import React from 'react';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import adaptivePlugin from '@fullcalendar/adaptive'
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3'
import { toLuxonDateTime } from '@fullcalendar/luxon3';

import { Global } from '../models/Global'
import { CalEvent } from '../models/CalEvent'

import { CommonCalendarFunctions } from './CommonCalendarFunctions';

import { ThemeColors } from '../Theme';

/**
 * Wrapper around the FullCalendar component, to display a calendar for all public Events in a community that a Patron will use to select an Event to sign up for.
 * Public events are shown as regular calendar events.
 * 
 * User must supply the following props:
 * 
 *     initialTimezone (String): The initial timezone to use for the calendar
 *     fetchSlots(start, end, successCallback, failureCallback): A function that fetches the slots from the server for the given time range.
 *                start: json string of the start date/time of the range
 *                end: json string of the end date/time of the range
 *                successCallback: A callback function that is called with the array of slots fetched from the server
 *                failureCallback: A callback function that is called with an error message if the fetch fails
 *  
 * Optional props:
 * 
 *     eventSelectCallback (function): A callback function that is called when the user selects an Event, called with the Event object as a parameter        
 *     loadingCallback (function): A callback function that is called when the calendar is loading events and rendering. The function is called with a boolean (true if loading)
 * 
 */


export class PatronEventsCalendar extends React.Component {

    _ref = React.createRef();
    _timezone;
    _eventSelectCallback;
    _didScrollToToday = false;

    _selectedEventObj = null;
    
    // Cache the last fetch request, when resizing the calendar or when it re-renders itself it will refetch the events, but if the range is the same
    // as the last fetch, we don't need to refetch
    _eventCache = {start: null, end: null, events: []}
    
    _getApi = () => {
        if (this._ref.current)
            return this._ref.current.getApi();
        return null;
    }


    constructor(props) {
        super(props);
        this._timezone = props.initialTimezone;
        this._eventSelectCallback = props.eventSelectCallback;
    }

    componentDidMount() {
        // Before the calendar is mounted, nothing is fetched on initial render (because api is required), so we
        // refetch events after the component is mounted
        this._getApi().refetchEvents();
    }

    // See if the component should re-render, re-rendering is expensive because it refetches the events. So we only do it if we have to
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    // Clear all selected
    _clearSelected = () => {
        CommonCalendarFunctions.highlightEvent(null, true, this._selectedEventObj ? [this._selectedEventObj] : []);
        this._eventSelectCallback(null);
    }

    _gotoToday = () => {
        CommonCalendarFunctions.todayButtonPressed(this._getApi());
    }


    // -----------------------------  EVENT FETCHING  -----------------------------

     // Create a Slot subclass object from a JSON string, we only accept Events here
    _slotFromJson(json) {
        switch (json.type) {
            case "Event":
                return new CalEvent(json, this._timezone);
            default:
                console.error("Unhandled slot type: " + json.type);
                return null;
        }
    }

    static _toFullCalendarEvent(slot) {
    
        return {
            id: slot.id,
            start: slot.start.toISO(),          // needs ISO string
            end: slot.end.toISO(),              // needs ISO string
            title: slot.title + (slot.isRecurring() ? " ↻" : ""),
            display: "block",
            color: ThemeColors.calendarDateButton,
            textColor: 'black',
            slotObject: slot
        }
    }


    // Since we are using the Luxon plugin, we must use the toLuxonDateTime function to convert the JSDate managed by the calendar
    // to a Luxon DateTime object
    _fullCalendarDateToLuxonDateTime = (date) => {
        return toLuxonDateTime(date, this._getApi());
    }



    // This function is called by the FullCalendar component to fetch events for the current view, the ref needs to exist so the component must
    // mount first. Fetch all the events within the specified time range, for the resources in the resources array, from the server
    _asyncFetchEvents = (fetchInfo, fetchEventsCallback, failureCallback) => {

        if (!this._getApi()) {       // component not mounted, no fetch
            fetchEventsCallback([]);
            return;
        }

        const start = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.start, this._getApi());
        const end = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.end, this._getApi());

        // If the range is the same as the last fetch, don't fetch again - just use the cache
        if (this._eventCache.start === start && this._eventCache.end === end) {
            console.log("Using cached events in range " + start + " to " + end);
            fetchEventsCallback(this._eventCache.events);
            return;
        }
        else
            this._eventCache = {start: null, end: null, events: []};    // clear cache

        console.log("Fetching events from " + start + " to " + end);
        this._clearSelected();

        // Tell the caller to fetch slots, the callback will be our fetchEventsCallback
        this.props.fetchSlots(start, end, 
                              (response) => this._fetchSlotsCallback(response, fetchEventsCallback, fetchInfo), 
                              (error) => failureCallback(error));
    }


    // Fetch Availability and Block slots from the server, and create clickable events for all the times that are available
    _fetchSlotsCallback = (response, fetchEventsCallback, fetchInfo) => {
        

        if (response) {

            const slots = response.map(json => this._slotFromJson(json));
            const events = slots.map(slot => PatronEventsCalendar._toFullCalendarEvent(slot));

            const start = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.start, this._getApi());
            const end = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.end, this._getApi());
            this._eventCache = {start: start, end: end, events: events};   // put to cache

            fetchEventsCallback(events);

            // Find the earliest and latest times that are not available, so we can limit the calendar view to show only available times
            CommonCalendarFunctions.setButtonCalendarOptions(slots, this._getApi());

            
            if (!this._didScrollToToday) {
                console.log("Scrolling to today");
                setTimeout(this._gotoToday, 100);  // scroll to today
                this._didScrollToToday = true;     // only scroll once per page load
            }

        }
        else {
            console.error("Error fetching events");
            fetchEventsCallback([]);   // No events
        }
    }

    
    // --------------------------- CALENDAR CALLBACKS FOR USER CLICKS ----------------------------


    // When the user clicks on an event, we need to find the Slot object that corresponds to the event, and call the slotClickCallback. 
    _eventClick = (clickInfo) => {

        const eventObj = clickInfo.event;
        
        // Clear the old selected, and select this one
        CommonCalendarFunctions.highlightEvent(eventObj, true, this._selectedEventObj ? [this._selectedEventObj] : []);

        this._selectedEventObj = eventObj;

        if (this._eventSelectCallback)
            this._eventSelectCallback(eventObj.extendedProps.slotObject);
        
    }

    _eventMouseEnter = (info) =>{
        const slot = info.event._def.extendedProps.slotObject;
        CommonCalendarFunctions.showTippyPopover(slot, this._resources, info.el);
    }


    // -----------------------------  RENDERING  -----------------------------


    _eventMounted = (info) => { 
        let style = info.el.getAttribute("style");
        style += "border-radius: 3px; opacity: 1.0; border: 1px solid gray; margin: 1px; ";  // make the event look like a button
        info.el.setAttribute("style", style);              // mark this so we know how to find it
    }

    render() {

       
        const customButtons = { customToday: {text: 'Today', click: this._gotoToday, hint: 'Go to Today'}, };      
        const height = window.innerWidth < 600 ? window.innerHeight * 0.6 : window.innerHeight * 0.7;

        return (
            <FullCalendar schedulerLicenseKey={Global.fullCalendarLicenseKey} 
                            ref={this._ref}
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, 
                                      adaptivePlugin, luxonPlugin]}
                            timeZone={this._timezone}
                            initialView='dayGridYear'
                            height={height}
                            expandRows={true}
                            nowIndicator={true}
                            monthStartFormat={{month: 'short', day: 'numeric'}}
                            slotDuration={'00:30:00'}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'narrow'
                            }}
                            slotLabelFormat={{ hour: 'numeric', minute: 'numeric', merdiem: 'short' }}
                            navLinks={true}
                            allDaySlot={false}
                            headerToolbar={{
                                left: 'prev,next customToday',
                                center: 'title',
                                right: 'dayGridYear,timeGridWeek,timeGridDay',
                            }}      
                            buttonText={{
                                dayGridYear: 'Month',
                            }}                
                            events={{events: this._asyncFetchEvents, id: 'server'}}  
                            eventClick={this._eventClick}
                            eventMouseEnter={this._eventMouseEnter}
                            eventDidMount={this._eventMounted}     
                            loading={this.props.loadingCallback}
                            customButtons={customButtons}
            />

        );
    }


}
