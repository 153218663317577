import React, { Fragment } from 'react';
import { TextField, Typography } from '@material-ui/core';

import { Currency } from 'react-frontend-utils' 
import { Global } from '../models/Global'

export class CommonPatronFunctions {


    // Returns a cost item with a label and a description
    static costItem(cost, label, description) {
        return (
            <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 10, marginBottom: 10}}>
                <TextField label={label} variant="outlined" 
                        value={Currency.round(cost)} 
                        InputLabelProps={{ shrink: true}} readOnly={true}
                        InputProps={{inputProps: {style: {textAlign: 'right'} }}} 
                />
                <Typography variant="body2" style={{marginLeft: 10, fontStyle: 'italic', color: 'gray'}}>{description}</Typography>
            </div>
        );
    }

    static calculateServiceFee(purchaseTotal, serviceFeePercent, serviceFeeFixed, agsFees) {

        if (purchaseTotal === 0)        //No fees for free items
            return 0;
 
        // These are the client's service fees
        const serviceFee1 = purchaseTotal * (serviceFeePercent / 100.0) + serviceFeeFixed;
        const totalAfterServiceFee1 = purchaseTotal + serviceFee1;

        const agsFixed = agsFees ? agsFees.serviceFeeFixed : 0;
        const agsScale = agsFees ? agsFees.serviceFeeMultiplier : 0;

        // Special formula that computes our fee that also includes our fee
        const agsFee = ((totalAfterServiceFee1 + agsFixed)/(1 - agsScale)) - totalAfterServiceFee1;

        return serviceFee1 + agsFee;
    }


    static finalBookingSignupDateDisplay(slot) {
        
        const start = slot.startDateTimeStr();
        const end = slot.endDateTimeStr(true);

        let dateLine1Display;
        let dateLine2Display;
        if (start.dateStr === end.dateStr) {     // for same day events, just show the start date and time range
            dateLine1Display = start.dateStr;
            dateLine2Display = start.timeStr + " - " + end.timeStr;
        }
        else {      // for multi-day events, show the start and end date and time}
            dateLine1Display = "Begins: " + start.dateStr + " " + start.timeStr;
            dateLine2Display = "Ends: " + end.dateStr + " " + end.timeStr;
        }


        return (
            <Fragment>
                <Typography align='center' variant="h6">{dateLine1Display}</Typography>
                <Typography align='center' variant="h6" style={{marginBottom: 10}}>{dateLine2Display}</Typography>
                <Typography align='center' variant="body2" style={{marginBottom: 10}}>{"Timezone: " + Global.getTimezone()}</Typography>
            </Fragment>
        );
    }
    


}