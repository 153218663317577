import React from 'react';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import adaptivePlugin from '@fullcalendar/adaptive'
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3'
import { toLuxonDateTime } from '@fullcalendar/luxon3';

import { DateConversions } from '../utils/DateConversions'

import { Global } from '../models/Global'
import { Availability } from '../models/Availability'
import { Block } from '../models/Block'

import { CommonCalendarFunctions } from './CommonCalendarFunctions';

import { ThemeColors } from '../Theme';

/**
 * Wrapper around the FullCalendar component, to display a calendar for a Service that a Patron will use to select a Booking time. This calendar uses negative
 * space to show unavailable times, and allows for slot clicking and dragging to select specific times and durations of the Booking.  It is used when the slot
 * intervals are 30 min or less. The unavailable times are a combination of times outside of business hours or the season booking range, and times that are
 * occupied by other bookings, fully used available resources, or blocks. Availabilities remove the negative space. The server will automatically expand all slots to
 * the nearest booking increment.
 * 
 * This is a static component that does not
 * re-render itself with prop changes, so it is up to the user to call the component functions to update as needed.
 * 
 * User must supply the following props:
 * 
 *     service (Service object): The Service to fetch slots for from the server
 *     initialTimezone (String): The initial timezone to use for the calendar
 *     fetchSlots(start, end, serviceID, successCallback, failureCallback): A function that fetches the slots from the server for the given time range for the Service.
 *                start: json string of the start date/time of the range
 *                end: json string of the end date/time of the range
 *                serviceID: the Service to fetch slots for
 *                successCallback: A callback function that is called with the array of slots fetched from the server
 *                failureCallback: A callback function that is called with an error message if the fetch fails
 *  
 * Optional props:
 * 
 *     extendSlotTime(): A function that extends the selected booking slot time by one increment
 *     dateSelectCallback (function): A callback function that is called when the user clicks on a date/time. 
 *                                    The function is called with the start and end date/time of the selected range
 *                                    
 *     loadingCallback (function): A callback function that is called when the calendar is loading events and rendering. The function is called with a boolean (true if loading)
 *     requiredSlots (number): The number of slots the user must select (for rescheduling an existing booking)
 * 
 * The following functions are available to users of the BookingCalendar component:
 *     setBooking(slot): A function that sets the selected booking slot on the calendar. If slot is null, the booking slot is cleared
 * 
 */
export class PatronServiceCalendarSlot extends React.Component {

    _debugBackgroundSlots = false;   // set to true to show the background slot titles for debugging

    _bookingSlotID = "PATRON_BOOKING_SLOT";

    _ref = React.createRef();
    _timezone;
    _service;

    // Luxon DateTime objects for the selected range
    _startRangeSelected;
    _endRangeSelected;
    _startOfToday;
    
    _maxIncrements = 1;     // number of vertical increments on the calendar

    // Cache the last fetch request, when resizing the calendar or when it re-renders itself it will refetch the events, but if the range is the same
    // as the last fetch, we don't need to refetch
    _eventCache = {start: null, end: null, events: []}


    _hasMouse = matchMedia('(pointer:fine)').matches;
    
    _getApi = () => {
        if (this._ref.current)
            return this._ref.current.getApi();
        return null;
    }

 

    constructor(props) {
        super(props);
        this._timezone = props.initialTimezone;
        this._service = props.service;
        this._startOfToday = DateConversions.now(this._timezone).startOf('day');
    }

    componentDidMount() {
        this._setHeight();
        // Before the calendar is mounted, nothing is fetched on initial render (because api is required), so we
        // refetch events after the component is mounted
        this._getApi().refetchEvents();
    }

    // See if the component should re-render, re-rendering is expensive because it refetches the events. So we only do it if we have to
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }


    // -----------------------------  USER FUNCTIONS  -----------------------------

    // Set the Patron selected booking slot displayed on the calendar.  Clear the old one if there is one.
    // if slot is null, just clear the booking slot
    setBooking = (slot) => {
        const api = this._getApi();
        if (api) {
            let event = api.getEventById(this._bookingSlotID);
            if (event)
                event.remove();

            if (slot) {
                event = {
                    id: this._bookingSlotID,
                    title: "SELECTED",
                    start: slot.start.toISO(),          // needs ISO string
                    end: slot.end.toISO(),              // needs ISO string
                    display: "block",
                    color: ThemeColors.clientCreditGreen,
                    slotObject: slot
                };
                api.addEvent(event, 'server');
            }

            if (this._service.serviceParams.maxIncrements > 1) {
                const element = document.querySelector('.fc-customAddIncrement-button');
                if (element) {
                    element.setAttribute('style', 'display: ' + (slot ? 'inline' : 'none'));
                }
            }

            const element = document.querySelector('.fc-customClear-button');
            if (element)
                element.setAttribute('style', 'display: ' + (slot ? 'inline' : 'none'));
        }
    }


    // -----------------------------  EVENT FETCHING  -----------------------------

     // Create a Slot subclass object from a JSON string, we only accept Blocks and Availabilities here
     _slotFromJson(json) {
        switch (json.type) {
            case "Availability":
                return new Availability(json, this._timezone);
            case "Block":
                return new Block(json, this._timezone);
            default:
                console.error("Unhandled slot type: " + json.type);
                return null;
        }
    }

    // A pseudo block is just used to create background events for non-business hours or outside booking window, so it doesn't need all the info of a block
    _pseudoBlock = (reason, start, end, allDay) => {
        return {title: reason, type: "Block", start: start, end: end, allDay: allDay};
    }

    static forOutsideBookingWindow(start, end) {
        const block = new Block();
        block.type = "Block";
        
        // call superclass method        
        block.newSlot("bl", start, end);
        block.color = ThemeColors.blockColor;
        return block;
    }


    // Since we are using the Luxon plugin, we must use the toLuxonDateTime function to convert the JSDate managed by the calendar
    // to a Luxon DateTime object
    _fullCalendarDateToLuxonDateTime = (date) => {
        return toLuxonDateTime(date, this._getApi());
    }

    _timeOutsideOfBookingRange = (start, end) => {

        if (this._service.serviceParams.seasonBookingStart && end <= this._service.serviceParams.seasonBookingStart)
            return true;

        if (this._service.serviceParams.seasonBookingEnd && start >= this._service.serviceParams.seasonBookingEnd)
            return true;
        
        if (end <= this._startOfToday)
            return true;

        return false;
    }


    // Given a Slot object, convert it to a FullCalendar background event
    _slotToBackgroundEvent = (slot) => {
        return {
            id: slot.id,
            title: this._debugBackgroundSlots ? (slot.title ? slot.title : slot.type) : "",
            start: slot.start.toISO(),          // needs ISO string
            end: slot.end.toISO(),              // needs ISO string
            display: "background",
            color: slot.type === "Block" ? ThemeColors.blockColor : ThemeColors.availabilityColor,
            textColor: ThemeColors.darkGray,
            allDay: slot.allDay,
            slotObject: slot
        };
    }

    // For any day that is entirely in the past, or outside the season, create a block that covers the entire day, so we can't book in the past
    _createBlocksForTimesOutsideBookingRange = (fetchStart, fetchEnd) => {

        const sp = this._service.serviceParams;

        const now = DateConversions.now(this._timezone).plus({minutes: sp.bookingLeadTimeMinutes});  // now, less the time to booking start

        const blocks = [];
        let dayStart = fetchStart.startOf('day');
        while (dayStart < fetchEnd) {

            const dayEnd = dayStart.plus({days: 1});
            // See if the entire day is outside the booking range
            if (this._timeOutsideOfBookingRange(dayStart, dayEnd))
                blocks.push(this._pseudoBlock("outsideRange-allday", dayStart, dayEnd, true));
            // If the start of the day is in the past, but the end is in the future, create a block for the past times
            else if (dayStart < now && dayEnd > now)
                blocks.push(this._pseudoBlock("inPast", dayStart, now, false));
            // Check season booking range            
            else {
                // If the day starts before the season start, create a block until the season start
                if (sp.seasonBookingStart && dayEnd > sp.seasonBookingStart)
                    blocks.push(this._pseudoBlock("beforeSeasonStart", dayStart, sp.seasonBookingStart, false));

                // If the day ends after the season end, create a block from the season end
                if (sp.seasonBookingEnd && dayEnd > sp.seasonBookingEnd)
                    blocks.push(this._pseudoBlock("afterSeasonEnd", sp.seasonBookingEnd, dayEnd, false));
            }
            
            dayStart = dayEnd;
        }

        return blocks;
    }
        



    // Create blocks for non-business hours. Each block is the duration of a booking increment, and is created for each increment that is not within the business hours
    _createBlocksForNonBusinessHours = (fetchStart, fetchEnd, availablilities) => {

        const now = DateConversions.now(this._timezone);

        const nonBusinessHourBlocks = [];
        let incrementStart = fetchStart;
        while (incrementStart < fetchEnd) {
            const incrementEnd = incrementStart.plus({minutes: this._service.serviceParams.incrementsMinutes});
            const incrementStartMinutes = incrementStart.hour * 60 + incrementStart.minute;
            const incrementEndMinutes = incrementStartMinutes + this._service.serviceParams.incrementsMinutes;
            const startOfNextDay = incrementStart.plus({days: 1}).startOf('day');

            // If the increment is outside the range, or in the past skip - it is handled by the createBlocksForTimesOutsideBookingRange function
            if (!this._timeOutsideOfBookingRange(incrementStart, incrementEnd) && (incrementEnd > now)) {

                const businessHours = this._service.businessHoursForDate(incrementStart);
                if (businessHours) {

                    if (businessHours.openMin === 0 && businessHours.closeMin === 24 * 60) { // open all day,  skip to the next day
                        incrementStart = startOfNextDay;
                        continue;
                    }

                    // If the increment is entirely outside the business hours, create a block
                    if (incrementEndMinutes < businessHours.openMin || incrementStartMinutes >= businessHours.closeMin ||
                        incrementStartMinutes < businessHours.openMin || incrementEndMinutes > businessHours.closeMin) {

                        nonBusinessHourBlocks.push(this._pseudoBlock( "outsideBusHours", incrementStart, incrementEnd, false));
                    }
                }
                else {      
                    const incStart = incrementStart;  // assign to local variable to avoid closure issue because incrementStart variable is changed in the loop
                    const availabilitiesThisDay = availablilities.filter(avail => avail.start.hasSame(incStart, 'day') || avail.end.hasSame(incStart, 'day'));
                    if (availabilitiesThisDay.length > 0) {
                        nonBusinessHourBlocks.push(this._pseudoBlock("outsideBusHours", incrementStart, incrementEnd, false));
                    }
                    else {      // entire day is has no business hours and no availabilities
                        nonBusinessHourBlocks.push(this._pseudoBlock("noBusHoursToday-noAvail", incrementStart, startOfNextDay, true));  // entire day has no business hours
                        incrementStart = startOfNextDay; // skip to the next day
                        continue;
                    }
                }
            }

            incrementStart = incrementEnd;
        }
        return nonBusinessHourBlocks;
    }

    _setEarliestAndLatestTimes = (slots) => {

        // First find the earliest and latest business hours
        let earliestBusinessHour = 24 * 60;        // earliest time of business hours, in minutes from midnight
        let latestBusinessHour = 0;                // latest time of business hours, in minutes from midnight

        for (let i = 0; i < 7; i++) {
            const businessHours = this._service.serviceParams.businessHours[i];
            if (businessHours.enabled) {
                const open = DateConversions.timeStringToMinutes(businessHours.open);
                const close = DateConversions.timeStringToMinutes(businessHours.close);

                if (open < earliestBusinessHour)
                    earliestBusinessHour = open;
                if (close > latestBusinessHour)
                    latestBusinessHour = close;
            }
        }

        // Now find the earliest and latest times that have expanded availability
        let earliestAvailability = null;        // earliest time of an Availability, in minutes from midnight
        let latestAvailability = null;         // latest time of an Availability, in minutes from midnight

        for (let slot of slots) {
            const dayMinuteOffset = DateConversions.dayMinuteOffset(slot.start, slot.end);

            if (slot.type === "Availability") {
                if (earliestAvailability === null || dayMinuteOffset.startMin < earliestAvailability)
                    earliestAvailability = dayMinuteOffset.startMin;
                if (latestAvailability === null || dayMinuteOffset.endMin > latestAvailability)
                    latestAvailability = dayMinuteOffset.endMin;
            }
        }

        // The earliest time is the earlier of the earliest business hour and earliest availability, if any
        let earliestTime = earliestAvailability !== null ? Math.min(earliestBusinessHour, earliestAvailability) : earliestBusinessHour;

        // The latest time is the later of the latest business hour and latest availability
        let latestTime = latestAvailability !== null ? Math.max(latestBusinessHour, latestAvailability) : latestBusinessHour;

        // Round to nearest hour
        earliestTime = Math.floor(earliestTime / 60) * 60;
        latestTime = Math.ceil(latestTime / 60) * 60;

        this._maxIncrements = (latestTime - earliestTime) / this._service.serviceParams.incrementsMinutes;  // max number of increments on the calendar

        const earliestTimeStr =  DateConversions.minutesToTimeString(earliestTime) + ":00";
        const latestTimeStr =  DateConversions.minutesToTimeString(latestTime) + ":00";

        const api = this._getApi();
        if (api) {
            api.setOption('slotMinTime', earliestTimeStr);
            api.setOption('slotMaxTime', latestTimeStr);
        }

        this._setHeight();

    }


    // This function is called by the FullCalendar component to fetch events for the current view, the ref needs to exist so the component must
    // mount first. Fetch all the events within the specified time range, for the resources in the resources array, from the server
    _asyncFetchEvents = (fetchInfo, fetchEventsCallback, failureCallback) => {

        if (!this._getApi()) {       // component not mounted, no fetch
            fetchEventsCallback([]);
            return;
        }

        const start = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.start, this._getApi());
        const end = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.end, this._getApi());

        // If the range is the same as the last fetch, don't fetch again - just use the cache
        if (this._eventCache.start === start && this._eventCache.end === end) {
            console.log("Using cached events in range " + start + " to " + end);
            fetchEventsCallback(this._eventCache.events);
            return;
        }
        else
            this._eventCache = {start: null, end: null, events: []};    // clear cache

        this._clearSelectedRange();
        console.log("Fetching events from " + start + " to " + end);

        // Tell the caller to fetch slots, the callback will be our fetchEventsCallback
        this.props.fetchSlots(start, end, this._service.id, 
                              (response) => this._fetchSlotsCallback(response, fetchEventsCallback, fetchInfo), 
                              (error) => failureCallback(error));
    }


    // Fetch Availability and Block slots from the server, and create background events for them. Also create background events for non-business hours.
    _fetchSlotsCallback = (response, fetchEventsCallback, fetchInfo) => {
        
        if (response) {
            const fetchStart = this._fullCalendarDateToLuxonDateTime(fetchInfo.start);
            const fetchEnd = this._fullCalendarDateToLuxonDateTime(fetchInfo.end);

            // First, create entire day blocks for all days in the past and blocks outside the season range
            const outsideRangeBlocks = this._createBlocksForTimesOutsideBookingRange(fetchStart, fetchEnd);

            // Fetch Blocks and Availability objects from the server
            let slots = response.map(json => this._slotFromJson(json));

            // Keep slots in the booking range
            slots = slots.filter(slot => !this._timeOutsideOfBookingRange(slot.start, slot.end));

            const availablilities = slots.filter(slot => slot.type === "Availability");

            // Next, create blocks for non-business hours
            const nonBusinessHourBlocks = this._createBlocksForNonBusinessHours(fetchStart, fetchEnd, availablilities);

            // For any non-business hour block that not wholly contained within an Availability, add it to slots
            for (let block of nonBusinessHourBlocks) {
                const contained = availablilities.find(avail => avail.start <= block.start && avail.end >= block.end);
                if (!contained) 
                    slots.push(block);
            }

            // Add the past day blocks to the slots
            slots = slots.concat(outsideRangeBlocks);

            const events = slots.map(slot => this._slotToBackgroundEvent(slot));   // convert to a background event

            const start = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.start, this._getApi());
            const end = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.end, this._getApi());

            this._eventCache = {start: start, end: end, events: events};   // put to cache
            fetchEventsCallback(events);

            // Find the earliest and latest times that are not blocked, so we can limit the calendar view to show only available times
            this._setEarliestAndLatestTimes(slots);
        }
        else {
            console.error("Error fetching events");
            fetchEventsCallback([]);   // No events
        }
    }

    
    // --------------------------- CALENDAR CALLBACKS FOR USER CLICKS ----------------------------

    _isMonthView = () => {
        const api = this._getApi();
        if (api)
            return api.view.type === "dayGridYear";
        return false;
    }

    // Save the selected range. Make the callback for the selected range only if there are no background events in the selected range. This is because the dateClick
    // will also be called if the user clicks on a background event, and we want that callback to take precedence
    _rangeSelect = (selectInfo) => {

        // Switch to day view if in month view, remove the time from the start String
        if (this._isMonthView()) {
            const api = this._getApi();
            if (api)
                api.changeView('timeGridDay', selectInfo.startStr.split('T')[0]);
            return;
        }

        
        this._startRangeSelected = this._fullCalendarDateToLuxonDateTime(selectInfo.start);
        this._endRangeSelected = this._fullCalendarDateToLuxonDateTime(selectInfo.end);

        if (this.props.dateSelectCallback) {
            if (this._startRangeSelected > this._endRangeSelected)             
                this.props.dateSelectCallback(this._endRangeSelected, this._startRangeSelected);    // swap them, so the start is before the end
            else
                this.props.dateSelectCallback(this._startRangeSelected, this._endRangeSelected);
        }
    }

    _rangeUnselect = (unselectInfo) => {
        this._startRangeSelected = null; 
        this._endRangeSelected = null;
    }


    _dateRangeDidChange = (dateInfo) => {
        this._setHeight();
    }

    
    // Callback to see if selection is allowed. We only allow selection if the start time is on the scheduling interval, and the selection range is within the max booking time
    _selectAllow = (selectInfo) => {
        if (this._isMonthView())        // selecting in month view will change to day view, so allow it
            return true;

        this._startRangeSelected = this._fullCalendarDateToLuxonDateTime(selectInfo.start);
        this._endRangeSelected = this._fullCalendarDateToLuxonDateTime(selectInfo.end);

        // If the selection range exceeds the max booking time, don't allow it
        const durationSelected = DateConversions.duration(this._startRangeSelected, this._endRangeSelected);

        let maxBookingTime;
        if (this.props.requiredSlots)
            maxBookingTime = this.props.requiredSlots * this._service.serviceParams.incrementsMinutes * 60;
        else
            maxBookingTime = this._service.serviceParams.incrementsMinutes * 60 * this._service.serviceParams.maxIncrements;

        if (durationSelected > maxBookingTime)
            return false;

        return true;
    }

    // Callback to see if selection can overlap and event. We allow this in month view because selecting will change the view. 
    // Otherwise we can select over an Availability slot, but not a Block slot
    _selectOverlap = (event) => {
        if (this._isMonthView())  // allow overlap in month view
            return true;

        const slot = event._def.extendedProps.slotObject;
        return slot.type === "Availability" || event.id === this._bookingSlotID;
    }

    _todayButtonPressed = () => {
        CommonCalendarFunctions.todayButtonPressed(this._getApi());
    }

    _addIncrementPressed = () => {
        if (this.props.extendSlotTime)
            this.props.extendSlotTime();
    }

    _clearSelectedRange = () => {
        this._rangeUnselect();
        if (this.props.dateSelectCallback)
            this.props.dateSelectCallback(null, null);
    }


    // -----------------------------  RENDERING  -----------------------------

    _setHeight = () => {
        const api = this._getApi();
        if (api) {
            if (this._isMonthView()) {
                api.setOption('expandRows', false);
                const height = window.innerWidth < 600 ? window.innerHeight * 0.55 : window.innerHeight * 0.7;
                api.setOption('height', height);   // show the whole month on the window
            }
            else {
                api.setOption('expandRows', true);
                const slotHeight = this._hasMouse ? 30 : 50;   // bigger for touch devices
                api.setOption('height', this._maxIncrements * slotHeight + 100);   // give each increment 30 pixels, and add 100 pixels for the header
            }
        }
    }


    render() {

        // The slot duration is in minutes, so we need to convert it to a string for the FullCalendar component, with 2 digits
        const gridSlotDuration = DateConversions.minutesToTimeString(this._service.serviceParams.incrementsMinutes);

        let customButtons = { customToday: {text: 'Today', click: this._todayButtonPressed, hint: 'Go to Today'} };
        let leftButtons = 'prev,next customToday';

        if (this._service.serviceParams.maxIncrements > 1) {
            customButtons.customAddIncrement = {text: 'Add Time', click: this._addIncrementPressed, hint: 'Add another slot to the Booking'};
            leftButtons += ' customAddIncrement';
        }
        customButtons.customClear = {text: 'Clear', click: this._clearSelectedRange, hint: 'Clear the Selection'};
        leftButtons += ' customClear';

        const height = window.innerWidth < 600 ? window.innerHeight * 0.45 : window.innerHeight * 0.75;

        return (
            <FullCalendar schedulerLicenseKey={Global.fullCalendarLicenseKey} 
                            ref={this._ref}
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, 
                                      adaptivePlugin, luxonPlugin]}
                            timeZone={this._timezone}
                            initialView='dayGridYear'
                            height={height}
                            expandRows={false}
                            nowIndicator={true}
                            monthStartFormat={{month: 'short', day: 'numeric'}}
                            slotDuration={gridSlotDuration + ':00'}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'narrow'
                            }}
                            slotLabelFormat={{ hour: 'numeric', minute: 'numeric', merdiem: 'short' }}
                            navLinks={true}
                            allDaySlot={false}
                            headerToolbar={{
                                left: leftButtons,
                                center: 'title',
                                right: 'dayGridYear,timeGridWeek,timeGridDay',
                            }}      
                            buttonText={{
                                dayGridYear: 'Month',
                            }}                
                            events={{events: this._asyncFetchEvents, id: 'server'}}  
                            selectable={true}
                            selectLongPressDelay={250}
                            select={this._rangeSelect}  
                            unselect={this._rangeUnselect}
                            selectAllow={this._selectAllow}
                            selectOverlap={this._selectOverlap}
                            datesSet={this._dateRangeDidChange}
                            loading={this.props.loadingCallback}
                            customButtons={customButtons}
            />

        );
    }


}
