import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { toLuxonDateTime } from '@fullcalendar/luxon3';

import { DateConversions } from '../utils/DateConversions';

import { Service } from '../models/Service';
import { ThemeColors } from '../Theme';

// Collection of utility functions that are used by the components that use FullCalendar component.  Generally they require the api
// of the FullCalendar component to be passed in as a parameter.
export class CommonCalendarFunctions {


    // Since we are using the Luxon plugin, we must use the toLuxonDateTime function to convert the JSDate managed by the calendar 
    // to a Luxon DateTime object, so we can use the our DateConversions functions to convert to a JSON string
    static fullCalendarDateToUTCJsonDateString = (date, calendarApi) => {
        
        const luxonDateTime = toLuxonDateTime(date, calendarApi);
        return DateConversions.luxonDateTimeToJsonDateString(luxonDateTime)
    }

    // Callback when the today button pressed, to scroll to the current day
    static todayButtonPressed = (calendarApi) => {
        if (calendarApi)
            calendarApi.today();

        const cell = document.querySelector('.fc-day-today');
        if (cell)
            cell.scrollIntoView();
    }


    // Common function to set a FullCalendar min/max times based on the earliest and latest times of the slots. The height is also set based on the window size
    static setButtonCalendarOptions = (slots, api) => {

        let earliestSlotMin = 8 * 60;      // 8:00 AM
        let latestSlotMin = 18 * 60;      // 6:00 PM

        for (let slot of slots) {
            const dayMinuteOffset = DateConversions.dayMinuteOffset(slot.start, slot.end);

            if (dayMinuteOffset.startMin < earliestSlotMin)
                earliestSlotMin = dayMinuteOffset.startMin;
            if (dayMinuteOffset.endMin > latestSlotMin)
                latestSlotMin = dayMinuteOffset.endMin;
        }

        const earliestTimeStr =  DateConversions.minutesToTimeString(earliestSlotMin) + ":00";
        const latestTimeStr =  DateConversions.minutesToTimeString(latestSlotMin) + ":00";

        if (api) {

           api.setOption('slotMinTime', earliestTimeStr);
           api.setOption('slotMaxTime', latestTimeStr);
           const height = window.innerWidth < 600 ? window.innerHeight * 0.6 : window.innerHeight * 0.70;
           api.setOption('height', height);   // give each increment 30 pixels, and add 100 pixels for the header
        }

    }

    // highlight the given Event if not null, by changing the color of the event and making it more prominent, and de-highlight the previous ones if requested
    // Must pass in the highlightedEvents array, which is updated with the new event
    static highlightEvent = (event, clearExisting, highlightedEvents, updateTitle = false) => {
        
        if (highlightedEvents.length > 0 && clearExisting) {      // de-highlight the previous events
            for (let e of highlightedEvents) {
                e.setProp('color', ThemeColors.calendarDateButton);    // restore the original color
                e.setProp('textColor', 'black');
                if (updateTitle)
                    e.setProp('title', '');
                e.setProp('borderColor', 'gray'); 
            }
            highlightedEvents.length = 0;   //  clear the array
        }          
        if (!event)
            return;

        highlightedEvents.push(event);

        // highlight the new event
        event.setProp('color', ThemeColors.calendarDateButtonSelected);
        if (updateTitle)
            event.setProp('title', 'Selected');
        event.setProp('textColor', 'white');
    }




    static showTippyPopover = (slot, resources, element) => {
        const startDTStr = slot.startDateTimeStr();
        const endDTStr = slot.endDateTimeStr();
        
        let dtStr1 = "", dtStr2 = "", dtStr3 = "", dtStr4 = "", divider = "";
        if (slot.isAllDay()) {      // Special format for all day events
            dtStr1 = startDTStr.dateStr
            dtStr2 = "ALL DAY";
        }
        else if (startDTStr.dateStr === endDTStr.dateStr) {     // for same day events, just show the start date and time range
            dtStr1 = startDTStr.dateStr;
            dtStr2 = startDTStr.timeStr + " - " + endDTStr.timeStr;
        }
        else {      // for multi-day events, show the start and end date and time}
            dtStr1 = startDTStr.dateStr;
            dtStr2 = endDTStr.dateStr;
            dtStr3 = startDTStr.timeStr;
            dtStr4 = endDTStr.timeStr;
            divider = "to";
        }

        let service;
        let slotResources = [];
        if (resources) {
            for (let resourceId of slot.resourceIds) {
                const resource = resources.find((resource) => resource.id === resourceId);
                if (resource) {
                    if (resource instanceof Service)
                        service = resource;
                    else
                        slotResources.push(resource);
                }
            }
        }

        let typeStr;
        if (service)
            typeStr = slot.type + " on " + service.name;
        else
            typeStr = slot.type;

        // create a bulleted list of resources (unless Availability, which have no resources)
        let resourceStr;
        if (slotResources.length === 0)
            resourceStr = "";
        else {
            resourceStr = '<div style="margin-top: 10px; border-top: 1px solid gray; font-weight: bold;">Resources</div>';
            resourceStr += '<ul style="padding-left: 15px; margin-left: 10px; margin-top: 0px">'; // Adjust padding and list style position
            for (let resource of slotResources)
                resourceStr += '<li>' + resource.name + '</li>';
            resourceStr += '</ul>';
        }

        const buffer = slot.endBufferMinutes > 0 ? ('<div style="text-align: right; font-style: italic; font-size: 9pt;">Ends ' + slot.endBufferMinutes + ' min earlier for patrons</div>') : "";

        const badges = slot.symbol() + (slot.isRecurring() ? " ↻" : "");
        const content = '<div style="width: 300px;">' +
                            '<div style="display: flex; gap: 20px; justify-content: space-between;">' + 
                                '<div style="font-weight: bold;">' + typeStr + '</div>' +
                                '<div>' + badges + '</div>' +
                            '</div>' +
                            '<div>' + slot.title +  '</div>' +
                            '<div style="border-bottom: 1px solid; margin-bottom: 2px;">' + slot.status() +  '</div>' +
                            '<div style="display: flex; gap: 20px; justify-content: space-between;">' + 
                                '<div>' + dtStr1 +  '</div>' +
                                '<div>' + divider +  '</div>' +
                                '<div>' + dtStr2 +  '</div>' +
                            '</div>' +
                            '<div style="display: flex; gap: 20px; justify-content: space-between;">' + 
                                '<div>' + dtStr3 +  '</div>' +
                                '<div>' + dtStr4 +  '</div>' +
                            '</div>' +
                            buffer +
                            '<div style="margin-top: 10px;">' + (slot.description ? slot.description : "") + '</div>' +
                            resourceStr +
                            '<div style="border-top: 1px solid; margin-top: 2px;">' + slot.details() +  '</div>' +
                        '</div>';

        tippy(element, {
            content: content,
            allowHTML: true,
            delay: 500,
        });
    }



}