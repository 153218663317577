import { ThemeColors } from "../Theme";
import { DateConversions } from "../utils/DateConversions";

import { Typography } from "@material-ui/core";

import { ColorUtils } from 'react-frontend-utils'
import { Global } from "./Global";

export class JournalEntry {

    date;                //date when created (Luxon DateTime)
    author;              //friendly name of user making the change
    activity;            //System activity (what changed)
    note; 

    constructor(json, timezone) {
        if (json) {
            this.date = DateConversions.utcJsonDateStringToLuxonDateTime(json.date).setZone(timezone);
            this.author = json.author;
            this.activity = json.activity;
            this.note = json.note;
        }
    }
}


export class Slot {

    type;               // Subclass type, for Jackson   
    state; 
    description;
    updateCount;        // Used to detect changes to the slot - do not modify on front end

    // These fields are the "event" fields for FullCalendar
    id;         // FullCalendar event id, and mongo Slot ID
    start;      // Event start, as a Luxon DateTime format (UTC)
    end;        // Event end, as a Luxon DateTime format (UTC)
    endBufferMinutes;  // Number of minutes shorten for patron display

    title;   
    resourceIds;
    color;

    recurrenceId;                 // the common id of the series
    recurrenceObj;                // only set when creating a new Recurrence on a slot, never passed to the backend

    journal;                     // Journal of changes to this slot

    constructor(json, timezone) {
        if (json) {
            this.type = json.type;
            this.state = json.state;
            this.id = json.id;
            this.title = json.title;
            this.description = json.description;
            this.updateCount = json.updateCount;
            this.start = DateConversions.utcJsonDateStringToLuxonDateTime(json.startTime).setZone(timezone);
            this.end = DateConversions.utcJsonDateStringToLuxonDateTime(json.endTime).setZone(timezone);
            this.endBufferMinutes = json.endBufferMinutes;
            this.resourceIds = json.resourceIds;
            this.color = json.color
            this.recurrenceId = json.recurrenceId;
            if (json.journal)
                this.journal = json.journal.map(entry => new JournalEntry(entry, timezone));
            else
                this.journal = [];
        }
    }

    static randomId() {
        return window.crypto.randomUUID().replace(/-/g, '');     // Generate a random UUID, remove the dashes
    }

    // Create a new Slot object from the given slot, with a new ID. Clearing fields that should not be in the copy
    static newFrom(slot) {
        const copy = slot.copy();    // start with a deep copy
        copy.id = Slot.randomId();
        copy.updateCount = 0;
        copy.state = "CREATED";
        copy.removeFieldsForNewSlot();
        return copy;
    }


    // For slots that are new from an existing slot, remove fields that should not be copied
    removeFieldsForNewSlot() {
        this.recurrenceId = null;   // remove any recurrence id
        this.journal = [];          // remove any journal entries
    }


    // Superclass method to initialize the fields of a new Slot object
    newSlot(prefix, start, end, resourceIds) {
        this.id = prefix + "_" + Slot.randomId();
        this.description = "";
        this.state = "CREATED";
        this.start = start;
        this.end = end;
        this.endBufferMinutes = 0;
        this.resourceIds = resourceIds;
        this.updateCount = 0;
        this.recurrenceId = null;
        this.journal = [];
    }

    // make a deep copy of this object into the target
    copy(target) {
        target.type = this.type;
        target.state = this.state;
        target.id = this.id;
        target.start = this.start;
        target.end = this.end;
        target.endBufferMinutes = this.endBufferMinutes;
        target.title = this.title;
        target.description = this.description;
        target.resourceIds = [...this.resourceIds];
        target.color = this.color;
        target.updateCount = this.updateCount;
        target.recurrenceId = this.recurrenceId;
        target.journal = [...this.journal]
    }

    fullCalendarDisplayType(resources) {

        // Any Slots that the user doesn't own, will be set to none
        if (Global.getShowOnlyMySlots()) {
            switch (this.type) {
                case "Booking":         // This is my Booking or Availability if I am the owner of its service
                case "Availability":
                    const service = this.getService(resources);
                    if (!service || service.owner !== Global.user.id)
                        return "none";
                    break;
                case "Event":
                    if (this.owner !== Global.user.id)   // This is my event if I am the owner
                        return "none";
                    break;
                default:
                    return "none";      // Blocks have no owner
            }
        }

        if (Global.getHideCancelledSlots()) {
            if (this.state === "CANCELLED" || this.state === "PENDING_CANCEL")
                return "none";
        }

        if (this.type === "Block" || this.type === "Availability")  {     // these are background events
            if (Global.getShowBackgroundSlots())
                return "background";
            else
                return "none";
        }

        if (this.isAllDay())
            return "block"; 
        return "auto";
    }

    toFullCalendarEvent(resources) {

        let fontColor = ColorUtils.fontColorForBackground(this.color);
        if (!fontColor) 
            fontColor = 'black';
        
        return {
            id: this.id,
            start: this.start.toISO(),          // needs ISO string
            end: this.end.toISO(),              // needs ISO string
            title: this.symbol() + " " + this.title + (this.isRecurring() ? " ↻" : ""),
            resourceIds: this.resourceIds,
            color: this.color,
            display: this.fullCalendarDisplayType(resources),
            textColor: this.type === "Block" || this.type === "Availability" ? ThemeColors.darkGray : fontColor,
            slotObject: this
        }
    }

    // Convert this object to a JSON string for posting to the server, with the start and end times converted to JSON strings
    // this object is not modified
    toJsonForPost(andStringify = true) {
        const copyOf = this.copy();     // make a copy of this object, we don't want to disturb the original, but we need to modify for posting
       
        copyOf.startTime = DateConversions.luxonDateTimeToJsonDateString(copyOf.start);
        copyOf.endTime = DateConversions.luxonDateTimeToJsonDateString(copyOf.end);

        copyOf.localStartTimeString = DateConversions.dateTimeStr(copyOf.start) + " (" + copyOf.start.zoneName + ")";

        copyOf.start = undefined;
        copyOf.end = undefined;

        if (!andStringify)
            return copyOf;

        copyOf.journal = null;   // don't send the journal
        
        return JSON.stringify(copyOf);
    }

    canEdit(resources) {
        console.error("canEdit not implemented for Slot superclass");
        return false;
    }

    getStartTimeOfDay() {
        return this.start.hour * 3600 + this.start.minute * 60 + this.start.second;
    }

    duration() {
        return DateConversions.duration(this.start, this.end);
    }

    // An all day event is one that starts at midnight and ends at midnight the next day, and is exactly 1 day long
    isAllDay() {
        const duration = this.end.diff(this.start, ["days", "hours", "minutes"]);
        return duration.days === 1 && this.start.hour === 0 && this.start.minute === 0 && this.end.hour === 0 && this.end.minute === 0;
    }

    replaceResourceId(oldId, newId) {
        const oldIndex = this.resourceIds.findIndex(id => id === oldId);
        if (oldIndex < 0)
            return false;

        this.resourceIds[oldIndex] = newId;
        return true;
    }


    isRecurring() {
        return this.recurrenceId ? true : false;
    }

    
    identicalTimeTo(anotherSlot) {
        if (!this.start.equals(anotherSlot.start))
            return false;
        if (!this.end.equals(anotherSlot.end))
            return false;

        return true;
    }

    stateLabel() {
        let color;
        switch (this.state) {
            case "CREATED":
                color = "gray";
                break;
            case "ACTIVE":
                color = "blue";
                break;
            case "PENDING_CANCEL":
            case "CANCELLED":
                color = "red";
                break;
            default:
                color = "black";
                break;
        }
        return {label: this.state.replace("_", " "), color: color };
    }

    symbol() {
        switch (this.state) {
            case "ACTIVE":
                return "✅";
            case "PENDING_CANCEL":
            case "CANCELLED":
                return "❌";
            default:
                return "";
        }
    }

    colorForStatus() {
        switch (this.state) {
            case "CREATED":
                return ThemeColors.mediumGray;
            case "ACTIVE":
                return 'green';
            case "PENDING_CANCEL":
            case "CANCELLED":
                return 'red';
            default:
                return 'black';
        }
    }


    startDateTimeStr() {
        return DateConversions.dateTimeStrComponents(this.start);
    }

    endDateTimeStr(forPatron = false) {
        return DateConversions.dateTimeStrComponents(this.end.minus({minutes: forPatron ? this.endBufferMinutes : 0}));
    }

    status() {
        return this.state.replace("_", " ");
    }

    // Subclasses override this to provide subclass specific details of the slot
    details() {
        return "";
    }

    _renderNote(note) {

        // Split up long notes into multiple 


    }


    renderJournal(wide = false) {
        if (!this.journal || this.journal.length === 0)
            return null;

        let additional = wide ? 0.1 : 0;
        let notesMaxWidth = window.innerWidth * (0.2 + additional);
        if (window.innerWidth > 1200)
            notesMaxWidth = window.innerWidth * (0.3 + additional);
        if (window.innerWidth > 1500)
            notesMaxWidth = window.innerWidth * (0.4 + additional);

        return (
            <div>
                 <div style={{display: 'flex', gap: 10, padding: 5, paddingBottom: 0}}>
                    <Typography variant='body2' style={{width: 160, fontWeight: 'bold'}}>Date</Typography>
                    <Typography variant='body2' style={{width: 150, fontWeight: 'bold'}}>Author</Typography>
                    <Typography variant='body2' style={{width: 200, fontWeight: 'bold'}}>Activity</Typography>
                    <Typography variant='body2' style={{fontWeight: 'bold'}}>Notes</Typography>
                </div>
                {this.journal.map((entry, index) => {
                    return (
                        <div key={index} style={{backgroundColor: ThemeColors.notifyBackground, marginBottom: 2, padding: 3, borderRadius: 3}}>
                            <div style={{display: 'flex', gap: 10}}>
                                <Typography variant='caption' style={{width: 160}}>{DateConversions.dateTimeStr(entry.date)}</Typography>
                                <Typography variant='caption' style={{width: 150}}>{entry.author}</Typography>
                                <Typography variant='caption' style={{width: 200}}>{entry.activity}</Typography>
                                <Typography variant='caption' style={{fontStyle: 'italic', maxWidth: notesMaxWidth}}>{entry.note}</Typography>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
    
}