import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { RestComponent, draftContentHasText, RichTextDisplay } from 'react-frontend-utils' 

import { CommonPatronFunctions } from '../components/CommonPatronFunctions'
import { Global } from '../models/Global'



/**
 * Page 3 of the Booking process. Displays the final booking information.
 * 
 * The following props are required:
 * - service: the Service object that the user is booking on
 * - booking: the Booking object that the user is booking
 * - resource: the Resource object that the user either requested or was assigned
 * - prevPageCallback: a callback that returns to the main page
 *  
 */
export class BookServicePage3 extends RestComponent {

    
    _prevPageCallback;
    

    constructor(props) {
        super(props);
    
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to BookServicePage1
    }
    
    
    componentDidMount() {
        super.componentDidMount(); 
        
        // Browser back button
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }


    render() {

        const service = this.props.service;
        const booking = this.props.booking;


        const hasTerms = draftContentHasText(service.serviceParams.termsAndConditions);

        return (
            <Fragment>
                <Typography align='center' variant="h4" style={{fontWeight: 'bold', color: 'green', marginTop: 20, marginBottom: 10}}>{service.name + ": Booking Complete"}</Typography>
                <Typography align='center' variant="body2" style={{marginBottom: 10}}>{service.description}</Typography>
                {CommonPatronFunctions.finalBookingSignupDateDisplay(booking)}
                <Typography align='center' variant="body2" style={{marginBottom: 10}}>{this.props.resource.name}</Typography>

                {hasTerms ?
                    <div style={{marginTop: 30}}>
                        <hr/>
                        <div style={{marginLeft: 10}}>
                            <RichTextDisplay draftContent={service.serviceParams.termsAndConditions}/>
                        </div>
                    </div>
                    : null
                }
            </Fragment>
        );
    }   

}


export default withCookies(withRouter(BookServicePage3));
