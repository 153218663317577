import React, { useState } from 'react';
import { Typography, Box, Checkbox, Popover, Tooltip, TextField, ClickAwayListener } from '@material-ui/core';

import { Global } from '../models/Global';
import Autocomplete from '@material-ui/lab/Autocomplete';


/**
 * This component is a popover shows options for the Calendar
 * 
 * The user can supply the following props:
 * 
 * isOpen (boolean): True if the popover is open, false otherwise
 * anchor (element): The element that the popover is anchored to
 * updateHiddenSlots (function): A callback function that is called when the user changes the "Show Blocks and Availabilities" checkbox or the "Show Only My Items" checkbox
 */
export function CalendarOptionsPopover(props) {

    const [showBackgroundSlots, setShowBackgroundSlots] = useState(Global.getShowBackgroundSlots());
    const [showOnlyMySlots, setShowOnlyMySlots] = useState(Global.getShowOnlyMySlots());
    const [hideCancelledSlots, setHideCancelledSlots] = useState(Global.getHideCancelledSlots());
    const [gridSlotDuration, setGridSlotDuration] = useState(Global.getGridSlotDuration());
   
    const handleClose = () => {
        props.onClose();
    }
  
    const showBackgroundSlotsChanged = (event) => {
        const isChecked = event.target.checked;
        Global.setShowBackgroundSlots(isChecked);
        setShowBackgroundSlots(isChecked);
        props.updateHiddenSlots();
    }

    const showOnlyMySlotsChanged = (event) => {
        const isChecked = event.target.checked;
        Global.setShowOnlyMySlots(isChecked);
        setShowOnlyMySlots(isChecked);
        props.updateHiddenSlots();
    }

    const hideCancelledSlotsChanged = (event) => {
        const isChecked = event.target.checked;
        Global.setHideCancelledSlots(isChecked);
        setHideCancelledSlots(isChecked);
        props.updateHiddenSlots();
    }

    const handleGridSlotChange = (value) => {
        if (!value)
            return;
        Global.setGridSlotDuration(value);
        setGridSlotDuration(value);
        props.onChangeGridSlotDuration(value);
    }

   
    return (
        <Popover open={props.isOpen} anchorEl={props.anchor} 
                anchorOrigin={{vertical: 'center', horizontal: 'left'}} 
                transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        
            <ClickAwayListener onClickAway={handleClose}>
                <Box style={{margin: 20, width: 200}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Options</Typography>  

                    <div style={{display: 'flex', alignItems: 'center', marginLeft: -5}}>
                        <Tooltip title="Show or hide Blocks and Availabilities on the weekly, daily, and timeline views">
                            <Checkbox color='primary' checked={showBackgroundSlots} onChange={showBackgroundSlotsChanged}/>
                        </Tooltip>
                        <Typography variant="body2">Show Background Items</Typography>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginLeft: -5}}>
                        <Tooltip title="Show or hide only Events that I own or Bookings on my Services">
                            <Checkbox color='primary' checked={showOnlyMySlots} onChange={showOnlyMySlotsChanged}/>
                        </Tooltip>
                        <Typography variant="body2">Show Only My Items</Typography>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 20, marginLeft: -5}}>
                        <Tooltip title="Hide or show Events and Bookings that are cancelled, or pending cancellation">
                            <Checkbox color='primary' checked={hideCancelledSlots} onChange={hideCancelledSlotsChanged}/>
                        </Tooltip>
                        <Typography variant="body2">Hide Cancelled Items</Typography>
                    </div>

                    <Autocomplete
                        style={{width: 200}}
                        size="small"
                        blurOnSelect
                        options={[15, 30, 60]}
                        getOptionLabel={(option) => {
                            return option + " minutes";
                        }}
                        value={gridSlotDuration}
                        onChange={(event, value) => handleGridSlotChange(value)}
                        renderInput={(params) => <TextField {...params} label="Grid Slot Duration" variant='outlined'/>}
                    />

                    
                </Box>
            </ClickAwayListener>

        </Popover>
    );
}