import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { RestComponent } from 'react-frontend-utils' 

import { Global } from '../models/Global'
import { ThemeColors } from '../Theme'
import { Booking } from '../models/Booking'
import { Resource } from '../models/Resource';

import { PatronServiceCalendarSlot } from '../components/PatronServiceCalendarSlot'
import { PatronServiceCalendarButton } from '../components/PatronServiceCalendarButton'
import { Typography, Button } from '@material-ui/core';
import { DateConversions } from '../utils/DateConversions';
import { PatronSelectResourcePopover } from '../components/PatronSelectResourcePopover';

/**
 * Reschedule an existing booking. Allows the user to select a new date and time for the booking. Uses one of two calendar components, depending on the service's
 * minimum slot time. If the service uses a slot calendar, the user can select a time slot. If the service uses a button calendar, the user can select a time
 * by clicking on a button. The user must select the same number of slots as the original booking.
 * 
 * The following props are required:
 * - existingBooking: the Booking object that the user is rescheduling
 * - patronKey: the Patron key for the Booking
 * - service: the Service object that the user is booking
 * - database: the database for the Service
 * 
 * - prevPageCallback: a callback that returns to the ManageBookingPage
 * - rescheduleCompleteCallback: a callback that is called when the user has successfully rescheduled the booking
 */
export class RescheduleBookingPage extends RestComponent {

    _prevPageCallback;
    _calendarRef = React.createRef();
    _timezone = Global.getTimezone();

    constructor(props) {
        super(props);
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to ManageBookingPage
        this.state.selectedBooking = null;
        this.state.showSelectResourcePopover = false;
        this.state.availableResources = [];
    }
    
    
    componentDidMount() {
        super.componentDidMount(); 
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }


    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
    }

    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- CALENDAR CALLBACK -----------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    // Fetches a list of Blocks and Availabilities for the given date range for the service. Blocks are unavailable times either because there
    // is a Booking (and the service does not allow overlapping bookings) there are no available resources. Availabilities are the expanded 
    // available times for the service, outside the business hours and the season start/end dates.
    _fetchSlots = (jsonDateStart, jsonDateEnd, serviceID, successCallback, failureCallback) => {

        // If we are using the button calendar, we tell the server to include all the individual slots on each Resource, not just when they overlap. Because
        // the button calendar uses 15 minute increments, we need to check against the entire button duration
        const allResources = this.props.service.usesSlotCalendar() ? "" : "&allResources=true";

        this.secureJSONFetch("/patron/services/" + serviceID + "/slots?fromDate=" + jsonDateStart + "&toDate=" + jsonDateEnd + allResources, {}, 
                             (response) => successCallback(response), (error) => failureCallback(error)); 

    }


    _setBooking = (booking) => {        
        if (this._calendarRef.current && this._calendarRef.current.setBooking)
            this._calendarRef.current.setBooking(booking);
        this.setState({selectedBooking: booking});
    }

     // Extend slot time by 1 increment, if it won't exceed the max booking time or the time of the existing booking
    _extendSlotTime = () => {

        const booking = this.state.selectedBooking; 
        if (!booking)
            return;

        const maxBookingTimeMin = this.props.service.serviceParams.incrementsMinutes * this.props.service.serviceParams.maxIncrements;
        const existingDuration = DateConversions.duration(this.props.existingBooking.start, this.props.existingBooking.end) / 60;

        const thisDuration = DateConversions.duration(booking.start, booking.end) / 60;

        if (thisDuration < maxBookingTimeMin && thisDuration < existingDuration ) {
            const newEndTime = booking.end.plus({minutes: this.props.service.serviceParams.incrementsMinutes});
            booking.end = newEndTime;
            this._setBooking(booking);
        }
        else 
            this.showConfirmAlert("Maximum Booking Time Reached", "Your booking must be " + existingDuration + " minutes long", 'red');
    }

    _dateSelected = (startTime, endTime) => {

        if (!startTime || !endTime) {
            this._setBooking(null);
            return;
        }

        const newBooking = Booking.createNew(startTime, endTime, this.props.service);
        this._setBooking(newBooking);
    }

    _rescheduleBooking = () => {

        const bookingStart = DateConversions.luxonDateTimeToJsonDateString(this.state.selectedBooking.start);
        const bookingEnd = DateConversions.luxonDateTimeToJsonDateString(this.state.selectedBooking.end);

        // Determine if the booking is available
        this.secureJSONFetch("/patron/services/" + this.props.service.id + "/checkBookingAvailability?fromDate=" + bookingStart + "&toDate=" + bookingEnd, {}, 
                             this._checkRescheduledBookingAvailable, this._fetchErrorCallback); 
    }

    _checkRescheduledBookingAvailable = (response) => {

        // If there is a response, the booking is available. The response is a list of available resource ids. An empty list means no resources are required.
        if (response) {

            if (this.props.service.serviceParams.requireAvailableResource && this.props.service.serviceParams.allowPatronToSelectResource) {

                const availableResources = response.map((r) => new Resource(r));
                
                // If there are no available resources, something went wrong
                if (availableResources.length === 0) {
                    this.showConfirmAlert("Error", "Something changed on a Service's Resource since the page was loaded. Please try again.", 'red');
                    return;
                }

                // Show a popup to select a resource
                console.info("Available resources for Patron to Select: ", availableResources);
                this.setState({showSelectResourcePopover: true, availableResources: availableResources});
                return;
            }

            // No resources required, so proceed to Book it
            console.log("Booking is available, resource will be auto-assigned");
            this._completeReschedule();
        }
        else {
            // Booking is not available
            this.showConfirmAlert("Error", "The selected booking time is no longer available. Please select another time.", 'red');
        }
    }


    _completeReschedule = (resourceId) => {

        const pk = "?patronKey=" + this.props.patronKey;
        const resourceQuery = resourceId ? "&resourceId=" + resourceId : "";

        const rb = this.state.selectedBooking.toJsonForPost();

        this.secureJSONFetch("/patron/services/" + this.props.service.id + "/reschedule/" + this.props.existingBooking.id + pk + resourceQuery, 
                             {method: 'POST', body: rb}, this._rescheduleBookingCallback, this._fetchErrorCallback); 
    }

    _rescheduleBookingCallback = (response) => {
        this.props.rescheduleCompleteCallback();
    }

    
    render() {

       
        const isMobile = window.innerWidth < 600;

        const existingDuration = DateConversions.duration(this.props.existingBooking.start, this.props.existingBooking.end) / 60;
        const numIncrementsBooked = existingDuration / this.props.service.serviceParams.incrementsMinutes;

        const newDuration = this.state.selectedBooking ? DateConversions.duration(this.state.selectedBooking.start, this.state.selectedBooking.end) / 60 : 0;
        const canReschedule = this.state.selectedBooking && newDuration === existingDuration;

        const useSlotCalendar = this.props.service.usesSlotCalendar();

        let durationString = "";
        let startStr = "";
        if (this.state.selectedBooking) {
            durationString = this.state.selectedBooking.patronDurationString(this.props.service);
            startStr = "Selected: " + DateConversions.dateTimeStr(this.state.selectedBooking.start);    
        }

        let msg;
        if (useSlotCalendar) {
            msg = "Your existing Booking is " + existingDuration + " minutes long. Choose a day and then select " + (numIncrementsBooked === 1 ? "a time slot" : numIncrementsBooked + " time slots") + ". Each time slot is " + this.props.service.serviceParams.incrementsMinutes + " minutes long.";        
            if (numIncrementsBooked > 1)
                msg += " Click on the first slot and then drag down " + numIncrementsBooked + " slots.";
            else
                msg += " Click on the time slot to select it.";

            msg += " Greyed out slots are not available.";
        }
        else {
            if (numIncrementsBooked === 1)
                msg = " Click on any available green slot to select your new booking time.";
            else
                msg = " Your Booking is " + numIncrementsBooked + " consecutive slots. Click on " + numIncrementsBooked + " new consecutive slots to reschedule to those times.";
        }

        return (
            <Fragment>
                {this.getConfirmAlertComponent()}

                <PatronSelectResourcePopover isOpen={this.state.showSelectResourcePopover} resources={this.state.availableResources}
                                             cancelCallback={() => this.setState({showSelectResourcePopover: false, selectedBooking: null})} okCallback={(resourceId) => this._completeReschedule(resourceId)}/>
                   
    
                <Button disabled={!this.state.selectedBooking} variant='contained' onClick={this._rescheduleBooking} 
                        style={{position: 'fixed', right: 20, backgroundColor: canReschedule ? 'green' : ThemeColors.veryLightGray, color: 'white', float: 'right', marginLeft: 10}}>Reschedule</Button>
                
                <Typography variant='h6'>{"Reschedule on " + this.props.service.name}</Typography>
                <Typography variant={isMobile ? 'body2' : 'body1'} style={{color: 'gray', fontStyle: 'italic', marginBottom: 10, maxWidth: '80%'}}>{this.props.service.description}</Typography>
                <hr/>

                <Typography variant={isMobile ? 'body2' : 'body1'}>{msg}</Typography>
                <div style={{display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', justifyContent: 'center', color: 'green', height: 40}}>
                    <Typography variant='body1' align='right' style={{fontWeight: 'bold'}}>{startStr}</Typography>
                    <Typography variant='body1' align='right' style={{fontWeight: 'bold'}}>{durationString}</Typography>
                </div>


                <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: 1024}}>
                    {useSlotCalendar ?
                        <PatronServiceCalendarSlot ref={this._calendarRef} 
                                                    initialTimezone={this._timezone} 
                                                    fetchSlots={this._fetchSlots}
                                                    dateSelectCallback={this._dateSelected}
                                                    extendSlotTime={this._extendSlotTime}
                                                    loadingCallback={this._calendarBusy}
                                                    service={this.props.service}
                                                    requiredSlots={numIncrementsBooked}
                                                    />
                        :
                        <PatronServiceCalendarButton ref={this._calendarRef} 
                                                    initialTimezone={this._timezone} 
                                                    fetchSlots={this._fetchSlots}
                                                    dateSelectCallback={this._dateSelected}
                                                    loadingCallback={this._calendarBusy}
                                                    service={this.props.service}
                                                    requiredSlots={numIncrementsBooked}
                                                    />
                    }
                </div>
            </Fragment>
        );
    }

}



export default withCookies(withRouter(RescheduleBookingPage));

