

import { DateConversions } from '../utils/DateConversions';
import { Global } from '../models/Global';
import { Patron } from '../models/Patron';
import { Booking } from '../models/Booking';
import { CalEvent } from '../models/CalEvent';

/**
 * Common function for prompting the user to confirm the deletion/cancellation of a slot
 * @param {Slot} slot the slot to delete
 * @param {boolean} showRecurrenceWarning true if the user should be warned that this is a recurring slot and only it will be deleted
 * @param {function} showConfirmAlertFunction the function to call to show the confirmation alert
 * @param {function} deleteFunction if the user confirms, this function is called to delete the slot, called with the slot as an argument
 */
export function deleteSlotConfirmation(slot, showRecurrenceWarning, showConfirmAlertFunction, deleteFunction) {

    let recurrMsg = ""; 
    if (slot.recurrenceId && showRecurrenceWarning)
        recurrMsg = " This is a recurring " + slot.type + ". Only this occurrence will be deleted. To modify the series, go to the Calendar.";

    const now = DateConversions.now(Global.getTimezone());
    let pastOrInProgress = "";
    if (slot.end < now)     // in the past
        pastOrInProgress = " This " + slot.type + " already occurred.";
    else if (slot.start < now && slot.end > now)        // in progress
        pastOrInProgress = " This " + slot.type + " is currently in progress.";


    if (slot.state === "ACTIVE") {      // Cancel Active slots instead of deleting

        let msg = "";
        let refundNote = "";
        if (slot instanceof Booking) {
            msg = "This Booking is confirmed with the patron."
            if (slot.patron && slot.patron.status() === Patron.Status.CONFIRMED_PAID)
                refundNote = " Note: you must issue refunds manually.";
        }
        else if (slot instanceof CalEvent) {
            const patronCount = slot.totalPatronCount();
            if (patronCount === 0)
                msg = "This Event is currently awaiting signups."
            else
                msg = "This Event has " + patronCount + " attendee" + (patronCount > 1 ? "s." : ".");
        }
        
        
        msg += pastOrInProgress;
        
        showConfirmAlertFunction("Cancel Active " + slot.type, 
                                msg + " Are you sure you want to cancel \"" + slot.title + "\"?" + recurrMsg + refundNote, 'black',
                                "No", 
                                () => deleteFunction(slot),
                                "Cancel",
                                'red');
    }
    else {
        showConfirmAlertFunction("Delete " + slot.type, 
                                "Are you sure you want to delete \"" + slot.title + "\"?" + pastOrInProgress + recurrMsg, 'black',
                                "No", 
                                () => deleteFunction(slot),
                                "Delete",
                                'red');
    }
}