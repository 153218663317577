import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Typography, Button, TextField, Checkbox } from '@material-ui/core';

import { RestComponent, Currency, ManageNumericField, ManageTextField, Email, RichTextDisplay, draftContentHasText } from 'react-frontend-utils' 

import { ThemeColors } from '../Theme'
import { Global } from '../models/Global'

import PatronCheckout from '../components/PatronCheckout'
import { CommonPatronFunctions } from '../components/CommonPatronFunctions';
import { Patron } from '../models/Patron';

/**
 * Page 2 of the Event Signup process. Displays the event details and allows the user to signup
 * 
 * The following props are required:
 * - event: the Event object that the user is signing up for
 * - agsServiceFee: the AGS service fee for the booking {serviceFeeMultiplier, serviceFeeFixed}
 * - prevPageCallback: a callback that returns to the previous page
 * - nextPageCallback: a callback that is called when the user has confirmed and paid for the Event
 * 
 */
export class EventSignupPage2 extends RestComponent {

    styles = {
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20,
        },
        sectionLabel: {
            fontSize: 16, 
            color: 'black', 
            marginLeft: 5
        },
        membershipInstructionsLabel: {
            color: 'gray',
            fontSize: '10pt',
            flexGrow: 1,
            fontStyle: 'italic',
            textAlign: 'left'
        },
        membershipField: {
            marginTop: 5,
            marginBottom: 5
        },
    }

    _prevPageCallback;
    _nextPageCallback;
    _timezone = Global.getTimezone();
    
    _patron = new Patron();     // Create a new Patron object to store the user's information, will use the same id each time a signup is attempted

    constructor(props) {
        super(props);
    
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to EventSignupPage1
        this._nextPageCallback = props.nextPageCallback;

        // Create an array of booleans for the optional cost items
        this.state.optionalCosts = new Array(props.event.signup.optionalCosts.length).fill(false);

        this.state.patronName = "";
        this.state.patronEmail = "";
        this.state.patronPhone = "";
        this.state.enableSms = false;
        this.state.tcAgree = false;

        this.state.clientSecret = null;
        this.state.membershipInfo = "";   //The membership ID or barcode typed in to identify the Membership
    }
    
    
    componentDidMount() {
        super.componentDidMount(); 
        
        // Browser back button - go back to Application List
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }

    _setPatronEmail = (json, userValue) => {
        if (!Email.validateEmail(userValue)) {
            this.showConfirmAlert("Invalid Email Address", "Invalid email address (check for whitespaces, invalid characters, and valid domain)", 'red');
            return;
        }
        this.setState({patronEmail: userValue});
    }

    _setPatronName = (json, userValue) => { 
        this.setState({patronName: userValue});
    }

    _setPatronPhone = (json, userValue) => {
        this.setState({patronPhone: userValue});
    }


    _submit = (grandTotal, payInCash) => {

        if (payInCash) {
            this.showConfirmAlert("Pay for Event Later", "Are you sure you want to pay for this event later with one of the other payment methods provided by your Community?", 'black',
                "No", 
                () => this._continueSubmit(grandTotal, payInCash),
                "Yes",
                'green');
        }
        else
            this._continueSubmit(grandTotal, payInCash);
    }



    _continueSubmit = (grandTotal, payInCash) => {

        const event = this.props.event;
        this._patron.clear();

        // Add the optional costs to the booking as a list of strings
        this._patron.optionalCosts = [];
        for (let i = 0; i < this.state.optionalCosts.length; i++) {
            if (this.state.optionalCosts[i])
                this._patron.optionalCosts.push(event.signup.optionalCosts[i].name);
        }

        this._patron.patronCount = 1;
        this._patron.patronNames = [this.state.patronName];
        this._patron.patronEmail = this.state.patronEmail;
        this._patron.patronPhone = this.state.patronPhone;
        this._patron.enableSms = this.state.enableSms;

        this._patron.calculatedCost = grandTotal;
        this._patron.isoCurrency = event.signup.isoCurrency;
        this._patron.isCashPayment = payInCash;
        if (this.state.membershipInfo)
            this._patron.membershipInfo = this.state.membershipInfo;
        
        const body = JSON.stringify(this._patron);

        const cashAmountDue = payInCash ? Currency.round(grandTotal) + " " + event.signup.isoCurrency : null;

        this.incrementBusy();
        this.secureJSONFetch("/patron/events/" + event.id + "/signup", {method: 'POST', body: body}, 
                             (response) => this._submitComplete(response, cashAmountDue), this._fetchErrorCallback); 
    }


    _submitComplete = (response, cashAmountDue) => {
        this.decrementBusy();
        if (response)  //response is a client secret if payment required, or null if not
            this.setState({clientSecret: response, showPayment: true});
        else
            this._nextPageCallback(this._patron.id, cashAmountDue);  // free or cash, we're done
    }


    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }


    //Patron cancels the payment
    _returnFromPayment = () => {
        this.setState({showPayment: false, clientSecret: null});
    }


    render() {

        const event = this.props.event;
        const signup = event.signup;

        const msg = "Your signup slot is not reserved until you complete this form. If space is limited, it is possible for the Event to fill up before you complete this form.";


        const totalOptionalCosts = signup.optionalCosts.reduce((total, costItem, index) => {
            return total + (this.state.optionalCosts[index] ? costItem.cost : 0);
        }, 0);


        const subtotal = signup.cost + totalOptionalCosts;

        const totalServiceFees = CommonPatronFunctions.calculateServiceFee(subtotal, signup.serviceFeePercent,
                                                            signup.serviceFeeFixed, this.props.agsServiceFee);

        const grandTotal = subtotal + totalServiceFees;

        const hasTerms = draftContentHasText(signup.termsAndConditions);

        // Check if the user can submit the booking, patron name must be filled out, email must be valid, and terms and conditions must be agreed to
        const canSubmit = this.state.patronName.trim().length > 0 && 
                          Email.validateEmail(this.state.patronEmail) && 
                          (!hasTerms || this.state.tcAgree) &&
                          (!signup.requiresMembership || this.state.membershipInfo.trim().length > 0);

        let width = window.innerWidth < 916 ? '100%' : '80%';
        if (window.innerWidth > 1200)
            width = 1160 * 0.8;       // leave 40 for margins

        return (
            <Fragment>
                <div style={{display: this.state.showPayment ? 'none' : 'block', width: width, marginLeft: 'auto', marginRight: 'auto'}}>
                    {this.getConfirmAlertComponent()}

                    <Typography align='center' variant="h5" style={{marginTop: 20, marginBottom: 10}}>{"Event Signup: " + event.title}</Typography>
                    {CommonPatronFunctions.finalBookingSignupDateDisplay(event)}
                    <Typography align='center' variant="body2" style={{marginBottom: 10}}>{"Location: " + event.locationString}</Typography>

                    <hr/>
                    <Typography align='center' variant="body1" style={{marginBottom: 10, color: 'red'}}>{msg}</Typography>
                    <hr/>

                    {signup.cost > 0 ?
                        <Fragment>
                            <Typography variant="body2" style={this.styles.sectionLabel}>{"Signup Fee (" + signup.isoCurrency + ")"}</Typography>
                            <div style={this.styles.roundedContainer}>
                                {CommonPatronFunctions.costItem(signup.cost, "Signup Fee")}
                            </div>
                        </Fragment>
                        : null
                    }

                    {signup.optionalCosts.length > 0 ?
                        <Fragment>
                            <Typography variant="body2" style={this.styles.sectionLabel}>{"Add-on Optional Items"}</Typography>
                            <div style={this.styles.roundedContainer}>

                                {signup.optionalCosts.map((costItem, index) => 
                                    <Fragment>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: -5}}>
                                            <Checkbox color='primary' checked={this.state.optionalCosts[index]} 
                                                    onChange={(event) => { 
                                                            const optionalCosts = [...this.state.optionalCosts];
                                                            optionalCosts[index] = event.target.checked;
                                                            this.setState({optionalCosts: optionalCosts});
                                                    }}/>
                                            <Typography variant="body2">{costItem.name + " (" + Currency.round(costItem.cost) + " " + signup.isoCurrency + ")"}</Typography>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </Fragment> 
                        : null
                    }

                    {totalServiceFees > 0 ?
                        <Fragment>
                            <Typography variant="body2" style={this.styles.sectionLabel}>{"Service Fees (" + signup.isoCurrency + ")"}</Typography>
                            <div style={this.styles.roundedContainer}>
                                {CommonPatronFunctions.costItem(totalServiceFees, "Service Fee", null)}      
                            </div>
                        </Fragment>
                        : null
                    }

                    {grandTotal > 0 ?
                        <Fragment>
                            <Typography align='center' variant="h5" style={{marginTop: 20, marginBottom: 10}}>{"Total Cost: " + Currency.round(grandTotal)}</Typography>
                            <hr/>
                        </Fragment>
                        : null
                    }


                    <ManageTextField label="Name"
                                    style={{marginTop: 15}}
                                    initialValue=""
                                    json="patronName"  
                                    autoAccept={true}
                                    onFieldChange={this._setPatronName}/>

                    <ManageTextField label="Contact Email Address"
                                    style={{marginTop: 15}}
                                    initialValue=""
                                    json="email"  
                                    autoAccept={true}
                                    onFieldChange={this._setPatronEmail}/>

                    
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                        <ManageTextField label="Contact Phone (Optional)"
                                    style={{marginRight: 15, flexGrow: 1}}
                                    initialValue=""
                                    json="phone"  
                                    autoAccept={true}
                                    onFieldChange={this._setPatronPhone}/>
                    {/** COMING SOON ------------------------------------- 
                        <Checkbox color='primary' checked={this.state.enableSms} 
                                onChange={(event) => this.setState({enableSms: event.target.checked})}/>
                        <Typography variant="body2">Enable SMS Notifications</Typography>
                        */}
                    </div>

                    {signup.requiresMembership ?
                        <div style={{marginTop: 15}}>
                            <TextField size="small" variant="outlined" value={this.state.membershipInfo} label="Membership"
                                        onChange={(event) => {this.setState({membershipInfo: event.target.value})}} 
                                        style={this.styles.membershipField} 
                                        fullWidth={true} InputLabelProps={{ shrink: true}} />
                            <Typography variant="body2" style={this.styles.membershipInstructionsLabel}>{"A valid Membership is required. Enter your pass number, barcode number, or Membership ID above."}</Typography>  
                        </div> : null
                    }

                    {hasTerms ?
                        <div style={{marginTop: 30}}>
                            <div style={this.styles.roundedContainer}>
                                <RichTextDisplay draftContent={signup.termsAndConditions}/>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: -5, marginTop: 20}}>
                                    <Checkbox color='primary' checked={this.state.tcAgree} 
                                            onChange={(event) => this.setState({tcAgree: event.target.checked})}/>
                                    <Typography variant="body2">I agree to the Terms and Conditions</Typography>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 40, gap: 30}}>
                        <Button disabled={!canSubmit} variant='contained' onClick={() => this._submit(grandTotal, false)} 
                                style={{backgroundColor: canSubmit ? ThemeColors.appBarBackground : ThemeColors.veryLightGray, color: 'white'}}>
                                    {grandTotal > 0 ? "Pay Now with Card" : "Confirm Signup"}
                        </Button>
                        {signup.allowCashPayment && grandTotal > 0 ?
                            <Button disabled={!canSubmit} variant='contained' onClick={() => this._submit(grandTotal, true)} 
                                    style={{backgroundColor: canSubmit ? ThemeColors.appBarBackground : ThemeColors.veryLightGray, color: 'white'}}>
                                        Pay Later
                            </Button>
                            : null
                        }
                    </div>
        

                </div>
                {this.state.showPayment ? 
                    <PatronCheckout returnFromPayment={this._returnFromPayment} 
                                    paymentDescription={"Amount to Charge: " + Currency.round(grandTotal) + " " + signup.isoCurrency}
                                    purchaseComplete={() => this._nextPageCallback(this._patron.id, 0)}
                                    clientSecret={this.state.clientSecret}
                                    email={this.state.patronEmail}/>
                    : null
                    }
            </Fragment>
        );
    }

}

export default withCookies(withRouter(EventSignupPage2));
