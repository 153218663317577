import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles'
import { AppBar, Toolbar, Typography, Container } from '@material-ui/core'

import EventSignupPage1 from '../patron/EventSignupPage1'
import EventSignupPage2 from '../patron/EventSignupPage2'
import EventSignupPage3 from '../patron/EventSignupPage3'
import { ManageSignupPage } from '../patron/ManageSignupPage'

import { Global } from '../models/Global'
import { CalEvent } from '../models/CalEvent'
import { Patron } from '../models/Patron'
import AGTheme, { ThemeColors } from '../Theme'
import { logo } from '../App'


import { RestComponent, HomepageContext } from 'react-frontend-utils'



/**
 * Landing page for the /patron/event route.  This page is the entry point for a Patron to sign up for Events, or manage an existing Event. They must arrive with a database 
 * parameter in the query string and the future public events will be checked for all available categories. If there are no distinct categories, the page will 
 * go directly to the EventSignupPage1. Otherwise, a list of available categories will be displayed for the Patron to select from.
 * 
 * If event id is provided, the page will go directly to EventSignupPage2 to signup.
 * 
 * If the patronid is provided, the page will go directly to ManageSignupPage to manage the signup.  The patronKey must also be provided.
 * 
 * The timezone for the community is fetched first, then the future public Events are fetched.
 * 
 */
class PatronEventSignup extends RestComponent {
  
    styles = {       
        divider: {
            marginTop: 10, 
            marginBottom: 20, 
            border: '2px solid gray'
        },
        link : {
            display: 'flex', 
            justifyContent: 'center', 
            color: 'white',
            textDecoration: 'underline',
            fontSize: 14
        },
        appTitle: {
            marginLeft: 10,
            textShadow: "2px 2px #333333",
            fontWeight: "bold",
            color: 'white',
            textAlign: 'left',
            flexGrow: 1,   //fill all space to push other elements to the right edge
            textDecoration: 'none',
            cursor: 'pointer'
        },
        servicesMsg: {
            marginTop: 20,
            textAlign: 'center',
            color: 'gray',
            fontSize: "150%",
        },
    };
    
    
    _database;
    _extendedName;
    _eventid;
    _patronid;

    constructor(props) {
        super(props);
        this.state.isMobile = false;
        this.state.isVerySmall = false;
        this.state.didLoad = false;

        this.state.selectedEvent = null;
        this.state.manageEvent = null;    
        this.state.managePatron = null;

        this.state.didCancel = false;
        this.state.complete = false;
        
        this._database = props.database ? decodeURIComponent(props.database) : null;  //Arrives with the query string set to the desired database
        this._eventid = props.eventid ? encodeURIComponent(props.eventid) : null;  //encode for URL safe
        this._patronid = props.patronid ? encodeURIComponent(props.patronid) : null;  //encode for URL safe        
        this._patronKey = props.patronKey ? encodeURIComponent(props.patronKey) : null;  //encode for URL safe
    }


    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        this._fetchTimezone();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    _rootPath() {
        return "/patron/event?database=" + this._database;
    }


    //callback when window changes size
    _updateSize = () => {
        this.setState({isMobile: window.innerWidth < 916, isVerySmall: window.innerWidth < 476 });  //custom, split between bootstrap and mui
    }

    _fetchTimezone = () => {
        if (!this._database) {
            this.setState({didLoad: true});
            return;
        }

        // Fetch the timezone for the current database
        this.incrementBusy();
        this.secureJSONFetch("/tz/" + this._database, {}, this._fetchTimezoneCallback, this._fetchErrorCallback);
    }
    
    // When the timezone is fetched, update the timezone or use the default, and then fetch the services
    _fetchTimezoneCallback = (response) => {
        this.decrementBusy();
        if (response) {
            if (response.group !== this._database) {
                this.showConfirmAlert("Error", "Timezone response for wrong database", 'red');
                return;
            }
            if (!response.timezone)
                Global.setTimezone(Global.DEFAULT_TIMEZONE);
            else
                Global.setTimezone(response.timezone);

            this._extendedName = response.extendedGroupName;

            console.log("Timezone set to: " + Global.getTimezone());

            if (this._eventid)
                this._fetchEvent();
            else
                this.setState({didLoad: true});
        }
    }
  
 
    //Called after mounting the page, on error, call the fetchEventFailed so the page can finish
    _fetchEvent = () => {
        this.setBusy(true);
        this.secureJSONFetch("/patron/events/" + this._eventid, {}, this._fetchEventCallback, this._fetchEventFailed);
    }


    //Callback for fetching the Event.  If the Patron is provided, fetch the Patron too, otherwise go to the EventSignupPage1
    _fetchEventCallback = (response) => {
        this.setBusy(false);
        if (response) {
            const event = new CalEvent(response, Global.getTimezone());

            if (this._patronid) {
                // If no key, error
                if (!this._patronKey) {
                    this.showConfirmAlert("Error", "No Patron Key provided", 'red');
                    return;
                }

                // Have to fetch the patron directly since the frontend does not receive the array of patrons
                this.setBusy(true);
                this.secureJSONFetch("/patron/events/" + this._eventid + "/patrons/" + this._patronid + "?patronKey=" + this._patronKey, {}, 
                                    (response) => this._fetchPatronCallback(response, event), this._fetchEventFailed);
            }
            else
                this._eventSelect(event);
        } 
        this.setState({didLoad: true});
    }

    _fetchEventFailed = (error) => {
        this.setBusy(false);
        this.setState({didLoad: true});
        this._fetchErrorCallback(error);
    }


    _fetchPatronCallback = (response, event) => {
        this.setBusy(false);
        if (response) {
            const patron = new Patron(response);
            this.setState({manageEvent: event, managePatron: patron});
            this.props.history.push(this._rootPath() + "&eventid=" + event.id + "&patronid=" + this._patronid);
        }
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }


    // Event fetched, or patron pressed the Signup button on EventSignupPage1, goto EventSignupPage2
    _eventSelect = (event) => {

        if (event.signup.addAGSServiceFee)  {
            this.incrementBusy();
            this.secureJSONFetch("/patron/events/" + event.id + "/agsfee", {}, (response) => this._agsFeeCallback(response, event), this._fetchErrorCallback); 
        }
        else {
            this.setState({selectedEvent: event});
            this.props.history.push(this._rootPath() + "&eventid=" + event.id);
        }
    }

    _agsFeeCallback = (response, event) => {
        this.decrementBusy();
        if (response) {
            const agsServiceFee = {serviceFeeMultiplier: response.serviceFeeMultiplier, serviceFeeFixed: response.serviceFeeFixed};
            this.setState({selectedEvent: event, agsServiceFee: agsServiceFee});
            this.props.history.push(this._rootPath() + "&eventid=" + event.id);
        }
    }



    _returnFromEventSignupPage2 = () => {
        this.props.history.push(this._rootPath());
        this.setState({selectedEvent: null});
    }

    _finishedSignup = (patronId, cashAmountDueString) => {
        console.log("Signup complete");
        this.props.history.push(this._rootPath() + "&eventid=" + this.state.selectedEvent.id + "&patronid=" + patronId + "&completed=true");

        this.setState({complete: true});
        if (cashAmountDueString)
            this.showConfirmAlert("Payment Required", "Please make arrangements with your Service provider to pay " + cashAmountDueString, 'black');
    
    }


    _cancelSignup = () => {
        this.showConfirmAlert("Cancel Signup", "Are you sure you want to cancel your Event signup?", 'black', "No", this._confirmCancelSignup, "Yes, Cancel", 'red');
    }

    _confirmCancelSignup = () => {
        this.incrementBusy();
        this.secureJSONFetch("/patron/events/" + this.state.manageEvent.id + "/patrons/" + this._patronid + "/cancel?patronKey=" + this._patronKey, 
                            {method: 'POST'}, this._cancelSignupCallback, this._fetchErrorCallback); 
    }

    _cancelSignupCallback = (response) => {
        this.decrementBusy();
        this.setState({didCancel: true, manageEvent: null});
    }


    _cancelMessage = () => {
        return (
            <Fragment>
                <Typography align='center' variant="h4" style={{fontWeight: 'bold', color: 'red', marginTop: 20, marginBottom: 10}}>Signup Cancelled</Typography>
                <Typography align='center' variant="body1" style={{marginTop: 40}}>Your will receive an email confirming your signup cancellation.</Typography>
            </Fragment>
        );
    }


    render() {

        //Selects the current page to view
        const viewingPage = (() => {
                       
            if (!this.state.didLoad)
                return <div style={this.styles.servicesMsg}>Loading...</div>;

            if (!this._database)
                return <div style={this.styles.servicesMsg}>You did not arrive with the correct link from your community</div>;

            if (this.state.didCancel)
                return this._cancelMessage(true);

            // The patron arrives back with their signupid, for Managing an existing signup
            if (this.state.manageEvent) {

                return <ManageSignupPage event={this.state.manageEvent} database={this._database} patron={this.state.managePatron}
                                         cancelSignupCallback={this._cancelSignup}/>

            }

            // The patron has selected a Service
            if (this.state.selectedEvent) {

                // Patron has completed the signup, pressing back will return to the EventSignupPage1
                if (this.state.complete)
                    return <EventSignupPage3 event={this.state.selectedEvent} 
                                             prevPageCallback={this._returnFromEventSignupPage2}/>;

                // patron selected an event
                return <EventSignupPage2 database={this._database} event={this.state.selectedEvent}
                                            agsServiceFee={this.state.agsServiceFee}
                                            prevPageCallback={this._returnFromEventSignupPage2}
                                            nextPageCallback={this._finishedSignup}/>;
                
            }

            // Nothing selected, go to Calendar
            return <EventSignupPage1 database={this._database} service={this.state.selectedService} 
                                     nextPageCallback={this._eventSelect}/>;
            
            
        })();
        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = 20;
        
        const headerFontSize = this.state.isMobile ? "120%" : "170%";
               
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" onClick={this._returnFromServiceBooking} style={{...this.styles.appTitle, fontSize: headerFontSize}}>Patron Event Signup</Typography>    
                                     <Typography variant="h5" style={{...this.styles.appTitle, textAlign: 'right', fontStyle: 'italic', fontSize: headerFontSize}}>{this._extendedName}</Typography>    
                                </Toolbar>
                            </div>
                        </AppBar>

                        {serverErrorMessage}

                        <Container maxWidth={false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                            {viewingPage} 
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(PatronEventSignup));


