import { Slot } from './Slot';
import { ThemeColors } from '../Theme';

import { Global } from './Global'
import { Patron } from './Patron'
import { DateConversions } from '../utils/DateConversions'
import { Permissions } from 'react-frontend-utils'

export class OptionalCost {
    
    name;
    cost;
    
    constructor(json) {
        if (json) {
            this.name = json.name;
            this.cost = json.cost;
        }
    }

    static initNew(name, cost) {
        const oc = new OptionalCost();
        oc.name = name;
        oc.cost = cost;
        return oc;
    }

    copy() {
        const copy = new OptionalCost();
        copy.name = this.name;
        copy.cost = this.cost;
        return copy;
    }
}


export class Signup {

    category;
    maxSignups;
    requiresMembership;

    cost;
    isoCurrency;
    optionalCosts;
    serviceFeePercent;
    serviceFeeFixed;
    addAGSServiceFee;
    allowCashPayment;

    opensMinutesBefore;
    closesMinutesBefore;
    
    autoRefund;
    termsAndConditions;
    emailOnPatronSignup;

    constructor(json) {
        if (json) {
            this.category = json.category;
            this.maxSignups = json.maxSignups;
            this.patronSignupEnabled = json.patronSignupEnabled;
            this.requiresMembership = json.requiresMembership;
            this.cost = json.cost;
            this.isoCurrency = json.isoCurrency;
            this.optionalCosts = json.optionalCosts ? json.optionalCosts.map(oc => new OptionalCost(oc)) : [];
            this.serviceFeePercent = json.serviceFeePercent;
            this.serviceFeeFixed = json.serviceFeeFixed;
            this.addAGSServiceFee = json.addAGSServiceFee;
            this.allowCashPayment = json.allowCashPayment;
            this.opensMinutesBefore = json.opensMinutesBefore;
            this.closesMinutesBefore = json.closesMinutesBefore;
            this.autoRefund = json.autoRefund;
            this.termsAndConditions = json.termsAndConditions;
            this.emailOnPatronSignup = json.emailOnPatronSignup;
        }
    }

    static initNew(isoCurrency) { // set defaults values for new Signup
        const signup = new Signup();
        signup.maxSignups = 1;
        signup.cost = 0;
        signup.optionalCosts = [];
        signup.isoCurrency = isoCurrency;
        signup.serviceFeeFixed = 0;
        signup.serviceFeePercent = 0;
        return signup;
    }


    copy() {
        const copy = new Signup();
        copy.category = this.category;
        copy.maxSignups = this.maxSignups;
        copy.patronSignupEnabled = this.patronSignupEnabled;
        copy.requiresMembership = this.requiresMembership;
        copy.cost = this.cost;
        copy.isoCurrency = this.isoCurrency;
        if (this.optionalCosts)
            copy.optionalCosts = this.optionalCosts.map(oc => oc.copy());
        else
            copy.optionalCosts = [];
        copy.serviceFeePercent = this.serviceFeePercent;
        copy.serviceFeeFixed = this.serviceFeeFixed;
        copy.addAGSServiceFee = this.addAGSServiceFee;
        copy.allowCashPayment = this.allowCashPayment;
        copy.opensMinutesBefore = this.opensMinutesBefore;
        copy.closesMinutesBefore = this.closesMinutesBefore;
        copy.autoRefund = this.autoRefund;
        copy.termsAndConditions = this.termsAndConditions;
        copy.emailOnPatronSignup = this.emailOnPatronSignup;
        return copy;
    }

    toJsonForPost() {
        const copy = this.copy();
        return JSON.stringify(copy);
    }


}



export class CalEvent extends Slot {

    static Visibility = {
        PUBLIC:   {enumName: "PUBLIC", displayName: "Public", description: "Visible to the public"},
        LINK:     {enumName: "LINK", displayName: "Private Link", description: "Visible only to those with a private link"},
        INTERNAL: {enumName: "INTERNAL", displayName: "Internal", description: "Internal use only, hidden for Patrons"}
    };

    owner;
    ownerName;
    visibility;

    signup;
    patronSignups;
    cancelledSignups;
    manualSignups;

    spacesLeft;             // computed field, null or number of spaces left
    frontEndSpacesLeft;     // for patron view, when CalEvent is redacted
    locationString;         // for patron view only

    constructor(json, timezone) {
        super(json, timezone);
        if (json) {
            this.owner = json.owner;
            this.ownerName = json.ownerName;
            this.visibility = json.visibility;
            this.signup = json.signup ? new Signup(json.signup) : null;
            this.patronSignups = json.patronSignups ? json.patronSignups.map(p => new Patron(p)) : [];
            this.cancelledSignups = json.cancelledSignups ? json.cancelledSignups.map(p => new Patron(p)) : [];
            this.manualSignups = json.manualSignups ? json.manualSignups.map(p => new Patron(p)) : [];
            this.spacesLeft = json.spacesLeft;

            if (json.frontEndSpacesLeft != null)        // override spacesLeft with frontEndSpacesLeft
                this.spacesLeft = json.frontEndSpacesLeft;

            this.locationString = json.locationString;     // for display only, for patrons only
        }
    }

    static createNew(start, end, resource) {
        
        const event = new CalEvent();
        event.type = "Event";
        event.title = "Untitled Event";
        event.owner = Global.user.id;       // the user creating the event is the owner
        event.ownerName = Global.user.name();
        event.visibility = CalEvent.Visibility.PUBLIC.enumName;
        
        // call superclass method        
        event.newSlot("ev", start, end, [resource.id]);

        event.color = resource.color ? resource.color : ThemeColors.appBarBackground;

        event.patronSignups = [];
        event.cancelledSignups = [];
        event.manualSignups = [];
        return event;
    }

    copy() {
        const copy = new CalEvent();
        super.copy(copy);
        copy.owner = this.owner;
        copy.ownerName = this.ownerName;
        copy.visibility = this.visibility;
        copy.signup = this.signup ? this.signup.copy() : null;
        copy.patronSignups = this.patronSignups.map(p => p.copy());
        copy.cancelledSignups = this.cancelledSignups.map(p => p.copy());
        copy.manualSignups = this.manualSignups.map(p => p.copy());
        copy.spacesLeft = this.spacesLeft;
        return copy;
    }

    removeFieldsForNewSlot() {
        super.removeFieldsForNewSlot();
        this.signup = null;
        this.patronSignups = [];
        this.cancelledSignups = [];
        this.manualSignups = [];
    }

    canEdit(resources) {
        
        if (Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES))      // resource managers can always edit Events
            return true;

        // Event can be edited if the user has permission to manage events and is the owner
        if (Global.user.hasPermissionTo(Permissions.MANAGE_EVENTS) && this.owner === Global.user.id)
            return true;        
    }

    totalPatronCount() {
        return this.patronSignups.length + this.manualSignups.length;
    }

    symbol() {
        if (this.state === "ACTIVE")
            return "💚";

        return super.symbol();
    }


    remainingTimeMinutes() {
        if (!this.signup)
            return 0;
        
        // Get the current time, round to the next minute
        const now = DateConversions.now(Global.getTimezone()).startOf('minute').plus({minutes: 1});

        const signupEndDate = this.start.minus({minutes: this.signup.closesMinutesBefore ? this.signup.closesMinutesBefore : 0});
        return DateConversions.duration(now, signupEndDate) / 60;
    }


    details() {

        const eventInPast = this.start < DateConversions.now(Global.getTimezone());

        const eventOwnerStr = this.ownerName ? "<div>Event Owner: " + this.ownerName + "</div>" : "";
        const visibilityStr = this.visibility ? "<div>Visibility: " + this.visibility + "</div>" : "";

        let categoryStr = "";
        let signupStart = "";
        let signupEnd = "";
        let manualSignups = "";
        let spacesLeftStr = "";
        let locationStr = "";
        
        if (this.locationString)
            locationStr = "<div>Location: " + this.locationString + "</div>";       // for patron display only

        if (this.signup) {
            if (this.signup.category)
                categoryStr ="<div>Category: " + this.signup.category + "</div>";
        }
        if (this.signup && !eventInPast) {

            const signupStartDate = this.signup.opensMinutesBefore ? this.start.minus({minutes: this.signup.opensMinutesBefore}) : null;
            const signupEndDate = this.signup.closesMinutesBefore ? this.start.minus({minutes: this.signup.closesMinutesBefore}) : null;

            if (signupStartDate && this.signup.patronSignupEnabled) {
                signupStart = "<div>Signup Begins: " + DateConversions.dateTimeStr(signupStartDate) + "</div>";
            }
            else if (this.signup.patronSignupEnabled)
                signupStart = "<div>Signup Enabled: Patrons can sign up anytime</div>";
            else
                signupStart = "<div>Signup Disabled</div>";

            if (signupEndDate && this.signup.patronSignupEnabled)
                signupEnd = "<div>Signup Closes: " + DateConversions.dateTimeStr(signupEndDate) + "</div>";
            else if (this.signup.patronSignupEnabled)
                signupEnd = "<div>Signup Closes: When event starts</div>";
        }
 
        if (this.spacesLeft != null)
            spacesLeftStr = "<div>Spaces Left: " + this.spacesLeft + "</div>";

        if (manualSignups && manualSignups.length > 0)
            manualSignups = "<div>Manual Signups: " + this.manualSignups.length + "</div>";

 
        return "<div>" + eventOwnerStr + visibilityStr + categoryStr + locationStr + signupStart + signupEnd + "<div style='margin-top: 5px'/>" + manualSignups + spacesLeftStr + "<div/>";

    }

}