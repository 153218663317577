import React from 'react';


import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import adaptivePlugin from '@fullcalendar/adaptive'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3'
import { toLuxonDateTime } from '@fullcalendar/luxon3';

import { CommonCalendarFunctions } from './CommonCalendarFunctions';

import { Constructors } from '../models/Global'

import { Global } from '../models/Global';
import { ColorUtils } from 'react-frontend-utils' 

import { Booking } from '../models/Booking'
import { Resource } from '../models/Resource'
import { CalEvent } from '../models/CalEvent'
import { ThemeColors } from '../Theme';


/**
 * Wrapper around the FullCalendar component, to display a calendar of slots, and allow the user to add, update, and delete slots. This is a static component that does not
 * re-render itself with prop changes, so it is up to the user to call the component functions to update as needed.
 * 
 * User must supply the following props:
 * 
 *     resources (initial array of Resource objects): The resources to display and fetch slots for from the server
 *     initialTimezone (String): The initial timezone to use for the calendar
 *     fetchSlots(start, end, resourceIDs, successCallback, failureCallback): A function that fetches the slots from the server for the given time range and resources.
 *                start: json string of the start date/time of the range
 *                end: json string of the end date/time of the range
 *                resourceIDs: array of resource IDs to fetch slots for
 *                successCallback: A callback function that is called with the array of slots fetched from the server
 *                failureCallback: A callback function that is called with an error message if the fetch fails
 *  
 * Optional props:
 *     addCallback (function): A callback function that is called when the user clicks the add button. The function is called with the selected range, and the
 *                              Resource selected on (if applicable), and true if the view is a month or year view
 *     slotClickCallback (function): A callback function that is called when the user clicks on one or more slots. The function is called with an array of Slot objects
 *                              and the Resource clicked on (if applicable)   
 *     dateSelectCallback (function): A callback function that is called when the user clicks on a date/time with no slots. 
 *                                    The function is called with the start and end date/time of the selected range,
 *                                    the Resource clicked on (if applicable), and true if the view is a month or year view
 *     slotChangeCallback (function): A callback function that is called when the user changes the start or end time of a slot. The function is called with the original 
 *                                    slot object, the new start and end times, and if the resource has changed, a structure with the old and new resource ids
 *     optionsClickCallback (function): A callback function that is called when the user clicks the options (gear) button. The function is called with the button element
 *     loadingCallback (function): A callback function that is called when the calendar is loading events and rendering. The function is called with a boolean (true if loading)
 *     initialHighlightedSlot: The ID of the slot to highlight when the calendar is first loaded, or null if no slot should be highlighted
 * 
 * The following functions are available to users of the BookingCalendar component:
 *    refetchEvents(): Force a complete refetch of the events from the server
 *    gotoDate(date): Make the calendar go to the given luxon date
 *    updateTimezone(timezone): Update the timezone of the calendar
 *    updateResources(resources, andRefetchEvents): Update the resources and refetch the slots from the server. If andRefetchEvents is true, the events  will be refetched.
 *    removeSlot(slotID): Remove a slot from the calendar's internal cache, by slot ID
 *    upsertSlot(slot): Add or update a slot in the calendar's internal cache. When refreshed from the server, the slot with the same ID will be updated
 *    highlightSlot(slot): Highlight the given slot, by changing the color of the slot and making it more prominent, and de-highlight the previous slot
 *                         If null is passed, the previous slot is de-highlighted    
 *    updateHiddenSlots(): Parse through all the slots and update the display based on their display type
 *    setGridSlotDuration(duration): Set the duration of the grid slots in minutes
 * 
 */
export class BookingCalendar extends React.Component {

    _ref = React.createRef();
    _timezone;
    _resources;

    _refetchEventsOnResourceUpdate = false;
    _highlightSlotOnLoad = null;      // when first loading, this will be set to the slot id to highlight

    // Luxon DateTime objects for the selected range
    _startRangeSelected;
    _endRangeSelected;
    _resourceSelected;
    
    _resourceElements = new Map();      // holds the dom elements for direct label color manipulation in the Timeline

    // Cache the last fetch request, when resizing the calendar or when it re-renders itself it will refetch the events, but if the range is the same
    // as the last fetch, we don't need to refetch
    _eventCache = {start: null, end: null, events: []}

    _highlightedSlotId = null;

    _getApi = () => {
        if (this._ref.current)
            return this._ref.current.getApi();
        return null;
    }

    static isMonthOrYearView = (view) => {
        return view.type === 'multiMonthYear' || view.type === 'dayGridYear';
    }


    // Produce an array of FullCalendar resources from our Resources, split into Resources and Services. Then within each one of those,
    // group them by category
    static parseResourceArray = (resources) => {
        // Break up the resources into categories
        const resourceCategories = Resource.groupResourcesByCategory(resources, "Resource");
        const serviceCategories =  Resource.groupResourcesByCategory(resources, "Service"); 

        // Each category is an object with children, where the children is the array of the category's resources
        const resourceCategoryCals = resourceCategories.map((category) => {
            return { id: category[0].category,
                     title: category[0].category ? category[0].category : "Uncategorized",
                     children: category.map((resource) => resource.toFullCalendarResource())
                    }
            });
            

        const serviceCategoryCals = serviceCategories.map((category) => {
            return { id: category[0].category,
                     title: category[0].category ? category[0].category : "Uncategorized",
                     children: category.map((resource) => resource.toFullCalendarResource())
                    }
                });

        const calResources = [];
        
        if (resourceCategoryCals.length > 0)
            calResources.push({id: "resources", title: "Resources", children: resourceCategoryCals});

        if (serviceCategoryCals.length > 0)
            calResources.push({id: "services", title: "Services", children: serviceCategoryCals});

        return calResources;
    }
    


    constructor(props) {
        super(props);
        this._timezone = props.initialTimezone;
        this._resources = props.resources;
    }

    componentDidMount() {
        // Before the calendar is mounted, nothing is fetched on initial render (because api is required), so we
        // refetch the resources and events after the component is mounted
        this._refetchEventsOnResourceUpdate = true;  
        this._highlightSlotOnLoad = this.props.initialHighlightedSlot;
        this._getApi().refetchResources();
    }

    // See if the component should re-render, re-rendering is expensive because it refetches the events. So we only do it if we have to
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    _getListColumnDataForSlot = (slot) => {
        // Get names of all the Resources for this Slot
        const resourceNames = this._resources.filter((resource) => slot.resourceIds.includes(resource.id) && resource.type === "Resource").map((resource) => resource.name);
        const services = this._resources.filter((resource) => slot.resourceIds.includes(resource.id) && resource.type === "Service");
        const serviceNames = services.map((service) => service.name);

        let owner = ""
        if (slot instanceof CalEvent)
            owner = slot.ownerName ? slot.ownerName : "";
        else if (slot instanceof Booking) {
            const service = services[0];    // the first service is the only Service, and that Service owner is the Booking owner
            if (service && service.ownerName)
                owner = service.ownerName;
        }

        let toInject = [];
        toInject.push(serviceNames.join(', '));
        toInject.push(resourceNames.join(', '));
        toInject.push(owner);
        toInject.push(slot.description);

        return toInject;
    }
   

    // When resources change, we need to refetch the events if the view is the List view, because the Resource/Service columns may change
    _refetchEventsForListView = () => {
        const api = this._getApi();
        if (!api)
            return;

        if (api.view.type === 'list7days') {
            console.log("Refetching events for List view");
            this.refetchEvents();
        }
    }


    // -----------------------------  USER FUNCTIONS  -----------------------------


    // Users of the BookingCalendar component can call this function to force a complete refetch of the events from the server
    refetchEvents = () => {
        const api = this._getApi();
        if (api) {
            this._eventCache = {start: null, end: null, events: []};      // clear cache
            console.log("Event refetch (cache cleared)");
            api.refetchEvents();
        }
    }

    // Make the calendar go to the given date
    gotoDate = (date) => {
        const fullCalendarDate = date.toISO();
        const api = this._getApi();
        if (api)
            api.gotoDate(fullCalendarDate);
    }

    // Users of the BookingCalendar component can call this function to update the timezone
    updateTimezone = (timezone) => {
        this._eventCache = {start: null, end: null, events: []};   // clear cache
        const api = this._getApi();
        if (api) {
            this._timezone = timezone;
            api.setOption('timeZone', timezone);
        }
    }

    // Users of the BookingCalendar component can call this function to update the resources and refetch the slots from the server
    updateResources = (resources, andRefetchEvents) => {
        this._resources = resources;
        const api = this._getApi();
        if (api) {
            this._refetchEventsOnResourceUpdate = andRefetchEvents;
            api.refetchResources();
        }
    }

  
    // Users of the BookingCalendar component can call this function to Remove a slot from the calendar's internal cache, by slot ID
    removeSlot = (slotID) => {
        const api = this._getApi();
        if (api) {
            const event = api.getEventById(slotID);
            if (event)
                event.remove();
        }
    }

    // Users of the BookingCalendar component can call this function to Add or update a slot in the calendar's internal cache. 
    // When refreshed from the server, the slot with the same ID will be updated
    upsertSlot = (slot) => {
        this.removeSlot(slot.id);       // Remove the slot if it exists
        const event = slot.toFullCalendarEvent(this._resources);
        const api = this._getApi();
        if (api)
            api.addEvent(event, 'server');
    }


    // highlight the given slot, by changing the color of the slot and making it more prominent, and de-highlight the previous slot
    highlightSlot = (slot) => {
        const api = this._getApi();
        if (!api)
            return;

        if (this._highlightedSlotId) {      // de-highlight the previous slot
            const event = api.getEventById(this._highlightedSlotId);
            if (event) {
                const slot = event._def.extendedProps.slotObject;
                if (event) {
                    if (event._context.currentViewType !== 'list7days') {   
                        event.setProp('display', slot.fullCalendarDisplayType(this._resources));   // restore the original display
                        event.setProp('color', slot.color);    // restore the original color
                    }
                    else {
                        const el = document.querySelector("[slotid='" + this._highlightedSlotId + "']");     // find the element by the slot id, it should be unique
                        if (el)
                            el.style.backgroundColor = 'white';
                    }
                }
            }
            else {
                this._highlightedSlotId = null;     // the slot was removed, so clear the highlighted slot
            }
        }
        this._highlightedSlotId = slot ? slot.id : null;

         // highlight the new slot
        if (this._highlightedSlotId) {   
            const event = api.getEventById(this._highlightedSlotId);
            
            if (event) {
                // make the color darker or lighter as needed to highlight
                let factor = ColorUtils.fontColorForBackground(slot.color) === 'black' ? 0.8 : 1.5;  // if the text would be black black, the background is lighter, so we need to darken it more, and vice versa
  
                if (event._context.currentViewType !== 'list7days') {
                    if (event.display !== 'background')     // don't change the display if it's a background event
                        event.setProp('display', 'block');
                        event.setProp('color', ColorUtils.darken(slot.color, factor));
                }
                else {
                    const el = document.querySelector("[slotid='" + slot.id + "']");     // find the element by the slot id, it should be unique
                    if (el)
                        el.style.backgroundColor = ThemeColors.listSelectedColor;
                }
            }
        }
    }

    // Parse through all the slots and update the display based on their display type
    updateHiddenSlots = () => {
        const api = this._getApi();
        if (!api)
            return;

        const events = api.getEvents();
        for (let event of events) {
            const slot = event._def.extendedProps.slotObject;
            const displayType = slot.fullCalendarDisplayType(this._resources);
            event.setProp('display', displayType);
        }
    }        

    setGridSlotDuration = (duration) => {
        const api = this._getApi();
        if (api)
            api.setOption('slotDuration', '00:' + duration + ':00');
    }

    // -----------------------------  EVENT FETCHING  -----------------------------


    // Since we are using the Luxon plugin, we must use the toLuxonDateTime function to convert the JSDate managed by the calendar
    // to a Luxon DateTime object
    _fullCalendarDateToLuxonDateTime = (date) => {
        return toLuxonDateTime(date, this._getApi());
    }


    // This function is called by the FullCalendar component to fetch events for the current view, the ref needs to exist so the component must
    // mount first. Fetch all the events within the specified time range, for the resources in the resources array, from the server
    _asyncFetchEvents = (fetchInfo, fetchEventsCallback, failureCallback) => {

        if (!this._getApi()) {       // component not mounted, no fetch
            fetchEventsCallback([]);
            return;
        }

        const start = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.start, this._getApi());
        const end = CommonCalendarFunctions.fullCalendarDateToUTCJsonDateString(fetchInfo.end, this._getApi());

        // If the range is the same as the last fetch, don't fetch again - just use the cache
        if (this._eventCache.start === start && this._eventCache.end === end) {
            console.log("Using cached events in range " + start + " to " + end);
            fetchEventsCallback(this._eventCache.events);
            return;
        }
        else
            this._eventCache = {start: null, end: null, events: []};    // clear cache


        if (this._resources.length === 0) {
            fetchEventsCallback([]);    // No resources, no events
            return;
        }

        console.log("Fetching events from " + start + " to " + end + " for " + this._resources.length + " resources");

        const resourceIDs = this._resources.map((resource) => resource.id);

        // Tell the caller to fetch slots, the callback will be our fetchEventsCallback
        this.props.fetchSlots(start, end, resourceIDs, 
                              (response) => this._fetchSlotsCallback(response, fetchEventsCallback, start, end), 
                              (error) => failureCallback(error));
    }

    
    _fetchSlotsCallback = (response, fetchEventsCallback, start, end) => {
        if (response) {
            const slots = response.map(json => Constructors.slotFromJson(json, this._timezone));
            const events = slots.map(slot => slot.toFullCalendarEvent(this._resources));

            this._eventCache = {start: start, end: end, events: events};   // put to cache
            fetchEventsCallback(events);
        }
        else {
            console.error("Error fetching events");
            fetchEventsCallback([]);   // No events
        }
    }

    
    // --------------------------- CALENDAR CALLBACKS FOR USER CLICKS ----------------------------


    _areBackgroundEventsVisibleInCurrentView = () => {
        const api = this._getApi();
        if (!api)
            return false;

        if (api.view.type === 'resourceTimeline7days' || api.view.type === 'timeGridWeek' || api.view.type === 'timeGridDay')
            return true;
        else
            return false;
    }


    // For a given date, return an array of Slot objects that contain that date
    _eventsContainingBlockOrAvailability = (date) => {
        const events = this._getApi().getEvents();  // all in-memory events

        const clickedEvents = [];
        for (let event of events) {
            const slot = event._def.extendedProps.slotObject;
            if (date >= event.start && date < event.end && (slot.type === "Block" || slot.type === "Availability"))
                clickedEvents.push(slot);
            
        }
        return clickedEvents;
    }


    // Save the selected range. Make the callback for the selected range only if there are no background events in the selected range. This is because the dateClick
    // will also be called if the user clicks on a background event, and we want that callback to take precedence
    _rangeSelect = (selectInfo) => {
        this._startRangeSelected = this._fullCalendarDateToLuxonDateTime(selectInfo.start);
        this._endRangeSelected = this._fullCalendarDateToLuxonDateTime(selectInfo.end);
        this._resourceSelected = selectInfo.resource ? this._resources.find((resource) => resource.id === selectInfo.resource.id) : null;

        if (this.props.dateSelectCallback) {

            // Only check for Blocks/Availability if the view shows them
            if (this._areBackgroundEventsVisibleInCurrentView()) {

                const slotsInStartRange = this._eventsContainingBlockOrAvailability(selectInfo.start);
                const slotsInEndRange = this._eventsContainingBlockOrAvailability(selectInfo.end.setSeconds(selectInfo.end.getSeconds() - 1));  // end is exclusive, so subtract a second
                let slotsInSelectedRange = slotsInStartRange.concat(slotsInEndRange);

                if (selectInfo.resource) {   // if a resource was selected, we need to filter the Slots to only those that contain the resource
                    const resourceID = selectInfo.resource.id;
                    slotsInSelectedRange = slotsInSelectedRange.filter((slot) => slot.resourceIds.includes(resourceID));
                }

                // don't call the dateSelectCallback if there are slots on the selected range, and clear the selection
                if (slotsInSelectedRange.length > 0) {
                    this._getApi().unselect();  
                    return;
                }
            }

            const isMonthOrYearView = BookingCalendar.isMonthOrYearView(selectInfo.view);

            if (this._startRangeSelected > this._endRangeSelected)             
                this.props.dateSelectCallback(this._endRangeSelected, this._startRangeSelected, this._resourceSelected, isMonthOrYearView);    // swap them, so the start is before the end
            else
                this.props.dateSelectCallback(this._startRangeSelected, this._endRangeSelected, this._resourceSelected, isMonthOrYearView);
        }
    }

    _rangeUnselect = (unselectInfo) => {
        // The unselect event is fired before the add button event, which needs the selected range, so we need to delay clearing the range
        // until after the add button event is processed
        setTimeout(() => {this._startRangeSelected = null; this._endRangeSelected = null;}, 0);
    }

    // When the user clicks on an event, we need to find the Slot object that corresponds to the event, and call the slotClickCallback. This only works for
    // real calendar events, not background events. Those are selected by dateClick
    _eventClick = (clickInfo) => {
        const slot = clickInfo.event._def.extendedProps.slotObject;
        if (this.props.slotClickCallback)
            this.props.slotClickCallback([slot]);
    }

    // When the user clicks on a date, we need to find the Block or Availability objects that correspond to the events on that date, and call the slotClickCallback
    // with those slots.  This is because we can't select Block or Availability slots (they are background events), so we need to find them by date
    _dateClick = (dateClickInfo) => {
        if (!this._areBackgroundEventsVisibleInCurrentView())  // no Blocks visible, so we can't have clicked on one
            return;

        let clickedSlots = this._eventsContainingBlockOrAvailability(dateClickInfo.date);

        if (dateClickInfo.resource) {   // if a resource was clicked, we need to filter the Slots to only those that contain the resource
            const resourceID = dateClickInfo.resource.id;
            clickedSlots = clickedSlots.filter((slot) => slot.resourceIds.includes(resourceID));
        }

        if (clickedSlots.length > 0 && this.props.slotClickCallback)
            this.props.slotClickCallback(clickedSlots);
    }

    // When the user clicks the add button, we need to call the addCallback with the selected range, and the selected resource, and the view type
    _addButtonPressed = () => {
        const isMonthOrYearView = BookingCalendar.isMonthOrYearView(this._getApi().view);
        if (this.props.addCallback)
            this.props.addCallback(this._startRangeSelected, this._endRangeSelected, this._resourceSelected, isMonthOrYearView);
    }

    _todayButtonPressed = () => {
        CommonCalendarFunctions.todayButtonPressed(this._getApi());
    }

    _gearButtonPressed = (mouseEvent) => {
        const buttonElement = mouseEvent.srcElement;

        if (this.props.optionsClickCallback)
            this.props.optionsClickCallback(buttonElement);
    }

    // Our fetch function which just parses our local resources into FullCalendar resources
    _fetchResources = (fetchInfo, successCallback, failureCallback) => {
        console.log("Fetching resources");
        const calResources = BookingCalendar.parseResourceArray(this._resources);
        successCallback(calResources);
        if (this._refetchEventsOnResourceUpdate) {
            this.refetchEvents();
            this._refetchEventsOnResourceUpdate = false;
        }
        this._refetchEventsForListView();       // we need to refetch anyway in List view
    }

    // -----------------------------  RENDERING  -----------------------------


    // When a resource is mounted, we need to set the color of the resource label if we have a color, and store the reference to the resource element
    // so we can change the color of the label directly when updating a single resource. Since the only View that uses Resources is the Timeline, we
    // don't care about checking the View
    _resourceDidMount = (info) => {
        const resource = info.resource;
        if (resource.extendedProps.color) {
            info.el.style.backgroundColor = resource.extendedProps.color;
            info.el.style.color = ColorUtils.fontColorForBackground(resource.extendedProps.color);
        }

        if (resource.id === "resources" || resource.id === "services") {
            info.el.style.fontWeight = "bold";
            info.el.style.fontSize = "14pt";
        }
        else {
            // Store the reference to the resource element
            this._resourceElements.set(resource.id, info.el);
        }
    }

    // When a resource is unmounted, remove the reference to the resource element
    _resourceWillUnmount = (info) => {
        this._resourceElements.delete(info.resource.id);
    }


    // Custom columns for List view. We need to inject the Services, Resources, and Description into the DOM to create new columns.
    _eventMounted = (info) => {
        if (info.view.type === 'list7days') {

            const slot = info.event._def.extendedProps.slotObject;

            info.el.setAttribute("slotid", slot.id);              // mark this so we know how to find it

            let toInject = this._getListColumnDataForSlot(slot);
            for (let i = 0; i < toInject.length; i++) {
                let columnElement = document.createElement('td');
                columnElement.className = "fc-list-ancillary-item";         // mark this so we know how to find it
                columnElement.textContent = toInject[i];
                info.el.append(columnElement);
            }
        }
    }

    _eventsSet = (info) => {
        const api = this._getApi();
        if (!api)
            return;
        
        if (this._highlightSlotOnLoad) {

            const event = api.getEventById(this._highlightSlotOnLoad);
            if (event) {
                const slot = event._def.extendedProps.slotObject;
                if (slot) {
                    this.gotoDate(slot.start);
                    setTimeout(() => this.highlightSlot(slot));  // delay the highlight so the calendar has time to render
                }
            }
            this._highlightSlotOnLoad = null;       // clear the highlight slot, so it doesn't get highlighted again
        }
    }

    _eventMouseEnter = (info) =>{
        const slot = info.event._def.extendedProps.slotObject;
        CommonCalendarFunctions.showTippyPopover(slot, this._resources, info.el);
    }


    // Custom column headers for List view
    _dayHeaderMounted = (info) => {
        if (info.view.type === 'list7days') {

            for (let child of info.el.children) {
                for (let subchild of child.children)
                    subchild.style="text-align: left; background-color: " + (info.isToday ? ThemeColors.todayTan : ThemeColors.listHeaderColor);
            }

            let toInject = [];
            toInject.push('Services');
            toInject.push('Resources');
            toInject.push('Owner');
            toInject.push('Details');
            for (let i = 0; i < toInject.length; i++) {
                let columnElement = document.createElement('th');
                let div = document.createElement('div');
                div.className="fc-list-day-cushion fc-cell-shaded";
                if (info.isToday)
                    div.style="text-align: left; background-color: " + ThemeColors.todayTan;
                else
                    div.style="text-align: left; background-color: " + ThemeColors.listHeaderColor;
                div.textContent = toInject[i];
                columnElement.append(div);
                info.el.append(columnElement);
            }
        }
    }


    // Only allow Drag or Resize on slots that can be edited
    _eventDragOrResizeAllow = (dropInfo, draggedEvent) => {
        const slot = draggedEvent._def.extendedProps.slotObject;
        return slot.canEdit(this._resources);
    }


    // When the user drags or resizes an event, we need to find the Slot object that corresponds to the event, and call the slotChangeCallback
    // with the new start and end times
    _eventDropOrResize = (dropInfo) => {
        const slot = dropInfo.event._def.extendedProps.slotObject;
        const start = this._fullCalendarDateToLuxonDateTime(dropInfo.event.start);
        const end = this._fullCalendarDateToLuxonDateTime(dropInfo.event.end);

        let resourceChange = null;
        if (dropInfo.oldResource)
            resourceChange = {oldResourceId: dropInfo.oldResource.id, newResourceId:  dropInfo.newResource.id};

        if (this.props.slotChangeCallback)
            this.props.slotChangeCallback(slot, start, end, resourceChange);
    }


    render() {

        const resourceAreaColumns = [{field: 'title', headerContent: ''}];

        return (
            <FullCalendar schedulerLicenseKey={Global.fullCalendarLicenseKey} 
                            ref={this._ref}
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, multiMonthPlugin, resourceTimelinePlugin, 
                                      adaptivePlugin, listPlugin, luxonPlugin]}
                            timeZone={this._timezone}
                            initialView="dayGridYear"
                            height={window.innerHeight * .8}
                            nowIndicator={true}
                            monthStartFormat={{month: 'short', day: 'numeric'}}
                            slotDuration={'00:' + this.props.gridSlotDuration + ':00'}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'narrow'
                            }}
                            slotLabelFormat={{ hour: 'numeric', minute: 'numeric', merdiem: 'short' }}
                            navLinks={true}
                            allDaySlot={false}
                            headerToolbar={{
                                left: 'refresh prev,next customToday add',
                                center: 'title',
                                right: 'multiMonthYear,dayGridYear,timeGridWeek,timeGridDay resourceTimeline7days,list7days gear',
                            }}
                            buttonText={{
                                multiMonthYear: 'Year',
                                dayGridYear: 'Month',
                                resourceTimeGridDay: 'Day',
                                timeGridWeek: "Week",
                                timeGridDay: "Day",
                                resourceTimeline7days: 'Timeline',
                                list7days: 'List'
                            }}
                            views={{
                                resourceTimeline7days: {
                                  type: 'resourceTimeline',
                                  duration: { days: 7 },
                                  slotLabelFormat: [
                                    { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true},
                                    { hour: 'numeric', meridiem: 'narrow' }, // top level of text
                                  ]
                                },
                                list7days: {
                                    type: 'list',
                                    duration: { days: 7 }
                                }
                            }}
                            resources={this._fetchResources}
                            resourceAreaColumns={resourceAreaColumns}
                            resourceOrder='title'
                            resourceLabelDidMount={this._resourceDidMount}
                            resourceLabelWillUnmount={this._resourceWillUnmount}
                            resourceAreaWidth='20%'
                            events={{events: this._asyncFetchEvents, id: 'server'}}  
                            editable={true}
                            eventsSet={this._eventsSet}
                            eventStartEditable={true}
                            eventResizableFromStart={true}
                            eventDurationEditable={true}
                            eventDrop={this._eventDropOrResize}
                            eventResize={this._eventDropOrResize}
                            eventAllow={this._eventDragOrResizeAllow}
                            eventDidMount={this._eventMounted}
                            eventWillUnmount={this._eventWillUnmount}
                            eventMouseEnter={this._eventMouseEnter}
                            dayHeaderDidMount={this._dayHeaderMounted}
                            selectable={true}
                            select={this._rangeSelect}  
                            unselect={this._rangeUnselect}  
                            dateClick={this._dateClick}
                            eventClick={this._eventClick}
                            loading={this.props.loadingCallback}
                            customButtons={ {refresh: {text: '↻', click: this.refetchEvents, hint: 'Refresh Calendar Items'},
                                             add: {text: '➕', click: this._addButtonPressed, hint: 'Add to Calendar'}, 
                                             customToday: {text: 'Today', click: this._todayButtonPressed, hint: 'Go to Today'},
                                             gear: {text: '⚙️', click: this._gearButtonPressed, hint: 'Options'}}
                                          }
                            listDayFormat= {{
                                month: 'short',
                                day: 'numeric',
                                weekday: 'short'
                              }}
            />

        );
    }


}


export default BookingCalendar;
