import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ReplyIcon from '@material-ui/icons/Reply';
import { Tooltip, Grid, Typography, Checkbox, Paper, TextField, Button, IconButton } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeColors } from '../Theme'
import { Global, Constructors } from '../models/Global'
import { Service, ServiceParams } from '../models/Service'
import { LuxonDatePicker } from '../components/LuxonDateTimePicker'

import { RestComponent } from 'react-frontend-utils' 
import { OpenInNewTab } from '../App'

import { DateUtils, SummaryWidget, ManageTextField, ManageDecimalField, ManageNumericField, ManageTimeField, RichTextEditor } from 'react-frontend-utils'
import { DateConversions } from '../utils/DateConversions';

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const longDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export class ServicesPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 5,
            marginBottom: 30,
            color: 'gray',
            fontSize: '14pt',
            flexGrow: 1
        },
        paper: {
            padding: 10
        },
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20,
        },
        containerLabel: {
            fontSize: 14, 
            color: ThemeColors.darkGray, 
            marginLeft: 5
        },
        sectionLabel: {
            fontSize: 16, 
            color: 'black', 
            marginLeft: 5
        },
        viewLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 100,
            float: 'right',
            textTransform: 'uppercase',
            padding: 5, 
            marginRight: 10,
            marginTop: 2,
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
    };

    _manageServiceId = null;        // from the Calendar page
    _currentDatabase = Global.getLastDatabase();

    _additionalCostHash =  window.crypto.randomUUID();

    constructor(props) {
        super(props);
        
        this.state.services = [];                 //The list of Services
        this.state.selectedService = null;        //The currently selected Service
        this.state.status = null;                  //The status of the selected Service

        this.state.bookingEnabled = false;

        this.state.seasonBookingAutoEnableChecked = false;
        this.state.seasonBookingStartChecked = false;
        this.state.seasonBookingEndChecked = false;

        this.state.usesSlotCalendar = false;
    }
    
    
   
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("databaseChangeEvent", this._databaseChanged);
        this._manageServiceId = this.props.manageServiceId;
        this._fetchAll();
    }

    // When the database changes, refetch the Services
    _databaseChanged = () => {
        if (Global.getLastDatabase() !== this._currentDatabase) {
            console.log("Database changed to " + Global.getLastDatabase() + ", refetching Services");
            this._fetchResources();
            this._currentDatabase = Global.getLastDatabase();
        }
    }
    
    //Fetch all Services
    _fetchAll = () => {
        this.setState({services: [], selectedService: null, status: null});
   
        this.incrementBusy();
        this.secureJSONFetch("/bk/" + Global.getLastDatabase() + "/resources", {}, 
                            this._fetchResourcesCallback, this._fetchErrorCallback);         
    }
    
    // When the resources are fetched, filter them into just Services
    _fetchResourcesCallback = (response) => {
        this.decrementBusy();
        if (response) {
            const resources = response.map((json) => Constructors.resourcefromJson(json));
            const services = resources.filter((resource) => resource.type === "Service");

            const aggregate = {active: {count: 0, names: []}, waiting: {count: 0, names: []}, finished: {count: 0, names: []}, disabled: {count: 0, names: []}};
            services.forEach((service) => {
                switch(service.status()) {
                    case Service.Status.DISABLED:
                        aggregate.disabled.count++;
                        aggregate.disabled.names.push(service.name);
                        break;
                    case Service.Status.WAITING:
                        aggregate.waiting.count++;
                        aggregate.waiting.names.push(service.name);
                        break;
                    case Service.Status.FINISHED:
                        aggregate.finished.count++;
                        aggregate.finished.names.push(service.name);
                        break;
                    case Service.Status.ACCEPTING:
                        aggregate.active.count++;
                        aggregate.active.names.push(service.name);
                        break;
                    default:
                        console.error("Unknown status for Service: " + service.status());
                        break;
                }
            });

            if (this._manageServiceId) {
                const selectedService = services.find((service) => service.id === this._manageServiceId);
                if (selectedService)
                    setTimeout(() => this._selectService(null, selectedService));
            }

            this.setState({services: services, aggregate: aggregate});

        }
    }
   
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
    _gotoPatronBooking = () => {
        OpenInNewTab("patron/booking?database=" + this._currentDatabase + "&serviceid=" + this.state.selectedService.id);
    }

    
    _discard = () => {
        this._manageServiceId = null;
        this._fetchAll();
    }


    //Post the current ServiceParams to modify to the server
    _save = () => {
        if (this.state.selectedService) {   
            
            // Check each of the business hours to make sure open is before close
            for (let i = 0; i < 7; i++) {
                if (this.state.selectedService.serviceParams.businessHours[i].enabled) {
                    const diff = DateUtils.diffTimeStrings(this.state.selectedService.serviceParams.businessHours[i].open, this.state.selectedService.serviceParams.businessHours[i].close);
                    if (diff < 0) {
                        this.showConfirmAlert("Error", "On " + longDays[i] + ", the closing time must be after the opening time", 'red');
                        return;
                    }
                }
            }

            //Make a copy of the selected ServiceParams to be posted back to the server.  Date fields are converted from luxon date objects to json date strings
            const serviceParamsToModify = ServiceParams.copyForPosting(this.state.selectedService.serviceParams);

            if (!this.state.seasonBookingAutoEnableChecked)
                serviceParamsToModify.seasonBookingAutoEnable = null;       // null this field if the checkbox is not checked
    
            if (!this.state.seasonBookingStartChecked)
                serviceParamsToModify.seasonBookingStart = null;       // null this field if the checkbox is not checked
    
            if (!this.state.seasonBookingEndChecked)
                serviceParamsToModify.seasonBookingEnd = null;       // null this field if the checkbox is not checked
    
            
        
            this.incrementBusy();
            this.secureJSONFetch("/bk/services/" + this.state.selectedService.id + "/serviceParams", {method: 'POST', body: JSON.stringify(serviceParamsToModify)}, 
                                 this._saveCallback, this._fetchErrorCallback);
        }
    }
    
    
    _saveCallback = () => {
        this.showConfirmAlert("Success", "Service \"" + this.state.selectedService.name + "\" updated", 'green'); 
        this.decrementBusy();
        this._manageServiceId = this.state.selectedService.id;      // hold onto this for refresh
        this._fetchAll();
    }
  
 
    
    //Callback when a Service is selected from the available list, display the selected Service
    _selectService = (event, newValue) => { 

        console.log("Selected Service: " + newValue.name);
        const selectedService = newValue;

        //We need to hold the status in the state, since we're going to modify the selectedService object below which will affect status
        const status = selectedService.status();

        this.setState({selectedService: selectedService, status: status,
                       bookingEnabled: selectedService.serviceParams.enabled,
                       seasonBookingAutoEnableChecked: selectedService.serviceParams.seasonBookingAutoEnable ? true : false,
                       seasonBookingStartChecked: selectedService.serviceParams.seasonBookingStart ? true : false,
                       seasonBookingEndChecked: selectedService.serviceParams.seasonBookingEnd ? true : false});

        // If these fields aren't set, LuxonDatePicker is going to initialize them to the current date. So if the user never changes them, we want our value
        // to be set to the current date as well.  If the check mark is never checked, we don't care, since we'll null them out before post
        const now = DateConversions.now(Global.getTimezone()).startOf('day');
        if (!selectedService.serviceParams.seasonBookingAutoEnable)
            selectedService.serviceParams.seasonBookingAutoEnable = now;
        if (!selectedService.serviceParams.seasonBookingStart)
            selectedService.serviceParams.seasonBookingStart = now;
        if (!selectedService.serviceParams.seasonBookingEnd)
            selectedService.serviceParams.seasonBookingEnd = now;

        // Set some default hours of operation if not set
        for (let i = 0; i < 7; i++) {
            if (!selectedService.serviceParams.businessHours[i])
                selectedService.serviceParams.businessHours[i] = {enabled: false, allDay: false};

            if (!selectedService.serviceParams.businessHours[i].open)
                selectedService.serviceParams.businessHours[i].open = DateUtils.parseTimeString("9:00 AM");
            if (!selectedService.serviceParams.businessHours[i].close)
                selectedService.serviceParams.businessHours[i].close = DateUtils.parseTimeString("5:00 PM");
        }

        this.setState({usesSlotCalendar: selectedService.usesSlotCalendar()});
    }
    

    
    //A field in the Service was changed, update the selected Service object with the new data
    _serviceParamsFieldChanged = (fieldName, userValue) => {
        this.state.selectedService.serviceParams[fieldName] = userValue;  
        this.setState({usesSlotCalendar: this.state.selectedService.usesSlotCalendar()});
    }

    _onDateParseError = (title, message) => {
        this.showConfirmAlert(title, message, 'red');
    }
    
    _tooltipForCount = (nameList, title) => {
        
        const spliceList = [...nameList];  //shallow copy
        const matrix = [];
        while (spliceList.length) 
            matrix.push(spliceList.splice(0,3));
        
        return  <div>
                    <Typography variant='caption' style={{borderBottom: '1px solid white'}}>{title}</Typography>
                    <table>
                        <tbody>
                        {matrix.map((row, index) => <tr key={index}>
                                                <td style={{fontSize: 13, paddingRight: 10}}>{row[0]}</td>
                                                <td style={{fontSize: 13, paddingRight: 10}}>{row[1]}</td>
                                                <td style={{fontSize: 13}}>{row[2]}</td>
                                           </tr>
                                   )}
                        </tbody>
                    </table>
                </div>;
    }

    _dateBoxChecked = (field, event) => {
        this.state[field + "Checked"] = event.target.checked;
        this.forceUpdate();
    }
    

    _dayHoursOfOperation = (day) => {

        const sp = this.state.selectedService.serviceParams;
        const isUs = DateConversions.isUSDateFormat();
        const initialOpenTimeString = isUs ? DateUtils.toFriendlyTimeString(sp.businessHours[day].open) : sp.businessHours[day].open;
        const initialCloseTimeString = isUs ? DateUtils.toFriendlyTimeString(sp.businessHours[day].close) : sp.businessHours[day].close;

        const onFieldChange = (json, timeStr) => {
            if (json === "open")
                sp.businessHours[day].open = DateUtils.parseTimeString(timeStr);  // convert to 24 hour time, if needed
            else
                sp.businessHours[day].close = DateUtils.parseTimeString(timeStr);  // convert to 24 hour time, if needed
        };

        return (
            <div key={day} style={{display: 'flex', alignItems: 'center', justifyContent: 'left', marginTop: 5, marginBottom: 5, height: 50}}>
                <Checkbox checked={sp.businessHours[day].enabled} color='primary'
                        onChange={(event) => {sp.businessHours[day].enabled = event.target.checked; this.forceUpdate();}}/>  


                <Typography variant='body1' style={{marginRight: 30, width: 90}} align='left'>{days[day]}</Typography>
                {sp.businessHours[day].enabled ? 
                    <Fragment>
                        <Checkbox checked={sp.businessHours[day].allDay} color='primary'
                                  onChange={(event) => {sp.businessHours[day].allDay = event.target.checked; this.forceUpdate();}}/>
                        <Typography variant='body1' style={{marginRight: 30, width: 90}} align='left'>All Day</Typography>

                        {!sp.businessHours[day].allDay ?
                            <Fragment>
                                <ManageTimeField label="Opens" 
                                                style={{maxWidth: 200, marginRight: 20}}
                                                json="open"
                                                autoAccept={true}
                                                clockColor={ThemeColors.clockColor} 
                                                twelveHour={isUs}
                                                initialValue={initialOpenTimeString} 
                                                onFieldChange={onFieldChange} 
                                                onParseError={this._onDateParseError}/> 
                                <ManageTimeField label="Closes" 
                                                style={{maxWidth: 200}}
                                                json="close"
                                                autoAccept={true}
                                                clockColor={ThemeColors.clockColor} 
                                                twelveHour={isUs}
                                                initialValue={initialCloseTimeString} 
                                                onFieldChange={onFieldChange} 
                                                onParseError={this._onDateParseError}/> 
                            </Fragment> 
                            : null
                        }
                    </Fragment>
                    : null
                }
            </div>
        );
    }


    _addAdditionalCostItem = () => {
        this.state.selectedService.serviceParams.additionalCosts.push({name: "", cost: 0});
        this.forceUpdate();
    }

    _additionalCostItem = (item, index) => {

        const onFieldChange = (json, val) => {
            if (json === "name")
                item.name = val;
            else
                item.cost = parseFloat(val);
        };

        const removeItem = () => {
            this.state.selectedService.serviceParams.additionalCosts.splice(index, 1);
            this._additionalCostHash =  window.crypto.randomUUID();  // force a new hash
            this.forceUpdate();
        }

        // Hash is needed so React will re-render the list when an item is removed, since these are ManagedFields we want to destroy them and recreate them

        return (
            <div key={index +  this._additionalCostHash} style={{display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 20, marginTop: 10, marginBottom: 5}}>
                
                <ManageTextField label="Optional Cost Name"
                                initialValue={item.name}
                                json="name"  
                                tooltip="Name for the additional cost the Patron may optionally purchase"
                                autoAccept={true}
                                onFieldChange={onFieldChange}/>

                <div style={{minWidth: 300}}>
                    <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                    tooltip={"Optional Cost"}
                                    label={"Optional Cost (" + this.state.selectedService.isoCurrency + ")"} minValue={0}
                                    decimalPlaces={2}
                                    json="cost"
                                    initialValue={item.cost}
                                    onFieldChange={onFieldChange}
                                    changedBackgroundColor='white'/> 
                </div>
                
                <Tooltip title="Remove optional cost item">
                    <IconButton onClick={removeItem} >
                        <ClearOutlinedIcon fontSize="large" style={{color: ThemeColors.darkRed}}/>
                    </IconButton>
                 </Tooltip>
            </div>
        );
    }


    _rekey = () => {
        this.showConfirmAlert("Regenerate Subscription Link", "Are you sure you want to regenerate the Subscription Link? Any subscribers will need to re-subscribe with the new link.",
                                'black',
                                "Cancel", 
                                this._confirmRekey,
                                "Re-key",
                                'red');
    }

    _confirmRekey = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/services/" + this.state.selectedService.id + "/rekey", {method: 'POST'},
                                this._rekeyCallback, this._fetchErrorCallback);
    }

    _rekeyCallback = (response) => {
        this.decrementBusy();
        this.showConfirmAlert("Success", "Service \"" + this.state.selectedService.name + "\" subscription link re-keyed", 'green'); 
        this._fetchAll();
    }



    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        const buttonStyle = (activeColor, disabled) => {     
            let color = disabled ? 'lightGray' : activeColor;
            return {borderColor: color, color: color, textAlign: 'center'};
        };
       
        const showService = this.state.selectedService !== null;
        const canSave = showService && this.state.selectedService.canManage();
            
        const sp = this.state.selectedService && this.state.selectedService.serviceParams ? this.state.selectedService.serviceParams : null;

        const currency = this.state.selectedService ? this.state.selectedService.isoCurrency : "???";

        const calendarTypeText = this.state.usesSlotCalendar ? "Patron's time selection view will be a calendar grid, with unavailable times greyed out. The patron can click and drag slots to book." : 
                                                               "Patron's time selection view will be a calendar of buttons showing available time slots; pressing the button selects the time.";

        const bookingDuration = sp ? sp.incrementsMinutes - sp.postBookingBufferMinutes : 0;
        const bookingDurationText = bookingDuration < 90 ? bookingDuration + " min" : (Math.floor(bookingDuration / 60) + " hr, " + (bookingDuration % 60) + " min");

        let resourcesString = "";
        let resourcesCount = 0;
        if (this.state.selectedService) {
            resourcesCount = this.state.selectedService.assignableResourceIds.length;
            if (resourcesCount === 0)
                resourcesString = "No Resources Assigned";
            else
                resourcesString = resourcesCount + " Resource" + (resourcesCount === 1 ? " Available" : "s Available");
        }

        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                
                <Grid container direction="row" spacing={2} style={{padding: 10, marginBottom: 20}}>
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Accepting" 
                                value={this.state.aggregate ? this.state.aggregate.active.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.active.names : [], "Services that are currently accepting Bookings")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.ACCEPTING.color}/>
                    </Grid>                  
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Waiting" 
                                value={this.state.aggregate ? this.state.aggregate.waiting.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.waiting.names : [], "Services that have not started accepting Bookings")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.WAITING.color}/>
                    </Grid>         
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Finished" 
                                value={this.state.aggregate ? this.state.aggregate.finished.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.finished.names : [], "Services no longer accepting Bookings")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.FINISHED.color}/>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                       <SummaryWidget label="Disabled" 
                                value={this.state.aggregate ? this.state.aggregate.disabled.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.disabled.names : [], "Services manually disabled")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.DISABLED.color}/>
                    </Grid>
                </Grid>
                
                
  
                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'left', alignItems: 'center'}}>
                    <Autocomplete
                        size='small'
                        disabled={showService}
                        style={{width: '35%'}}
                        value={this.state.selectedService}
                        onChange={this._selectService}
                        options={this.state.services}
                        blurOnSelect
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Service" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />            
                
                    <Tooltip title="Save Changes and Refresh">
                        <Button disabled={!canSave}  size='small' style={buttonStyle('green', !canSave)} 
                                onClick={this._save} variant="outlined" component="label">
                            Save
                        </Button>
                    </Tooltip>                    
                    <Tooltip title="Discard Changes and Clear">
                        <Button disabled={!showService}  size='small' style={buttonStyle('black', !showService)} 
                                onClick={this._discard} variant="outlined" component="label">
                            Discard
                        </Button>
                    </Tooltip>


                    <Tooltip title="Go to the Page the Patron will visit to book the Service (opens in new window)">
                        <Button disabled={!showService} size='small' style={{...buttonStyle('black', !showService), marginLeft: 'auto'}} 
                                onClick={this._gotoPatronBooking} variant="outlined" component="label"
                                startIcon={<ReplyIcon style={{transform: 'scaleX(-1)'}}/>}>
                            Go to Patron Booking Page
                        </Button>
                    </Tooltip>
                     
                    {this.state.isBusy ? this.getBusyComponent('right', {marginLeft: 20}, 30) : null}

                </div>
                
                <div style={{marginTop: 15}}/>
                
                {sp ?

                    <Fragment>
        
                        <Paper style={this.styles.paper}>   

                            <Tooltip title={this.state.status.tooltip}>
                                <div style={{...this.styles.status, backgroundColor: this.state.status.color}}>
                                        {this.state.status.label}
                                </div>
                            </Tooltip>
                            
                            <Typography style={this.styles.paperLabel} variant="h6">{"Editing \"" + this.state.selectedService.name + "\""}</Typography> 
                            
                            <Grid container spacing={2}>

                                <Grid item xs={12} lg={6}>
                                    
                                    <Typography variant="body2" style={this.styles.sectionLabel}>Patron Availability</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        <Typography variant='caption' align='right' style={{display: 'flex', justifyContent: 'right', color: 'gray', fontStyle: 'italic', marginLeft: 'auto'}}>{"Timezone: " + Global.getTimezone()}</Typography>                        
                                        
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 10}}>
                                            <Tooltip title="When unchecked, Patrons cannot book the Service (but you can manually create Bookings)">
                                                <Checkbox defaultChecked={sp.enabled} color='primary'
                                                        onChange={(event) => { sp.enabled = event.target.checked; this.setState({bookingEnabled: event.target.checked}); }}/>       
                                            </Tooltip>                           
                                            <Typography variant='body1' align='left' style={{color: this.state.bookingEnabled ? 'green' : 'red', width: 200}}>Enable Patron Bookings</Typography>                        
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, the Service is closed to Patrons before this date. Otherwise, Patrons can access the Booking Portal anytime">
                                                <Checkbox checked={this.state.seasonBookingAutoEnableChecked} color='primary'
                                                          onChange={(event) => this._dateBoxChecked("seasonBookingAutoEnable", event)}/>                                  
                                            </Tooltip>
                                            <Typography variant='body1' style={{width: 200}} align='left'>Service Opens After</Typography>
                                            <div style={{maxWidth: 400, display: this.state.seasonBookingAutoEnableChecked ? 'block' : 'none'}}>
                                                <LuxonDatePicker label='Open' timezone={Global.getTimezone()} 
                                                                initialDate={sp.seasonBookingAutoEnable}
                                                                onDateChange={(date) => sp.seasonBookingAutoEnable = date} onParseError={this._onDateParseError} /> 
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, this is the earliest time that can be booked.">
                                                <Checkbox checked={this.state.seasonBookingStartChecked} color='primary'
                                                          onChange={(event) => this._dateBoxChecked("seasonBookingStart", event)}/>                                  
                                            </Tooltip>  
                                            <Typography variant='body1' align='left' style={{width: 200}}>First available Booking</Typography>
                                            <div style={{maxWidth: 400, display: this.state.seasonBookingStartChecked ? 'block' : 'none'}}>
                                                <LuxonDatePicker label='Start' timezone={Global.getTimezone()} 
                                                                initialDate={sp.seasonBookingStart}
                                                                onDateChange={(date) => sp.seasonBookingStart = date} onParseError={this._onDateParseError} /> 
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, this is the latest time bookings can be scheduled for (they may not start after this time)">
                                                <Checkbox checked={this.state.seasonBookingEndChecked} color='primary'
                                                          onChange={(event) => this._dateBoxChecked("seasonBookingEnd", event)}/> 
                                            </Tooltip>                                 
                                            <Typography variant='body1' align='left' style={{width: 200}}>Last Booking Before</Typography>
                                            <div style={{maxWidth: 400, display: this.state.seasonBookingEndChecked ? 'block' : 'none'}}>
                                                <LuxonDatePicker label='End' timezone={Global.getTimezone()} 
                                                                initialDate={sp.seasonBookingEnd}
                                                                onDateChange={(date) => sp.seasonBookingEnd = date} onParseError={this._onDateParseError} /> 
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, only Members can book the Service (Members must supply a barcode or Membership ID when booking and Membership must not be suspended or expired)">
                                                <Checkbox defaultChecked={sp.requiresMembership} color='primary'
                                                          onChange={(event) => sp.requiresMembership = event.target.checked}/> 
                                            </Tooltip>                                 
                                            <Typography variant='body1' align='left' style={{width: 200}}>Requires Membership</Typography>
                                        </div>

                                    </div>
                                </Grid>


                                <Grid item xs={12} lg={6}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Service Hours of Operation</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        {this._dayHoursOfOperation(0)}
                                        {this._dayHoursOfOperation(1)}
                                        {this._dayHoursOfOperation(2)}
                                        {this._dayHoursOfOperation(3)}
                                        {this._dayHoursOfOperation(4)}
                                        {this._dayHoursOfOperation(5)}
                                        {this._dayHoursOfOperation(6)}

                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={7}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Booking Slot Times and Scheduling</Typography>
                                        <div style={this.styles.roundedContainer}>
                                            <Typography variant='h6' align='center' style={{marginTop: 2, color: ThemeColors.appBarBackground}}>{"Booking Duration: " + bookingDurationText}</Typography>                        

                                            <div style={{display: 'flex', flexDirection: 'row', gap: 40, marginTop: 10}}>
                                                <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 340}}>

                                                    <div>
                                                        <Autocomplete size='small'
                                                                        style={{width: 300, marginTop: 5, marginLeft: 15, marginBottom: 5}}
                                                                        value={sp.incrementsMinutes}
                                                                        onChange={(event, newValue) => { this._serviceParamsFieldChanged("incrementsMinutes", newValue) }}
                                                                        options={ServiceParams.IncrementsMinutes}
                                                                        getOptionLabel={(option) => (option < 120 ? (option + " minutes") : (option / 60) + " hours")}
                                                                        blurOnSelect
                                                                        renderInput={(params) => <TextField {...params} label="Slot Interval" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                                                    /> 
                                                        <Typography variant='body2' align='left' style={{fontStyle: 'italic', color: 'gray', marginLeft: 15, marginBottom: 20}}>{calendarTypeText}</Typography>
                                                    </div>
 

                                                    <ManageNumericField fullWidth={false} hasInfinity={false} justify='left' 
                                                                        tooltip="The maximum number of intervals that can be requested for a single Booking"
                                                                        label="Max Intervals" minValue={1}
                                                                        json="maxIncrements"
                                                                        initialValue={sp.maxIncrements} 
                                                                        onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>     


                                                    <ManageNumericField hasInfinity={false} justify='left' 
                                                                        tooltip="Amount of time to leave free after a Booking before the next Booking can be scheduled. The Booking duration is the Slot interval, less this time."
                                                                        label="Post Booking Buffer (minutes)" minValue={0} increment={5}
                                                                        json="postBookingBufferMinutes"
                                                                        initialValue={sp.postBookingBufferMinutes} 
                                                                        onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  
                                                    
                                                    <Autocomplete size='small'
                                                                        tooltip="The minimum time required between the current time and the start of the Booking"
                                                                        style={{width: 300, marginTop: 10, marginLeft: 15, marginBottom: 5}}
                                                                        value={sp.bookingLeadTimeMinutes}
                                                                        onChange={(event, newValue) => { this._serviceParamsFieldChanged("bookingLeadTimeMinutes", newValue) }}
                                                                        options={ServiceParams.LeadTimeMinutes}
                                                                        getOptionLabel={ (option) => ServiceParams.getLeadTimeLabel(option)}
                                                                        blurOnSelect
                                                                        renderInput={(params) => <TextField {...params} label="Lead Time" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                                                    /> 

                                                </div>

                                                <div>

                                                    {!this.state.usesSlotCalendar ?
                                                        <Autocomplete size='small'
                                                                        tooltip="Align the start of the Booking to the nearest time interval"
                                                                        style={{width: 300, marginTop: 5, marginBottom: 20}}
                                                                        value={sp.schedulingInterval}
                                                                        onChange={(event, newValue) => { sp.schedulingInterval = newValue; }}
                                                                        options={ServiceParams.SchedulingIntervals}
                                                                        getOptionLabel={(option) => {
                                                                            switch (option) {
                                                                                case 15: return "On the quarter hour";
                                                                                case 30: return "On the half hour";
                                                                                case 60: return "Top of the hour";
                                                                                default: return "Unknown";
                                                                            }
                                                                        }}
                                                                        blurOnSelect
                                                                        renderInput={(params) => <TextField {...params} label="Align Slots" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                                                    />  
                                                    : null}

                                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                                        <Tooltip title="If the service allows multiple bookings at the same time, check this box (typically used when there are several Resources available).">
                                                            <Checkbox defaultChecked={sp.allowOverlap} color='primary'
                                                                    onChange={(event) => sp.allowOverlap = event.target.checked}/> 
                                                        </Tooltip> 
                                                        <Typography variant='body1' align='left'>Allow Overlapping Bookings</Typography>                        
                                                    </div>    

                                                </div>
                                            </div>
                                        </div>


                                </Grid> 

                                <Grid item xs={12} lg={5}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Resources</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, the Service must have at least one Resource that is available during the booking time (ignored if the Service has are no assigned Resources)">
                                                <Checkbox defaultChecked={sp.requireAvailableResource} color='primary'
                                                        onChange={(event) => sp.requireAvailableResource = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Require Available Resource</Typography>                        
                                        </div>   

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, the Patron can select among available Resources. Otherwise, one will be randomly assigned.">
                                                <Checkbox defaultChecked={sp.allowPatronToSelectResource} color='primary'
                                                        onChange={(event) => sp.allowPatronToSelectResource = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Allow Patron to Select Resource</Typography>                        
                                        </div>  

                                        <Typography variant='body2' align='center' style={{color: resourcesCount === 0 ? 'red' : "blue", fontStyle: 'italic', marginTop: 10}}>{resourcesString}</Typography>

                                    </div> 
                                </Grid>
                                
                                <Grid item xs={12} lg={7}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Fees</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        <Typography variant='caption' align='right' style={{display: 'flex', justifyContent: 'right', color: 'gray', fontStyle: 'italic', marginLeft: 'auto'}}>All costs are calculated and added to the total in the order below.</Typography>                        
    
                                        <div style={{width: 340, marginBottom: 10}}>

                                            <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Cost per each increment of time booked."
                                                                label={"Cost per Increment (" + currency + ")"} minValue={0}
                                                                decimalPlaces={2}
                                                                json="costPerIncrement"
                                                                initialValue={sp.costPerIncrement} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', width: 720}}>

                                            <div style={{width: 340}}>
                                                <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                    tooltip={"Cost per patron for the entire Booking"}
                                                                    label={"Cost per patron (" + currency + ")"} minValue={0}
                                                                    decimalPlaces={2}
                                                                    json="costPerPatron"
                                                                    initialValue={sp.costPerPatron} 
                                                                    onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/> 
                                            </div>

                                            <ManageNumericField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Maximum number of Patrons who can attend the Booking (ignored when Cost per Patron is zero)"
                                                                label="Max Patrons per Booking" minValue={1}
                                                                json="maxPatronCount"
                                                                initialValue={sp.maxPatronCount} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  
                                        </div>       

                                        {sp.additionalCosts.map((item, index) => this._additionalCostItem(item, index))}

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                                            <Button size='small' variant='outlined' style={{color: ThemeColors.addColor}} onClick={this._addAdditionalCostItem} color='primary'>Add Optional Cost Item</Button>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, gap: 10, maxWidth: 300}}>

                                            <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Add a Service fee as a percentage to the total cost calculated above"
                                                                label={"Service Fee %"} minValue={0}
                                                                decimalPlaces={1}
                                                                json="serviceFeePercent"
                                                                initialValue={sp.serviceFeePercent} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  

                                            <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Add a fixed Service fee to the total cost calculated above"
                                                                label={"Fixed Service Fee (" + currency + ")"}  minValue={0}
                                                                decimalPlaces={2}
                                                                json="serviceFeeFixed"
                                                                initialValue={sp.serviceFeeFixed} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/> 
                                        </div>   

                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 5, marginTop: 10, justifyContent: 'start'}}>
                                            <Tooltip title="Add an additional Service fee that matches your AGS payment processing fee, effectively passing our fee to you on to your Patrons. Only available if your Marketplace operates in USD.
                                                     This fee is applied all other fees above. The fee is not applied if the order total is zero.">         
                               
                                                <Checkbox defaultChecked={sp.addAGSServiceFee} color='primary'
                                                            onChange={(event) => this.state.selectedService.serviceParams.addAGSServiceFee = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Add Matching AGS Service Fee</Typography>                        
                                        </div> 

                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 5, marginTop: 10, justifyContent: 'start'}}>
                                            <Tooltip title="Allow patron to pay with cash or other method outside of the Booking portal. The Booking will indicate the amount due from the Patron.">
                               
                                                <Checkbox defaultChecked={sp.allowCashPayment} color='primary'
                                                            onChange={(event) => this.state.selectedService.serviceParams.allowCashPayment = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Allow Other Payment</Typography>                        
                                            <Typography variant='caption' style={{marginLeft: 15, fontStyle: 'italic', color: 'gray'}} align='left'>You must process this payment outside the portal. Indicate payment methods in your terms and conditions.</Typography>                        
                                        </div>  

                                       

                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={5}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Changes by Patron</Typography>
                                    <div style={this.styles.roundedContainer}>
                                       
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, the Patron can cancel the Booking themselves">
                                                <Checkbox defaultChecked={sp.allowCancellation} color='primary'
                                                        onChange={(event) => sp.allowCancellation = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Allow Cancellation</Typography>                        
                                        </div>  
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, the Patron can reschedule the Booking themselves">
                                                <Checkbox defaultChecked={sp.allowRescheduling} color='primary'
                                                        onChange={(event) => sp.allowRescheduling = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Allow Rescheduling</Typography>                        
                                        </div>  
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, if the Booking is cancelled, a refund is automatically issued. Otherwise, it must be manually issued.">
                                                <Checkbox defaultChecked={sp.autoRefund} color='primary'
                                                        onChange={(event) => sp.autoRefund = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Auto Refund Fee on Cancellation</Typography>                        
                                        </div>  
                                        <div style={{maxWidth: 380, marginTop: 10}}>
                                            <ManageNumericField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="The minimum number of hours before the Booking when the Patron can reschedule or cancel, if allowed"
                                                                label="Minimum Time to Modifiy (hours)" minValue={0}
                                                                json="minHoursBeforeChange"
                                                                initialValue={sp.minHoursBeforeChange} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, the Service owner is emailed when a Patron initially confirms, changes, or cancels a Booking">
                                                <Checkbox defaultChecked={sp.emailOnPatronBooking} color='primary'
                                                        onChange={(event) => sp.emailOnPatronBooking = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Email Service Owner on Booking Activity</Typography>                        
                                        </div>  

                                    </div>
                                </Grid>

                                <Grid item xs={12}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Patron Instructions and Terms & Conditions</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        <RichTextEditor draftContent={sp.termsAndConditions}     
                                                editing={true}  
                                                onChange={ (draftJson) => {
                                                    this._serviceParamsFieldChanged("termsAndConditions", draftJson);
                                                }}/>
                                    </div>
                                </Grid>     

                            </Grid>

 
                        </Paper>

                        {showService ?
                            <Paper style={{...this.styles.paper, marginTop: 30}}>   
                                    
                                <Typography style={this.styles.paperLabel} variant="h6">iCal Subscription</Typography> 
                                
                                <Typography variant='body2' style={{marginTop: 10, color: 'gray', fontStyle: 'italic'}}>Use this URL to subscribe to the Service's Booking Calendar in your own calendar application. Only share this link with people authorized to view all patron Bookings on this Service.</Typography>
                                <Typography variant='body2' style={{marginTop: 10, color: 'blue'}}>{"https://booking.accessgrantedsystems.net/ical/service/" + this.state.selectedService.subscriptionFeedKey}</Typography>

                                <Button size='small' style={{marginTop: 20}} onClick={this._rekey} variant="outlined" component="label">
                                    Re-key Subscription URL
                                </Button>
                            </Paper>
                            : null
                        }

                    </Fragment>                
                    : null
                }
                    
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(ServicesPage));




