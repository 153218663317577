import { Resource } from './Resource'
import { Global } from './Global'

import { DateConversions } from '../utils/DateConversions'
import { ThemeColors } from '../Theme'

export class ServiceParams {
 
    static SchedulingIntervals = [15, 30, 60];
    static IncrementsMinutes = [5, 10, 15, 20, 30, 45, 60, 90, 2 * 60, 2.5 * 60, 3 * 60, 4 * 60, 6 * 60, 8 * 60, 12 * 60, 24 * 60];

    static LeadTimeMinutes = [0, 15, 30, 45, 60, 90, 120, 60 * 24, 60 * 24 * 2, 60 * 24 * 3, 60 * 24 * 7, 60 * 24 * 14, 60 * 24 * 30];

    static getLeadTimeLabel = (minutes) => {
        if (minutes === 0)
            return "None";
        if (minutes < 60)
            return minutes + " minutes";
        if (minutes < 60 * 24) {
            const hours = minutes / 60;
            return hours === 1 ? "1 hour" : hours + " hours";
        }
        const days = minutes / (60 * 24);
        return days === 1 ? "1 day" : days + " days";
    }


    enabled;
    seasonBookingAutoEnable;
    seasonBookingStart;
    seasonBookingEnd;
    requireMembership;

    businessHours;
    bookingLeadTimeMinutes;

    incrementsMinutes;
    maxIncrements;
    postBookingBufferMinutes;
    schedulingInterval;     // For Button Calendar - 15 - minute, 30 - half hour, 60 - top of the hour
    allowOverlap;
    requiresMembership;

    requireAvailableResource;
    allowPatronToSelectResource;

    costPerIncrement;
    costPerPatron;
    maxPatronCount;
    additionalCosts;
    serviceFeePercent;
    serviceFeeFixed;
    addAGSServiceFee;
    allowCashPayment;

    allowCancellation;
    allowRescheduling;
    minHoursBeforeChange;
    autoRefund;

    termsAndConditions;
    emailOnPatronBooking;

    constructor(json, timezone) {
        if (json) {
            this.enabled = json.enabled;
            this.seasonBookingAutoEnable = ServiceParams.toLuxDt(json.seasonBookingAutoEnable, timezone);
            this.seasonBookingStart = ServiceParams.toLuxDt(json.seasonBookingStart, timezone);
            this.seasonBookingEnd = ServiceParams.toLuxDt(json.seasonBookingEnd, timezone);
            this.requireMembership = json.requireMembership;
            this.businessHours = json.businessHours ? json.businessHours : [];
            this.bookingLeadTimeMinutes = json.bookingLeadTimeMinutes;
            this.incrementsMinutes = json.incrementsMinutes;
            this.maxIncrements = json.maxIncrements;
            this.postBookingBufferMinutes = json.postBookingBufferMinutes;
            this.schedulingInterval = json.schedulingInterval;
            this.allowOverlap = json.allowOverlap;
            this.requiresMembership = json.requiresMembership;
            this.requireAvailableResource = json.requireAvailableResource;
            this.allowPatronToSelectResource = json.allowPatronToSelectResource;
            this.costPerIncrement = json.costPerIncrement;
            this.costPerPatron = json.costPerPatron;
            this.maxPatronCount = json.maxPatronCount;
            this.additionalCosts = json.additionalCosts ? json.additionalCosts : [];
            this.serviceFeePercent = json.serviceFeePercent;
            this.serviceFeeFixed = json.serviceFeeFixed;
            this.addAGSServiceFee = json.addAGSServiceFee;
            this.allowCashPayment = json.allowCashPayment;
            this.allowCancellation = json.allowCancellation;
            this.allowRescheduling = json.allowRescheduling;
            this.minHoursBeforeChange = json.minHoursBeforeChange;
            this.autoRefund = json.autoRefund;
            this.termsAndConditions = json.termsAndConditions;
            this.emailOnPatronBooking = json.emailOnPatronBooking;
        }
    }

    static requestNew() {
        const serviceParams = new ServiceParams();
        serviceParams.enabled = false;
        // Create 7 business hours
        serviceParams.businessHours = Array.from({ length: 7 }, (_, day) => ({
            enabled: false,
            allDay: false,
            open: "09:00",
            close: "17:00"
        }));
        serviceParams.bookingLeadTimeMinutes = 0;
        serviceParams.maxPatronCount = 1;
        serviceParams.incrementsMinutes = 60;
        serviceParams.maxIncrements = 1;
        serviceParams.postBookingBufferMinutes = 0;
        serviceParams.schedulingInterval = 15;
        serviceParams.additionalCosts = [];
        serviceParams.allowCancellation = true;
        serviceParams.allowRescheduling = true;
        serviceParams.minHoursBeforeChange = 1;    // 1 hour before
        serviceParams.autoRefund = false;
        serviceParams.emailOnPatronBooking = true;
        return serviceParams;
    }

    static copyForPosting(serviceParams) {
        const copy = new ServiceParams();
        copy.enabled = serviceParams.enabled;
        copy.seasonBookingAutoEnable = ServiceParams.fromLuxDt(serviceParams.seasonBookingAutoEnable);
        copy.seasonBookingStart = ServiceParams.fromLuxDt(serviceParams.seasonBookingStart);
        copy.seasonBookingEnd = ServiceParams.fromLuxDt(serviceParams.seasonBookingEnd);
        copy.requireMembership = serviceParams.requireMembership;
        copy.businessHours = serviceParams.businessHours;
        copy.bookingLeadTimeMinutes = serviceParams.bookingLeadTimeMinutes;
        copy.incrementsMinutes = serviceParams.incrementsMinutes;
        copy.maxIncrements = serviceParams.maxIncrements;
        copy.postBookingBufferMinutes = serviceParams.postBookingBufferMinutes;
        copy.schedulingInterval = serviceParams.schedulingInterval;
        copy.allowOverlap = serviceParams.allowOverlap;
        copy.requiresMembership = serviceParams.requiresMembership;
        copy.requireAvailableResource = serviceParams.requireAvailableResource;
        copy.allowPatronToSelectResource = serviceParams.allowPatronToSelectResource;
        copy.costPerIncrement = serviceParams.costPerIncrement;
        copy.costPerPatron = serviceParams.costPerPatron;
        copy.maxPatronCount = serviceParams.maxPatronCount;
        copy.additionalCosts = serviceParams.additionalCosts;
        copy.serviceFeePercent = serviceParams.serviceFeePercent;
        copy.serviceFeeFixed = serviceParams.serviceFeeFixed;
        copy.addAGSServiceFee = serviceParams.addAGSServiceFee;
        copy.allowCashPayment = serviceParams.allowCashPayment
        copy.allowCancellation = serviceParams.allowCancellation;
        copy.allowRescheduling = serviceParams.allowRescheduling;
        copy.minHoursBeforeChange = serviceParams.minHoursBeforeChange;
        copy.autoRefund = serviceParams.autoRefund;
        copy.termsAndConditions = serviceParams.termsAndConditions;
        copy.emailOnPatronBooking = serviceParams.emailOnPatronBooking;
        return copy;
    }

    static toLuxDt(json, tz) {
        return json ? DateConversions.utcJsonDateStringToLuxonDateTime(json).setZone(tz) : null;
    }
    static fromLuxDt(lux) {
        return lux ? DateConversions.luxonDateTimeToJsonDateString(lux) : null;
    }

}


export class Service extends Resource {

    acceptsBookings;        // transient set by the backend to indicate if the service is accepting bookings by patrons

    owner;
    ownerName;

    assignableResourceIds = [];
    subscriptionFeedKey;

    serviceParams;

    constructor(json, timezone) {
        super(json);
        if (json) {
            this.acceptsBookings = json.acceptsBookings;
            this.owner = json.owner;
            this.ownerName = json.ownerName;
            this.assignableResourceIds = json.assignableResourceIds ? json.assignableResourceIds : [];
            this.subscriptionFeedKey = json.subscriptionFeedKey;
            this.serviceParams = new ServiceParams(json.serviceParams, timezone);
        }
    }


    // Create a new Service object with the user's current group
    static requestNew(name, category, description, color) {
        const service = new Service();
        service.type = "Service"
        service.initNew(name, category, description, color);
        service.assignableResourceIds = [];
        service.serviceParams = ServiceParams.requestNew();
        return service;
    }


    hasAssignableResourceId = (id) => {
        return this.assignableResourceIds.includes(id);
    }

    canManage = () => {
        // Resources managers can manage any Service
        if (Global.isResourceManager())
            return true;

        // Only the owner of the service can select it
        if (Global.user && Global.user.id === this.owner)
            return true;

        return false;
    }



    static Status = {
        DISABLED:     {label: "Disabled", color: 'gray', tooltip: "Service has been manually disabled", patronReason: "We are sorry, this service is not accepting Bookings at this time."},  
        WAITING:      {label: "Waiting", color: 'blue', tooltip: "Not yet accepting Bookings", patronReason: "You are early! This Service is not accepting Bookings yet. Please check back later."},
        FINISHED:     {label: "Finished", color: ThemeColors.darkRed, tooltip: "Bookings no longer accepted", patronReason: "We are sorry, this Service is no longer accepting Bookings. Please check back later."},
        ACCEPTING:    {label: "Accepting", color: 'green', tooltip: "Actively accepting Bookings", patronReason: "Available for Bookings."}     
    };
    
    //Return an Status item 
    status() {
        if (!this.serviceParams.enabled)
            return Service.Status.DISABLED;

        const now = DateConversions.now();
        if (this.serviceParams.seasonBookingAutoEnable && now < this.serviceParams.seasonBookingAutoEnable)     // too early
            return Service.Status.WAITING;

        if (this.serviceParams.seasonBookingEnd && now > this.serviceParams.seasonBookingEnd)     // too late    
            return Service.Status.FINISHED;

        return Service.Status.ACCEPTING;
    }


    // Return the business hours for the given date, as minutes from midnight for open and close. If the service is not open on that day, return null
    businessHoursForDate = (date) => {
        const dayOfWeek = DateConversions.weekdayIndex(date);
        const businessHours = this.serviceParams.businessHours[dayOfWeek - 1];
        if (!businessHours.enabled)
            return null;

        if (businessHours.allDay)
            return {openMin: 0, closeMin: 24 * 60};
    

        const open = DateConversions.timeStringComponents(businessHours.open);
        const close = DateConversions.timeStringComponents(businessHours.close);
        const openMinutes = open.hour * 60 + open.minute;
        const closeMinutes = close.hour * 60 + close.minute;

        return {openMin: openMinutes, closeMin: closeMinutes};
    }

    usesSlotCalendar = () => {
        return this.serviceParams.incrementsMinutes < 30 || (this.serviceParams.incrementsMinutes === 30 && this.serviceParams.maxIncrements > 1)
    }

}