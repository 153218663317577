import { ThemeColors } from '../Theme';
import { Slot } from './Slot';
import { Service } from './Service';

import { Permissions } from 'react-frontend-utils'
import { Global } from './Global'

export class Availability extends Slot {


    static createNew(start, end, resource) {
        
        const availability = new Availability();
        availability.type = "Availability";
        availability.title = "Expanded Availability";
        
        // call superclass method        
        availability.newSlot("av", start, end, [resource.id]);

        availability.color = ThemeColors.availabilityColor;
        return availability;
    }

    copy() {
        const copy = new Availability();
        super.copy(copy);
        return copy;
    }


    canEdit(resources) {

        if (Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES))      // resource managers can always edit Availability
            return true;
        
        const service = this.getService(resources);
        if (!service) {
            console.error("Availability " + this.id + " does not have a Service, which is not allowed");
            return false;
        }

        if (service.owner === Global.user.id) {        // owners of services can always edit their bookings (and there can only be one)
            return true;
        }
        
        return false;
    }

    // Return the Service that this Availability is on, or null if it is not on a service. We assume that a Availability can only be on one service.
    getService = (resourcesList) => {

        // step through all our resourceIDs, and find the resource in the list that matches it. If it is a service, it's ours
        for (let resourceId of this.resourceIds) {
            const resource = resourcesList.find(r => r.id === resourceId)
            if (resource instanceof Service) {
                return resource;
            }
        }
        return null;
    }

}