import React from 'react';

import { AlertingComponent } from 'react-frontend-utils';
import { LuxonDatePicker } from './LuxonDateTimePicker';

import { Button, Tooltip, Typography } from '@material-ui/core';

/**
 * This component allows the user to select a date and time range. The format of the date and time is based on the locale (US or international).
 * 
 * The user can supply the following props:
 * 
 * timezone (String): The timezone to use for Luxon date time objects. Default if not supplied is "America/New_York"
 * startDate (luxon DateTime object): The initial start date to display. Required.
 * endDate (luxon DateTime object): The initial end date to display. Required.
 * onRangeChange (function returning an object with startDate and endDate properties, which are Luxon DateTime objects) : A callback function that is called when the date range is changed.
 * 
 * The user can call the following functions:
 *  set(startDate, endDate): Set the start and end date to the given Luxon DateTime objects
 * 
 */


export class DateTimeRangeSelector extends AlertingComponent {


    _startDate;
    _endDate;

    _startRef = React.createRef();
    _endRef = React.createRef();

    constructor(props) {
        super(props);

        this._startDate = props.startDate;
        this._endDate = props.endDate;
        
        const duration = this._durationString();
        this.state.durationString = duration.durationString;
        this.state.durationColor = duration.durationColor;
    }

    set(startDate, endDate) {
        this._startDate = startDate;
        this._endDate = endDate;
        this._startRef.current.set(startDate);
        this._endRef.current.set(endDate);
        this._rangeChangeCallback();
    }

    _duration = () => {
        if (this._startDate == null || this._endDate == null)
            return null;

        if (this._endDate < this._startDate)
            return null;

        return this._endDate.diff(this._startDate, ["days", "hours", "minutes"]);
    }


    _durationString = () => {
        let durationString;
        let durationColor = 'black';

        const duration = this._duration();
        if (!duration)
            return {durationString: "End is before start", durationColor: 'red'};

        else if (duration.days === 0 && duration.hours === 0 && duration.minutes === 0)
            return {durationString: "End is the same as start", durationColor: 'red'};
        
        else {
            if (duration.days > 0)
                durationString = (duration.days === 1 ? "1 day, " : duration.days + " days, ") +
                                 (duration.hours === 1 ? "1 hr, " : duration.hours + " hrs, ") +
                                 (duration.minutes === 1 ? "1 min" : (duration.minutes > 0 ? duration.minutes + " mins" : ""));
            else if (duration.hours > 0)
                durationString = (duration.hours === 1 ? "1 hr, " : duration.hours + " hrs, ") +
                                 (duration.minutes === 1 ? "1 min" : (duration.minutes > 0 ? duration.minutes + " mins" : ""));
            else
                durationString = (duration.minutes === 1 ? "1 min" : (duration.minutes + " mins"));
        }

        durationString = durationString.replace(/,([^,]*)$/, '$1');  // remove trailing comma

        return {durationString: durationString, durationColor: durationColor};
    }

    _rangeChangeCallback = () => {
        this.setState(this._durationString());
        if (this.props.onRangeChange)
            this.props.onRangeChange({startDate: this._startDate, endDate: this._endDate});
    }

    _onParseError = (title, message) => {
        this.showConfirmAlert(title, message, 'red');
    }

    _onStartDateChange = (date) => {
        this._startDate = date;
        this._rangeChangeCallback();
    }

    _onEndDateChange = (date) => {
        this._endDate = date;
        this._rangeChangeCallback();
    }

    _allDay = () => {
        const start = this._startDate.set({hour: 0, minute: 0, second: 0});
        const end = this._endDate.set({hour: 0, minute: 0, second: 0}).plus({days: 1});
        this.set(start, end);
    }


    render() {

        const duration = this._duration();
        const spansMultipleDays = duration && duration.days > 0;

        return (
            <div>
                    {this.getConfirmAlertComponent()}  

                    <LuxonDatePicker ref={this._startRef} label='Start' timezone={this.props.timezone} 
                                    initialDate={this._startDate} isReadOnly={this.props.isReadOnly}
                                    onDateChange={this._onStartDateChange} onParseError={this._onParseError} />

                    <LuxonDatePicker ref={this._endRef} label='End' timezone={this.props.timezone} 
                                    initialDate={this._endDate} isReadOnly={this.props.isReadOnly}
                                    onDateChange={this._onEndDateChange} onParseError={this._onParseError} />

                    {!this.props.isReadOnly && duration && !spansMultipleDays ?
                        <Tooltip title="Make this span an entire day">
                            <Button size="small" style={{float: 'right'}} onClick={this._allDay} color='primary'>All Day</Button>
                        </Tooltip>
                            : null
                    }
                    <Typography variant='body2' align='center' style={{fontStyle: 'italic', color: this.state.durationColor, marginTop: 10}}>Duration: {this.state.durationString}</Typography>
            </div>
        );
    }

}
