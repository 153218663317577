import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Button, Grid, Box, Popover, Tooltip, Typography, TextField, Checkbox, Radio, RadioGroup, FormControlLabel, Collapse, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReplyIcon from '@material-ui/icons/Reply';

import { Global } from '../models/Global';
import { Service } from '../models/Service';
import { ThemeColors } from '../Theme';
import { CalEvent } from '../models/CalEvent';
import { Booking } from '../models/Booking';
import { Recurrence } from '../models/Recurrence';
import { LuxonDatePicker } from './LuxonDateTimePicker';
import { DateTimeRangeSelector } from './DateTimeRangeSelector';
import { DateConversions } from '../utils/DateConversions';

import { ColorPicker } from 'react-frontend-utils';
import { OpenInNewTab } from '../App'

/**
 * This function allows editing of a Slot.  The slot is modified in place.
 * 
 *  The user must supply the following props:
 *      isOpen (boolean): True if the popover is open, false otherwise
 *      slot (subclass object of Slot): The slot to edit
 *      addingNew (boolean): True if adding a new, false if editing an existing
 *      timezone (String): The timezone to use for the slot
 *      resources (array of Resource): The list of resources that can be assigned to the slot
 *      recurrence (Recurrence): The recurrence object for the slot, fetched from the server, if the slot's recurrenceId is not null
 *      recurrenceExists (boolean): True if the slot is existing and had a recurrence
 * 
 *    onUpdate (function): A callback function that is called when the user clicks "Apply Changes"
 *    onCancel (function): A callback function that is called when the user clicks "Cancel"
 *    onDelete (function): A callback function that is called when the user clicks "Delete"
 *    onOwnerChange (function): A callback function that is called when the user clicks "Change Owner"
 *    onManageSignups (function): A callback function that is called when the user clicks "Manage Signups"
 *    onBookingPatronEdit (function): A callback function that is called when the user clicks "Edit Patrons"
 *    onManualBookingConfirm (function): A callback function that is called when the user clicks "Confirm Booking"
 */

function CommonFields(props) {

    const [name, setName] = useState(props.slot.title);
    const [description, setDescription] = useState(props.slot.description ? props.slot.description : "");   
    const [color, setColor] = useState(props.slot.color);

    const onNameChange = (event) => { 
        props.slot.title = event.target.value;
        setName(event.target.value);
        props.onChange();
    };

    const onDescriptionChange = (event) => {
        props.slot.description = event.target.value;
        setDescription(event.target.value);
        props.onChange();
    };  

    const onColorChange = (color) => {
        props.slot.color = color.hex;
        setColor(color.hex);
        props.onChange();
    }

    const onRangeChange = (range) => {
        props.slot.start = range.startDate;
        props.slot.end = range.endDate;
        props.onChange();
    }

    const colorPickerDisabled = props.slot.type === "Block" || props.slot.type === "Availability" || props.isReadOnly

    return (
        <Fragment>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20, marginBottom: 20}}>
                {props.isReadOnly ? <Typography variant="h6" style={{marginLeft: 10}}>{name}</Typography> :
                                    <TextField label="Name" variant="outlined" fullWidth value={name} onChange={onNameChange} InputLabelProps={{ shrink: true}}/>
                }
                <Tooltip title={"Color for calendar display"}>
                    <div>
                        <ColorPicker initialColor={color} disableAlpha={true} onChange={onColorChange} alignRight={true} disabled={colorPickerDisabled}/>
                    </div>
                </Tooltip>
            </div>
            {props.isReadOnly ? <Typography variant="body1" style={{marginLeft: 10, marginBottom: 20}}>{"Description: " + description}</Typography> :
                                <TextField label="Internal Note" variant="outlined" fullWidth value={description} onChange={onDescriptionChange} InputLabelProps={{ shrink: true}} style={{marginBottom: 20}} />
            }
            <DateTimeRangeSelector startDate={props.slot.start} endDate={props.slot.end} timezone={props.timezone} isReadOnly={props.isReadOnly}
                                   onRangeChange={onRangeChange} />
            {props.slot.endBufferMinutes > 0 ?
                <Typography variant="body2" align='center' style={{color: 'gray', fontStyle: 'italic'}}>{"Ends " + props.slot.endBufferMinutes + " min earlier for patrons"}</Typography>
                : null
            }
        </Fragment>
    );
}

function ResourceSelect(props) {

    const shouldBeChecked = props.slot.resourceIds.includes(props.resource.id);     // checked if our resource ids contain this resource id

    const [updateCount, setUpdateCount] = useState(props.updateCount);
    const [checked, setChecked] = useState(shouldBeChecked);

    // If services update, we need to re-render to update the checked status
    if (props.updateCount !== updateCount) {
        setUpdateCount(props.updateCount);
        setChecked(shouldBeChecked);
    }
    
    // update the slot's resourceIds when the checkbox is clicked
    const onCheck = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        if (isChecked && !props.slot.resourceIds.includes(props.resource.id))
            props.slot.resourceIds.push(props.resource.id);
        else if (!isChecked && props.slot.resourceIds.includes(props.resource.id))
            props.slot.resourceIds = props.slot.resourceIds.filter(id => id !== props.resource.id);
    }        

    let description = props.resource.description; 
    if (props.slot.type === "Block")
        description += " (" + props.resource.type + ")";
    
    return (<div style={{display: 'flex', alignItems: 'center', marginBottom: -5}}>
                <Checkbox color='primary' disabled={props.isReadOnly} checked={checked} onChange={onCheck}/>
                <Typography variant="body2">{props.resource.name}</Typography>
                <Typography variant="caption" style={{marginLeft: 20, color: 'gray', fontStyle: 'italic'}}>{description}</Typography>
            </div>); 
}

function ServiceSelect(props) {

    // For Bookings and Availabilities: the Slot's service is the resourceID that also appears in the services array (there can only be one)
    const service = props.slot.getService(props.services);
    const [selected, setSelected] = useState(service);

    if (!service)
        return null;

    // update the slot's resourceIds when the service is clicked
    const handleChange = (event) => {
        const newServiceId = event.target.value;

        // Find the service object that corresponds to the new selection
        const newSelection = props.services.find(service => service.id === newServiceId);

        // Remove the currently selected one
        props.slot.resourceIds = props.slot.resourceIds.filter(id => id !== selected.id);

        // Add the new one
        setSelected(newSelection);
        props.slot.resourceIds.push(newSelection.id);

        props.onChange();

    } 

    const serviceDisabled = (service) => {
        if (props.isReadOnly)
            return true;

        // if the user can manage the service (is the owner or a resource manager), they can select it
        if (service.canManage())
            return false;

        return true;
    }

    return ( <RadioGroup name="type" value={selected.id} onChange={handleChange}>
                {props.services.map((service, index) => 
                    <div key={index} style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: -5}}>
                        <FormControlLabel disabled={serviceDisabled(service)} value={service.id} control={<Radio color='primary'/>} label={service.name} style={{minWidth: 100, marginLeft: 0}}/>
                        <Typography variant="caption" style={{marginLeft: 20, color: 'gray', fontStyle: 'italic'}}>{service.description}</Typography>
                    </div>
                )}
              </RadioGroup>
            );
}

function VisibilitySelect(props) {

    const [selected, setSelected] = useState(props.slot.visibility);

    const handleChange = (event) => {
        setSelected(event.target.value);
        props.slot.visibility = event.target.value;
    }

    return ( <RadioGroup name="type" value={selected} onChange={handleChange}>
                {Object.values(CalEvent.Visibility).map((v, index) => 
                    <div key={index} style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: -5}}>
                        <FormControlLabel disabled={props.isReadOnly} value={v.enumName} control={<Radio color='primary'/>} label={v.displayName} style={{minWidth: 130, marginLeft: 0}}/>
                        <Typography variant="caption" style={{color: 'gray', fontStyle: 'italic'}}>{v.description}</Typography>
                    </div>
                )}
              </RadioGroup>
            );
}


function EditRecurrence(props) {

    const [mode, setMode] = useState(props.recurrence.mode);
    const handleModeChange = (event) => {   
        setMode(event.target.value);
        props.recurrence.mode = event.target.value;
    }

    const [interval, setInterval] = useState(props.recurrence.interval);
    const handleIntervalChange = (event) => {   
        let value = parseInt(event.target.value);
        if (!value || value < 1)
            value = 1;
        if (value > 365)
            value = 365;
        setInterval(value);
        props.recurrence.interval = value;
    }
    
    let intervalLabel;
    switch (mode) {
        case Recurrence.RecurrenceMode.DAILY.enumName:
            intervalLabel = "day";
            break;
        case Recurrence.RecurrenceMode.WEEKLY.enumName:
            intervalLabel = "week";
            break;
        case Recurrence.RecurrenceMode.MONTHLY_DATE.enumName:
        case Recurrence.RecurrenceMode.MONTHLY_WEEK.enumName:
            intervalLabel = "month";
            break;
        default:
            console.error("Unknown recurrence mode: " + mode);
            break;
    }
    if (interval > 1)
        intervalLabel += "s";

    const weekdays = [{name: "Sun", value: 1}, {name: "Mon", value: 2}, {name: "Tue", value: 4}, {name: "Wed", value: 8}, {name: "Thu", value: 16}, {name: "Fri", value: 32}, {name: "Sat", value: 64}];

    const [daysOfWeek, setDaysOfWeek] = useState(props.recurrence.daysOfWeek);
    const daysOfWeekChanged = (event) => {
        const day = parseInt(event.target.value);
        if (event.target.checked)
            props.recurrence.daysOfWeek |= day;
        else
            props.recurrence.daysOfWeek &= ~day;
        setDaysOfWeek(props.recurrence.daysOfWeek);
        console.log("Day of week changed: " + props.recurrence.daysOfWeek);
    }

    const [monthDate, setMonthDate] = useState(props.recurrence.dateOfMonth);
    const handleMonthDateChange = (value) => {
        if (value === '' || value < 0)
            value = 1;
        if (value > 31)
            value = 31;
        setMonthDate(value);
        props.recurrence.dateOfMonth = value;
    }
    let monthDateLabel = (monthDate === 0) ? "" :
                         (monthDate === 1 || monthDate === 21 || monthDate === 31) ? "st" : 
                         (monthDate === 2 || monthDate === 22) ? "nd" : 
                         (monthDate === 2 || monthDate === 23) ? "rd" : "th";
    monthDateLabel += " day of the month";

    const [weekOfMonth, setWeekOfMonth] = useState(props.recurrence.weekOfMonth);
    const handleWeekOfMonthChange = (value) => {
        if (value === '' || value < 0)
            value = 1;
        if (value > 5)
            value = 5;
        setWeekOfMonth(value);
        props.recurrence.weekOfMonth = value;
    }

    const [weekOfMonthDay, setWeekOfMonthDay] = useState(props.recurrence.weekOfMonthDay);
    const handleWeekOfMonthDayChange = (value) => {
        if (!value || value < 1)
            value = 1;
        if (value > 7)
            value = 7;
        setWeekOfMonthDay(value);
        props.recurrence.weekOfMonthDay = value;
    }

    const [endDate, setEndDate] = useState(props.recurrence.endDate);
    const handleEndDateChange = (date) => {
        props.recurrence.endDate = date;
        setEndDate(date);
    }

    // For display, calculate the recurring slots and turn them into date strings
    const recurringSlots = props.recurrence.generateSlots(props.slot);
    const recurringSlotStrings = recurringSlots.map(slot => DateConversions.toDateStr(slot.start, true));

    // Make sure recurringSlotStrings has a multiple of 5 entries, so we can display them in a grid
    while (recurringSlotStrings.length % 5 !== 0)
        recurringSlotStrings.push("");

   
    const recurringTooltip = <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                {recurringSlotStrings.map((row, index) =>  
                                    <div key={index} style={{flex: '0 0 20%'}}>{row}</div>
                                )}
                            </div>;

    
    const RecurrenceListTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: 'white',
            color: 'black',
            maxWidth: 600,
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid gray',
            padding: 5
        }
    }))(Tooltip);


    // If there are more than 10 slots, just show the first 10 and indicate there are more
    const maxSlotsToDisplay = 10;
    const recurringSlotDisplay = <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', flexWrap: 'wrap'}}>
                                    {recurringSlotStrings.slice(0, maxSlotsToDisplay).map((row, index) =>  
                                        <div key={index} style={{flex: '0 0 20%'}}>{row}</div>
                                    )}
                                    {recurringSlotStrings.length > maxSlotsToDisplay ? 
                                        <RecurrenceListTooltip title={recurringTooltip}>
                                            <div style={{flex: '0 0 100%', fontStyle: 'italic', color: 'blue'}}>Mouse over for more...</div> 
                                        </RecurrenceListTooltip>
                                        : null
                                    }
                                </div>;

 

    const dateArray = Array.from({length: 31}, (v, i) => i + 1); // Array from 1-32
    dateArray.push(0);  // add "Last" as an option

    return ( <div style={{border: '1px solid gray', borderRadius: 5, padding: 5}}>
                {!props.isReadOnly && !props.recurrenceExists ?
                    <Button size='small' onClick={props.onRemoveRecurrance} disabled={props.isReadOnly} style={{color: 'red', float: 'right'}} variant="outlined">Remove Recurrence</Button>
                    : null
                }
                <Typography variant="body2" style={{color: ThemeColors.appBarBackground, fontSize: '10pt', marginLeft: 5}}>Recurrence</Typography>

                <Grid container gap={20}>

                    <Grid item lg={3} sm={4} xs={6}>
                        <RadioGroup name="type" value={mode} onChange={handleModeChange}>
                            {Object.values(Recurrence.RecurrenceMode).map((v, index) => 
                                <FormControlLabel key={index} disabled={props.isReadOnly} value={v.enumName} control={<Radio color='primary'/>} label={v.displayName} style={{minWidth: 100, marginLeft: 0, marginBottom: -5}}/>
                            )}
                        </RadioGroup>
                    </Grid>

                    <Grid item lg={3} sm={4} xs={6}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 10, marginTop: 20}}>
                            <TextField label="Every" type="number" variant="outlined" value={interval} onChange={handleIntervalChange} InputLabelProps={{ shrink: true}} 
                                        disabled={props.isReadOnly}
                                        InputProps={{
                                            inputProps: { style: { textAlign: 'center' } }
                                        }}  
                                        style={{maxWidth: 80}} />
                            <Typography variant="body2">{intervalLabel}</Typography>
                        </div>
                    </Grid>

                    {mode === Recurrence.RecurrenceMode.DAILY.enumName ?
                        <Grid item lg={6} sm={12} xs={12}>
                            <div style={{marginTop: 20, marginBottom: 10, marginLeft: 10}}/>
                        </Grid>
                        : null  
                    }

                    {mode === Recurrence.RecurrenceMode.WEEKLY.enumName ?
                        <Grid item lg={6} sm={12} xs={12}>

                            <div style={{display: 'flex', alignItems: 'center', gap: 5, marginTop: 25}}>     
                                {weekdays.map((day, index) =>
                                    <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                                        <Checkbox key={index} color='primary' disabled={props.isReadOnly} value={day.value} checked={(daysOfWeek & day.value) ? true : false} onChange={daysOfWeekChanged}/>
                                        <Typography variant="body2" style={{marginLeft: -5}} >{day.name}</Typography>
                                    </div>
                                )}
                            </div>

                        </Grid>
                        : null
                    }

                    {mode === Recurrence.RecurrenceMode.MONTHLY_DATE.enumName ?
                        <Grid item lg={6} sm={12} xs={12}>
                            <div style={{display: 'flex', alignItems: 'center', gap: 10, marginTop: 20, marginBottom: 10, marginLeft: 10}}>
                                <Typography variant="body2">on the</Typography>
                                <Autocomplete
                                    blurOnSelect
                                    style={{width: 120}}
                                    size="small"
                                    disabled={props.isReadOnly}
                                    options={dateArray}
                                    getOptionLabel={(option) => {
                                        return option === 0 ? "Last" : option.toString();
                                    }}
                                    value={monthDate}
                                    onChange={(event, value) => handleMonthDateChange(value)}
                                    renderInput={(params) => <TextField {...params} variant='outlined'/>}
                                />
                                <Typography variant="body2">{monthDateLabel}</Typography>
                            </div>
                        </Grid>
                        : null
                    }

                    {mode === Recurrence.RecurrenceMode.MONTHLY_WEEK.enumName ?
                        <Grid item lg={6} sm={12} xs={12}>
                            <div style={{display: 'flex', alignItems: 'center', gap: 10, marginTop: 20, marginBottom: 10, marginLeft: 10}}>
                                <Typography variant="body2">on the</Typography>
                                <Autocomplete
                                    style={{width: 150}}
                                    size="small"
                                    blurOnSelect
                                    disabled={props.isReadOnly}
                                    options={[1, 2, 3, 4, 5, 0]}
                                    getOptionLabel={(option) => {
                                        switch(option) {
                                            case 1: return "First";
                                            case 2: return "Second";
                                            case 3: return "Third";
                                            case 4: return "Fourth";
                                            case 5: return "Fifth";
                                            case 0: return "Last";
                                            default: return "Unknown";
                                        }
                                    }}
                                    value={weekOfMonth}
                                    onChange={(event, value) => handleWeekOfMonthChange(value)}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                <Autocomplete
                                    style={{width: 180}}
                                    size="small"
                                    blurOnSelect
                                    disabled={props.isReadOnly}
                                    options={[1, 2, 3, 4, 5, 6, 7]}
                                    getOptionLabel={(option) => {
                                        switch(option) {
                                            case 1: return "Sunday";
                                            case 2: return "Monday";
                                            case 3: return "Tuesday";
                                            case 4: return "Wednesday";
                                            case 5: return "Thursday";
                                            case 6: return "Friday";
                                            case 7: return "Saturday";
                                            default: return "Unknown";
                                        }
                                    }}
                                    value={weekOfMonthDay}
                                    onChange={(event, value) => handleWeekOfMonthDayChange(value)}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                <Typography variant="body2">of the month</Typography>
                            </div>
                        </Grid> 
                        : null
                    }

                    <Grid item lg={4} xs={12}>
                        <div style={{marginTop: 20, marginBottom: 5, marginLeft: 10, marginRight: 10, maxWidth: 300}}>
                            <LuxonDatePicker label='End Recurrence After' timezone={props.timezone} 
                                              initialDate={endDate} isReadOnly={props.isReadOnly}
                                              onDateChange={handleEndDateChange} hideTime={true}/>
                            {endDate < props.slot.start ?
                                <Typography variant='body2' style={{fontStyle: 'italic', color: 'red', marginLeft: 10}}>Recurrence end earlier than start date</Typography>
                                :
                                <div style={{marginTop: 25}}/>
                            }
                        </div>
                    </Grid>

                    <Grid item lg={8} xs={12}>
                        <Typography variant="body2" style={{fontWeight: 'bold', marginLeft: 10}}>{"Recurring Dates (" + recurringSlots.length + "): "}</Typography>
                        <Typography variant="body2" style={{marginLeft: 10}}>{recurringSlotDisplay}</Typography>
                    </Grid> 

                </Grid>

            </div>
    );
}


function RecurrenceSelect(props) {
    
    const [recurrence, setRecurrence] = useState(props.recurrence);

    // create a new, default recurrence
    const createRecurrence = () => {
        const newRecurrence = Recurrence.newRecurrence(props.slot.start);
        props.slot.recurrenceId = newRecurrence.id;
        props.slot.recurrenceObj = newRecurrence;       // store the new recurrence object in the slot, to return to caller
        setRecurrence(newRecurrence);
        props.onRecurrenceCreateOrRemove();
    }

    // remove the recurrence, only possible if the original slot is not a recurrence
    const removeRecurrence = () => {
        props.slot.recurrenceId = null;
        props.slot.recurrenceObj = null;
        setRecurrence(null);
        props.onRecurrenceCreateOrRemove();
    }

    if (recurrence)
        return <EditRecurrence recurrence={recurrence} timezone={props.timezone} isReadOnly={props.isReadOnly} recurrenceExists={props.recurrenceExists}
                               slot={props.slot} onRemoveRecurrance={removeRecurrence}/>;
    else
        if (!props.recurrenceExists)
            return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                        <Button onClick={createRecurrence} disabled={props.isReadOnly} style={{color: !props.isReadOnly ? 'black' : 'lightgray'}} variant="outlined">Add Recurrence</Button>
                    </div>;
        else
            return null;
}




export function EditSlot(props) {

    const maxWidth = window.innerWidth * 0.8
    const isReadOnly = props.slot && !props.slot.canEdit(props.resources);

    const slotInPast = props.slot && props.slot.start < DateConversions.now(props.timezone);
    let title = props.slot ? props.addingNew ? "New " : "Edit " : "";
    title += slotInPast ? " Past " : "";
    title += props.slot && props.slot.isRecurring() ? " Recurring " : "";
    title += props.slot ? props.slot.type : "";
   
    if (isReadOnly && props.slot)
        title = "Viewing " + (slotInPast ? "Past " : "") + props.slot.type;

    const [showJournal, setShowJournal] = useState(false);

    const [updateCount, setUpdateCount] = useState(0);
    const commonFieldUpdated = () => { setUpdateCount(updateCount + 1); }       // re-render when the range changes
        
    // rerender when the service changes, and remove the Booking's selected assigned resources (keep the Service)
    const serviceUpdated = () => {  if (props.slot.type === "Booking")
                                        props.slot.removeAllAssignedResources(props.resources);
                                    setUpdateCount(updateCount + 1); 
                                 }  
        
    // rerender when the recurrence changes, our window will change size
    const onRecurrenceCreateOrRemove = () => { setUpdateCount(updateCount + 1); }

    let resources = [];
    if (props.slot) {
        switch (props.slot.type) {
            case "Availability":            // Availabilities can only be assigned to Services
                break;
            case "Block":
                resources = props.resources;    // Blocks and Bookings can be assigned to any type of Resource
                break;
            case "Event":
            case "Booking":
                resources = props.resources.filter(r => r.type === "Resource");   // Events and Booking, can only be assigned to Resources
                break;
            default:
                console.error("Unknown slot type: " + props.slot.type);
                break;
        }
    }

    let services = [];
    let canChangeServce = true;
    if (props.slot && (props.slot.type === "Booking" || props.slot.type === "Availability")) {
        services = props.resources.filter(r => r.type === "Service");       // get a list of just services

        // find the Slot's service
        const service = props.slot.getService(services);

        // Remove assignable resources that don't have the service's assignableResourceIds
        if (service && service instanceof Service)
            resources = resources.filter(r => service.hasAssignableResourceId(r.id));
        else {
            console.error("Booking " + props.slot.title + " has no service");
            resources = [];
        }

        if (props.slot.type === "Booking" && props.slot.state !== "CREATED")
            canChangeServce = false;
    }

    let canClickOk = props.slot && !isReadOnly && props.slot.start < props.slot.end && props.slot.title && props.slot.title.trim().length > 0;

    let stateLabel;
    if (props.addingNew)
        stateLabel = {label: "NEW", color: 'green'};
    else
        stateLabel = props.slot ? props.slot.stateLabel() : null;

    const deleteable = props.slot && !isReadOnly && !props.addingNew && (props.slot.state === "CREATED" || props.slot.state === "ACTIVE");


    const gotoPatronSignupPage = () => {
        const anyResource = props.resources[0];
        OpenInNewTab("patron/event?database=" + anyResource.group + "&eventid=" + props.slot.id);
    }

    const newManualPatronBooking = props.slot instanceof Booking && props.slot.patron.isManual() && props.slot.state === "CREATED";
    if (props.slot instanceof Booking && !props.slot.patron.patronEmail)
        canClickOk = false;

    const canEditPatrons = props.slot instanceof Booking && !isReadOnly && (props.slot.state === "CREATED" || props.slot.state === "ACTIVE");


    return(

        <Popover open={props.isOpen}
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}>
    
            {props.slot ?
                <Box style={{margin: 20, width: maxWidth}}>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{title}</Typography>  
                        <Typography variant="subtitle1" style={{fontStyle: 'italic', color: stateLabel.color}}>{stateLabel.label}</Typography>                          
                    </div>

                    <CommonFields slot={props.slot} timezone={props.timezone} onChange={commonFieldUpdated} isReadOnly={isReadOnly}/>

                    {props.slot instanceof Booking ? null :
                         <RecurrenceSelect recurrence={props.slot.recurrenceObj} slot={props.slot} timezone={props.timezone} isReadOnly={isReadOnly}
                                            onRecurrenceCreateOrRemove={onRecurrenceCreateOrRemove} recurrenceExists={props.recurrenceExists}/>
                    }

                    <Grid container spacing={2} style={{marginTop: 20}}>
                        {services.length > 0 ?
                            <Grid item sm={6} xs={12}>
                                <div style={{border: '1px solid gray', borderRadius: 5, padding: 5, marginBottom: 10, sminHeight: 50}}>
                                    <Typography variant="body2" style={{color: ThemeColors.appBarBackground, fontSize: '10pt', marginLeft: 5}}>{props.slot.type === "Availability" ? "Service" : "Booked Service"}</Typography>
                                    <ServiceSelect slot={props.slot} services={services} onChange={serviceUpdated} isReadOnly={isReadOnly || !canChangeServce}/>
                                </div>
                            </Grid>
                            : null
                        }

                        {props.slot.type === "Availability" ? null :
                            <Grid item sm={6} xs={12}>
                                <div style={{border: '1px solid gray', borderRadius: 5, padding: 5, minHeight: 50}}>
                                    <Typography variant="body2" style={{color: ThemeColors.appBarBackground, fontSize: '10pt', marginLeft: 5}}>Assigned Resources</Typography>
                                    {resources.map((resource, index) =>
                                            <ResourceSelect key={index} slot={props.slot} resource={resource} isReadOnly={isReadOnly} updateCount={updateCount}/>
                                        )}
                                </div>
                            </Grid>
                        }

                        {props.slot instanceof CalEvent ?
                            <Grid item sm={6} xs={12}>
                                <div style={{border: '1px solid gray', borderRadius: 5, padding: 5}}>
                                    <Typography variant="body2" style={{color: ThemeColors.appBarBackground, fontSize: '10pt', marginLeft: 5}}>Visibility</Typography>
                                    <VisibilitySelect slot={props.slot} isReadOnly={isReadOnly}/>
                                </div>
                                <Typography variant="body2" style={{marginTop: 10, textAlign: 'center'}}>{"Event Owner: " + props.slot.ownerName}</Typography>  
                            </Grid>
                            : null
                        }
                    </Grid>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton edge="start" onClick={() => setShowJournal(!showJournal)}>
                            {showJournal ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}                          
                        </IconButton>      
                        <Typography style={{marginLeft: -5}} variant="button">Journal</Typography>  
                    </div>
                    <Collapse in={showJournal}>
                        <div style={{marginLeft: 30, marginRight: 30}}>
                            {props.slot.renderJournal()}
                        </div>
                    </Collapse>
                    

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20, marginTop: 20, marginBottom: 20}}>
                        {isReadOnly ? null :
                            <Button onClick={newManualPatronBooking ? props.onManualBookingConfirm : props.onUpdate} disabled={!canClickOk} style={{color: canClickOk ? 'green' : 'lightgray'}} variant="outlined">
                                {props.addingNew ? (newManualPatronBooking ? "Confirm Booking" : "Add") : "Apply Changes"}
                            </Button>
                        }
                        <Button onClick={props.onCancel} style={{color: 'black'}} variant="outlined">{isReadOnly ? "Close" : "Discard Changes"}</Button>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20, marginTop: 20, marginBottom: 20}}>
                        {canEditPatrons ?
                            <Fragment>  
                                <Button onClick={props.onBookingPatronEdit} style={{color: props.slot.patron.patronEmail ? 'black' : 'green'}} variant="outlined">{props.slot.patron.patronEmail ? "Edit Patrons" : "Add Patron"}</Button>
                            </Fragment>
                            : null
                        }
                        {props.slot instanceof CalEvent && !props.addingNew && !isReadOnly && Global.isResourceManager() ?
                            <Button onClick={props.onOwnerChange} style={{color: 'black'}} variant="outlined">Change Owner</Button>
                            : null
                        }
                        {!props.addingNew && props.slot instanceof CalEvent ?
                            <Button onClick={props.onManageSignups} style={{color: 'black'}} variant="outlined"                                             
                                    startIcon={<ReplyIcon style={{transform: 'scaleX(-1)'}}/>}>
                                        {isReadOnly ? "View Signups" : "Manage Signups"}
                            </Button>
                            : null
                        }
                        {props.slot instanceof CalEvent && props.slot.state === "ACTIVE" ? 
                            <Button onClick={gotoPatronSignupPage} variant="outlined" component="label"
                                    startIcon={<ReplyIcon style={{transform: 'scaleX(-1)'}}/>}>
                                Go to Patron Signup Page
                            </Button>
                            : null
                        }
                        <Button onClick={props.onDownloadAsICal} style={{color: 'black'}} variant="outlined">Download iCal File</Button>
                        {deleteable ? 
                            <Button onClick={props.onDelete} style={{color: 'red'}} variant="outlined">{props.slot.state === "ACTIVE" ? ("Cancel " + props.slot.type) : "Delete"}</Button>
                            : null
                        }   
                    </div>


                </Box>
                : null 
            }
        </Popover>
    );
    
}