import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { RestComponent, draftContentHasText, RichTextDisplay } from 'react-frontend-utils' 

import { CommonPatronFunctions } from '../components/CommonPatronFunctions'



/**
 * Page 3 of the Event signup process. Displays the final signup information.
 * 
 * The following props are required:
 * - event: the Event object that the user signed up for
 * - prevPageCallback: a callback that returns to the main page
 *  
 */
export class EventSignupPage3 extends RestComponent {

    
    _prevPageCallback;
    

    constructor(props) {
        super(props);
    
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to EventSignupPage1
    }
    
    
    componentDidMount() {
        super.componentDidMount(); 
        
        // Browser back button 
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }


    render() {

        const event = this.props.event;
        
        const hasTerms = draftContentHasText(event.signup.termsAndConditions);

        return (
            <Fragment>
                <Typography align='center' variant="h4" style={{fontWeight: 'bold', color: 'green', marginTop: 20, marginBottom: 10}}>{event.title + ": Signup Complete"}</Typography>
                {CommonPatronFunctions.finalBookingSignupDateDisplay(event)}
                

                {hasTerms ?
                    <div style={{marginTop: 30}}>
                        <hr/>
                        <div style={{marginLeft: 10}}>
                            <RichTextDisplay draftContent={event.signup.termsAndConditions}/>
                        </div>
                    </div>
                    : null
                }
            </Fragment>
        );
    }   

}


export default withCookies(withRouter(EventSignupPage3));
