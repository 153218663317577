import React, { Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';


import { Global } from '../models/Global'
import { Currency } from 'react-frontend-utils' 
import { Patron } from '../models/Patron'

import { CommonPatronFunctions } from '../components/CommonPatronFunctions'

/**
 *  Let the Patron modify the Booking
 * 
 * The following props are required:
 *  event: the Event object that the user signed up for
 *  database: the database to use for the Event
 *  patron: the Patron object that the user signed up with
 * 
 *  cancelSignupCallback: the callback to call when the user wants to cancel the Signup
 *  
 */
export function ManageSignupPage(props) {

    
    const timezone = Global.getTimezone();
    
    const event = props.event;
    const patron = props.patron;
    
    if (!patron)
        return <Typography align='center' variant="h6">This Signup does not exist.</Typography>


    return (
        <Fragment>
            <Typography align='center' variant="h6" style={{marginBottom: 10}}>{"Welcome Back, " + patron.patronNames[0]}</Typography>
            <Typography align='center' variant="h5" style={{fontWeight: 'bold', marginTop: 20, marginBottom: 10}}>{"You signed up for the Event \"" + event.title + "\""}</Typography>
            {CommonPatronFunctions.finalBookingSignupDateDisplay(event)}
            <Typography align='center' variant="body2" style={{marginBottom: 30}}>{"Timezone: " + timezone}</Typography>

            {patron.status() === Patron.Status.CONFIRMED_PAID ?
                <Typography align='center' variant="body1" style={{color: 'green'}}>{"Paid: " + Currency.round(patron.calculatedCost) + " " + patron.isoCurrency}</Typography>
                : null
            }

            <hr style={{margin: 20}}/>

     
            <div style={{display: 'flex', flexDirection: 'column', gap: 20, justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{minWidth: 400, color: 'red'}} variant="outlined" onClick={props.cancelSignupCallback}>Cancel</Button> 
            </div>
            
        </Fragment>
    );
}   
