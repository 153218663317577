import { ThemeColors } from '../Theme';
import { Slot } from './Slot';
import { Service } from './Service';

import { Permissions } from 'react-frontend-utils'
import { Global } from './Global'

export class Block extends Slot {


    static createNew(start, end, resource) {
        
        const block = new Block();
        block.type = "Block";
        block.title = "Unavailable";
        
        // call superclass method        
        block.newSlot("bl", start, end, [resource.id]);

        block.color = ThemeColors.blockColor;
        return block;
    }


    copy() {
        const copy = new Block();
        super.copy(copy);
        return copy;
    }


    canEdit(resources) {

        if (Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES))      // resource managers can always edit Blocks
            return true;

        // If the Block is on a Service, it can only be edited if all the Services it is on are owned by the same user
        let serviceBlockOwner = null;
        for (let resourceId of this.resourceIds) {
            const resource = resources.find(r => r.id === resourceId)
            if (!resource) {
                console.error("Block " + this.id + " has a resource ID that is not in the resources list: " + resourceId);
                return false;
            }
            if (resource instanceof Service) {
                if (serviceBlockOwner === null)
                    serviceBlockOwner = resource.owner;
                else if (serviceBlockOwner !== resource.owner)
                    return false;
            }
            else
                return false;       // If a block is also on a Resource, it can't edited by a Service owner
        }

        // If there's at least one Service for this Block, it can only be edited if the user owns the Service
        if (serviceBlockOwner && serviceBlockOwner === Global.user.id)
            return true;       


        return false;
    }

}