import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import NotesIcon from '@material-ui/icons/Notes';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReplyIcon from '@material-ui/icons/Reply';

import { Tooltip, Grid, Typography, Checkbox, Paper, Button, IconButton, Tabs, Tab } from '@material-ui/core'
import { ManageTextField, ManageDecimalField, ManageNumericField, RichTextEditor, DateUtils } from 'react-frontend-utils'

import { EditPatronPopover } from '../components/EditPatronPopover'
import { PatronSignupTable } from '../components/PatronSignupTable'
import { RefundPopover } from '../components/RefundPopover'
import { ThemeColors } from '../Theme'
import { OpenInNewTab } from '../App'

import { Patron } from '../models/Patron';
import { CalEvent, Signup, OptionalCost } from '../models/CalEvent';
import { Global } from '../models/Global';
import { Resource } from '../models/Resource';

import { RestComponent } from 'react-frontend-utils'
import { DateConversions } from '../utils/DateConversions';


/**
 * Page that is mounted from the SlotsListPage (for Events) when a user selects to manage signups for an Event. Closing this page will return to the SlotsListPage.
 * The following props are passed to this page:
 * 
 * id: The Event id that we are managing signups for
 * exitCallback: Callback function to call when the page is closed
 * viewSlotInCalendarCallback: Callback function to call when the user wants to view the Event in the Calendar
 * 
 */
class EventSignupPage extends RestComponent {
  
    styles = {
            paperLabel: {
                marginLeft: 5,
                marginBottom: 30,
                color: 'gray',
                fontSize: '14pt',
                flexGrow: 1
            },
            paper: {
                padding: 10,
                marginBottom: 20,
            },
            roundedContainer: {
                border: '1px solid #CCCCCC', 
                borderRadius: '4px', 
                padding: 10,
                marginBottom: 20,
            },
            containerLabel: {
                fontSize: 14, 
                color: ThemeColors.darkGray, 
                marginLeft: 5
            },
            sectionLabel: {
                fontSize: 16, 
                color: 'black', 
                marginLeft: 5
            },
            viewLabel: {
                fontSize: 12, 
                color: 'gray', 
                marginLeft: 5
            },
        };
   
    _id;   //Event id 
    _exitCallback;

    _additionalCostHash =  window.crypto.randomUUID();

    _currentDatabase = Global.getLastDatabase();
    
    constructor(props) {
        super(props);
    
        this._id = props.id; 
        this._exitCallback = props.exitCallback;
        this.state.selectedTab = 0;     // 0 = Event Signup, 1 = Patron List, 2 = Journal
    
        this.state.event = null;  //Event object

        this.state.signupEnabled = false;
        this.state.opensMinutesBeforeEnabled = false;
        this.state.closesMinutesBeforeEnabled = false;
        this.state.opensMinutesBefore = 1;
        this.state.closesMinutesBefore = 1;

        this.state.addingNewPatron = false;
        this.state.patronToEdit = null;
        this.state.editPatronPopoverOpen = false;
        this.state.refundPopupOpen = false;

        this.state.maxRefund = 0;
        this.state.refundPatron = null;
    }


    
    
    
    /**
     * When the page loads, immediately fetch the full application (if we don't have it yet)
     */
    componentDidMount() {
        super.componentDidMount(); 
        
        if (!this.state.event)  //fetch if not set
            this._fetchEvent(); 
        
        // Browser back button - go back to SlotsListPage
        window.addEventListener("popstate", this._popstateCallback);
        
        window.addEventListener("databaseChangeEvent", this._databaseChanged);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._popstateCallback);
        window.removeEventListener("databaseChangeEvent", this._databaseChanged);
    }
    
    _popstateCallback = (e) => {
        this._exitCallback();
    }

    _databaseChanged = () => {
        // Just close if the database changed
        if (Global.getLastDatabase() !== this._currentDatabase)
            this._exitCallback();
    }

    _discard = () => {
        this._exitCallback();
    }

    _viewInCalendar = () => {
        this.props.viewSlotInCalendarCallback();
    }

    _gotoEventSignup = () => {
        OpenInNewTab("patron/event?database=" + this._currentDatabase + "&eventid=" + this.state.event.id);
    }
    
    _fetchEvent = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/" + this._id, {}, this._fetchEventCallback, this._fetchErrorCallback); 
    }
   
    _fetchEventCallback = (response) => {
        this.decrementBusy();
        if (response) {            
            const event = new CalEvent(response, Global.getTimezone());

            if (!event.signup) {
                const resourceId = event.resourceIds[0];        // must have at least one resource
                this.incrementBusy();
                this.secureJSONFetch("/bk/resources/" + resourceId, {}, (response) => this._fetchResourceCallback(response, event), this._fetchErrorCallback); 
            }
            else            
                this.setState({event: event, 
                              signupEnabled: event.signup.patronSignupEnabled,
                              opensMinutesBeforeEnabled: event.signup.opensMinutesBefore ? true : false, 
                              closesMinutesBeforeEnabled: event.signup.closesMinutesBefore ? true : false,
                              opensMinutesBefore: event.signup.opensMinutesBefore ? event.signup.opensMinutesBefore : 1,
                              closesMinutesBefore: event.signup.closesMinutesBefore ? event.signup.closesMinutesBefore : 1});
        }           
    }

    _fetchResourceCallback = (response, event) => { 
        this.decrementBusy();
        if (response) {
            const resource = new Resource(response);
            event.signup = Signup.initNew(resource.isoCurrency);
            this.setState({event: event});      // already have the signup data
            console.log(event)
        }
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    //A field in the Event signup was changed, update the selected Event object with the new data
    _signupFieldChanged = (fieldName, userValue) => {
        this.state.event.signup[fieldName] = userValue;  
    }


    _addOptionalCostItem = () => {
        const oc = OptionalCost.initNew("", 0);  
        this.state.event.signup.optionalCosts.push(oc);
        this.forceUpdate();
    }

    _optionalCostItem = (item, index) => {

        const signup = this.state.event.signup;

        const onFieldChange = (json, val) => {
            if (json === "name")
                item.name = val;
            else
                item.cost = parseFloat(val);
        };

        const removeItem = () => {
            signup.optionalCosts.splice(index, 1);
            this._additionalCostHash =  window.crypto.randomUUID();  // force a new hash
            this.forceUpdate();
        }

        // Hash is needed so React will re-render the list when an item is removed, since these are ManagedFields we want to destroy them and recreate them

        return (
            <div key={index + this._additionalCostHash} style={{display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 20, marginTop: 10, marginBottom: 5}}>
                
                <ManageTextField label="Optional Cost Name"
                                initialValue={item.name}
                                json="name"  
                                tooltip="Name for the additional cost the Patron may optionally purchase"
                                autoAccept={true}
                                onFieldChange={onFieldChange}/>

                <div style={{minWidth: 300}}>
                    <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                    tooltip={"Optional Cost"}
                                    label={"Optional Cost (" + signup.isoCurrency + ")"} minValue={0}
                                    decimalPlaces={2}
                                    json="cost"
                                    initialValue={item.cost}
                                    onFieldChange={onFieldChange}
                                    changedBackgroundColor='white'/> 
                </div>
                
                <Tooltip title="Remove optional cost item">
                    <IconButton onClick={removeItem} >
                        <ClearOutlinedIcon fontSize="large" style={{color: ThemeColors.darkRed}}/>
                    </IconButton>
                 </Tooltip>
            </div>
        );
    }

    _save = () => {

        if (this.state.opensMinutesBeforeEnabled) 
            this.state.event.signup.opensMinutesBefore = this.state.opensMinutesBefore;
        else       
            this.state.event.signup.opensMinutesBefore = null;

        if (this.state.closesMinutesBeforeEnabled)
            this.state.event.signup.closesMinutesBefore = this.state.closesMinutesBefore;
        else
            this.state.event.signup.closesMinutesBefore = null;

        this.secureJSONFetch("/bk/slots/" + this.state.event.id + "/upsertSignup", {method: 'POST', body: this.state.event.signup.toJsonForPost()}, 
                             this._saveCallback, this._fetchErrorCallback);
    }

    _saveCallback = (response) => {

        let internalMsg = "";
        if (this.state.event.visibility === CalEvent.Visibility.INTERNAL.enumName && this.state.event.signup.patronSignupEnabled)
            internalMsg = " Note: This Event is set to Internal visibility. Patrons will not be able to sign up for this Event until the visibility is changed to Public or Private Link.";

        this.showConfirmAlert("Success", "The Event signup has been updated." + internalMsg, 'green', "OK", null, null, null, null, this._fetchEvent);
    }

  
    // Refreshes the journal and signups for the Event, but not the signup settings
    _refresh = () => {   
        this.secureJSONFetch("/bk/slots/" + this.state.event.id, {}, this._refreshCallback, this._fetchErrorCallback); 
    }

    _refreshCallback = (response) => {
        if (response) {
            const refreshedEvent = new CalEvent(response, Global.getTimezone());

            // Were only refreshing the journal and signups here, not the signup settings which may be being edited by the user
            const event = this.state.event.copy();
            event.journal = refreshedEvent.journal;
            event.patronSignups = refreshedEvent.patronSignups;
            event.cancelledSignups = refreshedEvent.cancelledSignups;
            event.manualSignups = refreshedEvent.manualSignups;

            this.setState({event: event});
        }
    }


    _addPatron = () => {
        const patron = new Patron();
        this.setState({editPatronPopoverOpen: true, patronToEdit: patron, addingNewPatron: true});
    }

    _editPatron = (patron) => {
        this.setState({editPatronPopoverOpen: true, patronToEdit: patron, addingNewPatron: false});
    }

    _patronEdited = (email, phone, enableSms, patronNames) => {

        this.setState({editPatronPopoverOpen: false});

        const patron = this.state.patronToEdit;
        patron.patronEmail = email;
        patron.patronPhone = phone;
        patron.enableSms = enableSms;
        patron.patronNames = patronNames;
        patron.patronCount = patronNames.length;

        // Sanity check, we can't add a patron to patronSignups
        if (this.state.addingNewPatron && !patron.isManual()) {
            console.log("Error: Attempt to add a new Patron to patronSignups");
            return;
        }
        const body = JSON.stringify(patron);

        this.secureJSONFetch("/bk/slots/" + this.state.event.id + "/eventPatron?manual=" + patron.isManual(), {method: 'POST', body: body}, 
                             this._patronEditCallback, this._fetchErrorCallback);

    }

    _patronEditCallback = (response) => {
        this.setState({patronToEdit: null, addingNewPatron: false});
        this._refresh();     // will force a re-render
    }

    _refundPatron = (patron) => {
        this.setState({refundPopupOpen: true, maxRefund: patron.calculatedCost - patron.refundedAmount, refundPatron: patron});
    }

    _startRefund = (amount, comment) => {
        this.setState({refundPopupOpen: false});

        const slotId = this.state.event.id;
        const patronId = this.state.refundPatron.id;
        const body = {amount: amount, comment: comment};
        this.secureJSONFetch("/bk/slots/" + slotId + "/eventPatron/" + patronId + "/refund", {method: 'POST', body: JSON.stringify(body)}, 
                              this._refresh, this._fetchErrorCallback);
    }


    _deletePatron = (patron) => {

        if (!patron.isManual()) {
            console.log("Error: Attempt to delete a Patron that is not a manual signup");
            return;
        }

        this.showConfirmAlert("Delete Patron", "Are you sure you want to delete the manually added Patron \"" + patron.patronNames[0] + "\"?", 'red', 
                              "No",  () => this._doDeletePatron(patron), "Yes, Delete", 'red');

    }

    _doDeletePatron = (patron) => {

        this.secureJSONFetch("/bk/slots/" + this.state.event.id + "/eventPatron/" + patron.id, {method: 'DELETE'},
                            (response) => {this._deletePatronCallback(patron)}, this._fetchErrorCallback);
    }

    _deletePatronCallback = (patron) => {
        this._refresh();     // will force a re-render
    }


    _cancelPatronSignup = (patron) => {

        if (patron.isManual() || patron.cancelled)
            console.log("Error: Attempt to cancel a Patron that is already cancelled or not a manual signup");

        let refundMsg = "";
        if (patron.refundable() && this.state.event.signup.autoRefund)
            refundMsg = " You must issue refunds manually for this Patron.";


        this.showConfirmAlert("Cancel Signup", "Are you sure you want to cancel the signup for \"" + patron.patronNames[0] + "\"?" + refundMsg, 'red', 
                             "No",  () => this._doCancelPatronSignup(patron), "Yes, Cancel Signup", 'red');
    }

    _doCancelPatronSignup = (patron) => {

        this.secureJSONFetch("/bk/slots/" + this.state.event.id + "/eventPatron/" + patron.id + "/cancel", {method: 'POST'},
                            (response) => {this._cancelPatronSignupCallback(patron)}, this._fetchErrorCallback);
    }

    _cancelPatronSignupCallback = (patron) => {
        this._refresh();     // will force a re-render
    }



    _changeMinutesPriorValue = (fieldName, increment) => {
        let newValue = this.state[fieldName] = this.state[fieldName] + increment;
        if (newValue < 1)
            newValue = 1;

        this.setState({ [fieldName]: newValue });
    }


    _plusMinusButtons = (fieldName, label, increment) => {


        const buttonStyle = {
            border: '1px solid #ccc',
            borderRadius: '0px',
            padding: '0px'
        };


        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0}}>
                <Typography variant='caption'>{label}</Typography>
                <IconButton size='small' style={buttonStyle} onClick={() => this._changeMinutesPriorValue(fieldName, increment)}>
                    <AddIcon />
                </IconButton>
                <IconButton size='small' style={buttonStyle} onClick={() => this._changeMinutesPriorValue(fieldName, -increment)}>
                    <RemoveIcon />
                </IconButton>
            </div>
        );
    }

    _minutesPriorComponent = (fieldPrefix) => {

        const fieldTitle = String(fieldPrefix).charAt(0).toUpperCase() + String(fieldPrefix).slice(1);
        
        const enabledName = fieldPrefix + "MinutesBeforeEnabled";
        const minutesPriorName = fieldPrefix + "MinutesBefore";

        const enabled = this.state[enabledName];
        const minutesPrior = this.state[minutesPriorName];

        const relativeTime = DateUtils.relativeTimeFormat(minutesPrior);
        const absoluteTime = this.state.event.start.minus({minutes: minutesPrior});

        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 30, marginTop: 40, marginBottom: 50, height: 50}}>
                
                <div style={{display: 'flex', alignItems: 'center', width: 160}}>
                    <Tooltip title={"When checked, the Event " + fieldPrefix + " for signup this amount of time before it starts. Otherwise, Patrons can sign up until the Event starts."}>
                        <Checkbox checked={enabled} color='primary'
                                onChange={(event) => {this.setState({[enabledName]: event.target.checked});}}/>                       
                    </Tooltip>
                    <Typography variant='body1' align='left'>{"Signup " + fieldTitle}</Typography>
                </div>
                <div style={{display: enabled ? 'flex' : 'none', alignItems: 'center', justifyContent: 'start', gap: 5}}>
                    {this._plusMinusButtons(minutesPriorName, "DAY", 60*24)}
                    {this._plusMinusButtons(minutesPriorName, "HR", 60)}
                    {this._plusMinusButtons(minutesPriorName, "MIN", 1)}
                </div>
                <div style={{display: enabled ? 'block' : 'none'}}>
                    <Typography variant='body2'>{relativeTime + " prior to Event start"}</Typography>                        
                    <Typography variant='body2'>{DateConversions.dateTimeStr(absoluteTime)}</Typography>   
                </div>
            </div>);
    }



    render() {

        const buttonStyle = (activeColor, disabled) => {     
            let color = disabled ? 'lightGray' : activeColor;
            return {borderColor: color, color: color, textAlign: 'center'};
        };

        if (!Global.user.isSuperAdmin()) {
            return <Fragment>
                        <Typography variant="h1" style={{color: 'blue', fontStyle: 'italic'}}>Event Signups are Coming Soon!</Typography>
                    </Fragment>
        }

        const signup = this.state.event ? this.state.event.signup : null;

        if (!signup)
            return null;

        const canEdit = this.state.event && this.state.event.canEdit();

        let signupWindow = "";
        let signupColor = 'green';

        if (!this.state.opensMinutesBeforeEnabled && !this.state.closesMinutesBeforeEnabled)
            signupWindow = "Signup Window: Any time before the Event starts";
        else {

            const signupStartDate = this.state.event.start.minus({minutes: this.state.opensMinutesBeforeEnabled ? this.state.opensMinutesBefore : 0});
            const signupEndDate = this.state.event.start.minus({minutes: this.state.closesMinutesBeforeEnabled ? this.state.closesMinutesBefore : 0});

            if (signupStartDate >= signupEndDate) {
                signupWindow = "Signup Window: Open time is on or after the close time!";
                signupColor = 'red';
            }
            else {
                const duration = DateConversions.duration(signupStartDate, signupEndDate);
                signupWindow = "Signup Window: " + DateUtils.relativeTimeFriendlyFormat(duration/60);
            }
        }
        
        const isMobile = window.innerWidth < 890;

        return (
            <Fragment>

                {this.getConfirmAlertComponent()}
                {this.state.isBusy ? this.getBusyComponent('right', {marginLeft: 20}, 30) : null}

                 <RefundPopover isOpen={this.state.refundPopupOpen} maxVal={this.state.maxRefund} currency={this.state.refundPatron ? this.state.refundPatron.isoCurrency : null}
                                okCallback={this._startRefund} cancelCallback={() => this.setState({refundPopupOpen: false})}/>                 
                           

                {this.state.editPatronPopoverOpen ?

                    <EditPatronPopover patron={this.state.patronToEdit}
                                        okCallback={this._patronEdited}
                                        cancelCallback={() => this.setState({editPatronPopoverOpen: false})}/>
                    : null
                }

                <div style={{display: 'flex', justifyContent: 'right', height: 30, gap: 10, float: isMobile ? 'none' : 'right'}}>
                    <div style={{display: this.state.selectedTab === 0 ? 'none' : 'flex'}}>
                        <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                            <RefreshIcon/>
                        </IconButton>
                    </div>
                    <Tooltip title="Go to the Page the Patron will visit to sign up for this Event (opens in new window)">
                        <Button size='small' disabled={!this.state.event}  style={buttonStyle('black', !this.state.event)}  
                                onClick={this._gotoEventSignup} variant="outlined" component="label"
                                startIcon={<ReplyIcon style={{transform: 'scaleX(-1)'}}/>}>
                            Go to Signup Page
                        </Button>
                    </Tooltip>
                    <Button disabled={!this.state.event}  size='small' 
                            style={buttonStyle('black', !this.state.event)} 
                            onClick={this._viewInCalendar} variant="outlined" component="label">
                        View in Calendar
                    </Button>
                    <Tooltip title="Discard unsaved changes and close">
                        <Button disabled={!this.state.event}  size='small' 
                                style={buttonStyle('black', !this.state.event)} 
                                onClick={this._discard} variant="outlined" component="label">
                            Close
                        </Button>
                    </Tooltip>
                </div>
  
                <Tabs value={this.state.selectedTab} onChange={(event, newValue) => this.setState({selectedTab: newValue})} indicatorColor="primary" textColor="primary" centered={true} variant='standard'>
                    <Tab value={0} style={{marginRight: isMobile ? 0 : 20}} label={<div style={{ display: 'flex', alignItems: 'center' }}><AssignmentIcon fontSize="small" /><span style={{ marginLeft: 10 }}>{isMobile ? "Settings" : "Signup Settings"}</span></div>} />
                    <Tab value={1} style={{marginRight: isMobile ? 0 : 20}} label={<div style={{ display: 'flex', alignItems: 'center' }}><PeopleIcon fontSize="small" /><span style={{ marginLeft: 10 }}>Patrons</span></div>} />
                    <Tab value={2} label={<div style={{ display: 'flex', alignItems: 'center' }}><NotesIcon fontSize="small" /><span style={{ marginLeft: 10 }}>{isMobile ? "Journal" : "Event Journal"}</span></div>} />
                </Tabs>

                { /* -------------------------------------- Event Signup Tab -------------------------------------- */ }

                <Paper style={{display: this.state.selectedTab === 0 ? 'block' : 'none', ...this.styles.paper}}>   
    
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginRight: 20, gap: 20}}>
                        <Typography style={this.styles.paperLabel} variant="h6">{"Editing Signup Settings for \"" + this.state.event.title + "\""}</Typography> 
                        <Tooltip title="Save Changes and Refresh">
                            <Button disabled={!canEdit}  size='small' style={buttonStyle('green', !canEdit)} 
                                    onClick={this._save} variant="outlined" component="label">
                                Save Settings
                            </Button>
                        </Tooltip>                    
                    </div>
                    
    
                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={5}>
                            
                            <Typography variant="body2" style={this.styles.sectionLabel}>Signups</Typography>            
                            <div style={this.styles.roundedContainer}>
                                
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 10}}>
                                    <Tooltip title="When unchecked, Patrons cannot sign up for the Event (but you can manually add them)">
                                        <Checkbox defaultChecked={signup.patronSignupEnabled} color='primary'
                                                    onChange={(event) => { signup.patronSignupEnabled = event.target.checked; this.setState({signupEnabled: event.target.checked}); }}/>       
                                    </Tooltip>                           
                                    <Typography variant='body1' align='left' style={{color: this.state.signupEnabled ? 'green' : 'red', width: 200}}>Enable Patron Signups</Typography>                        
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 10, height: 50}}>
                                    <Tooltip title="When checked, only Members can sign up for the Event (Members must supply a barcode or Membership ID when signing up and Membership must not be suspended or expired)">
                                        <Checkbox defaultChecked={signup.requiresMembership} color='primary'
                                                    onChange={(event) => signup.requiresMembership = event.target.checked}/> 
                                    </Tooltip>                                 
                                    <Typography variant='body1' align='left' style={{width: 200}}>Requires Membership</Typography>
                                </div>

                                <div style={{maxWidth: 300}}>
                                    <ManageNumericField fullWidth={false} hasInfinity={false} justify='left' 
                                                        tooltip="The maximum number of patrons that can sign up for this Event (you can manually add as many as you want)"
                                                        label="Max Patron Signups" minValue={1}
                                                        json="maxSignups"
                                                        initialValue={signup.maxSignups} 
                                                        onFieldChange={this._signupFieldChanged} changedBackgroundColor='white'/>     
                                </div>

                                
                                {this._minutesPriorComponent("opens")}
                                {this._minutesPriorComponent("closes")}
                                

                                <Typography variant='body1' style={{display: 'flex', justifyContent: 'center', color: signupColor, marginTop: 10, fontStyle: 'italic'}}>{signupWindow}</Typography>
                                <Typography variant='caption' style={{display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: 'gray'}}>If the Event start time changes, the open and close signup times will shift relative to the new time.</Typography>                                               

                            </div>
                        </Grid>

                        <Grid item xs={12} lg={7}>
                        
                            <Typography variant="body2" style={this.styles.sectionLabel}>Fees</Typography>
                            <div style={this.styles.roundedContainer}>

                                <Typography variant='caption' align='right' style={{display: 'flex', justifyContent: 'right', color: 'gray', fontStyle: 'italic', marginLeft: 'auto'}}>All costs are calculated and added to the total in the order below.</Typography>                        

                                <div style={{width: 340, marginBottom: 10}}>

                                    <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                        label={"Signup Cost (" + signup.isoCurrency + ")"} minValue={0}
                                                        decimalPlaces={2}
                                                        json="cost"
                                                        initialValue={signup.cost} 
                                                        onFieldChange={this._signupFieldChanged} changedBackgroundColor='white'/>  
                                </div>   

                                {signup.optionalCosts.map((item, index) => this._optionalCostItem(item, index))}

                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                                    <Button size='small' variant='outlined' style={{color: ThemeColors.addColor}} onClick={this._addOptionalCostItem} color='primary'>Add Optional Cost Item</Button>
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, gap: 10, maxWidth: 300}}>

                                    <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                        tooltip="Add a Service fee as a percentage to the total cost calculated above"
                                                        label={"Service Fee %"} minValue={0}
                                                        decimalPlaces={1}
                                                        json="serviceFeePercent"
                                                        initialValue={signup.serviceFeePercent} 
                                                        onFieldChange={this._signupFieldChanged} changedBackgroundColor='white'/>  

                                    <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                        tooltip="Add a fixed Service fee to the total cost calculated above"
                                                        label={"Fixed Service Fee (" + signup.isoCurrency + ")"}  minValue={0}
                                                        decimalPlaces={2}
                                                        json="serviceFeeFixed"
                                                        initialValue={signup.serviceFeeFixed} 
                                                        onFieldChange={this._signupFieldChanged} changedBackgroundColor='white'/> 
                                </div>   

                                <div style={{display: 'flex', alignItems: 'center', marginLeft: 5, marginTop: 10, justifyContent: 'start'}}>
                                    <Tooltip title="Add an additional Service fee that matches your AGS payment processing fee, effectively passing our fee to you on to your Patrons. Only available if your Marketplace operates in USD.
                                                This fee is applied all other fees above. The fee is not applied if the order total is zero.">         
                        
                                        <Checkbox defaultChecked={signup.addAGSServiceFee} color='primary'
                                                    onChange={(event) => signup.addAGSServiceFee = event.target.checked}/>  
                                    </Tooltip>
                                    <Typography variant='body1' align='left'>Add Matching AGS Service Fee</Typography>                        
                                </div> 

                                <div style={{display: 'flex', alignItems: 'center', marginLeft: 5, marginTop: 10, justifyContent: 'start'}}>
                                    <Tooltip title="Allow patron to pay with cash or other method outside of the Booking portal. The Event signup will indicate the amount due from the Patron.">
                        
                                        <Checkbox defaultChecked={signup.allowCashPayment} color='primary'
                                                    onChange={(event) => signup.allowCashPayment = event.target.checked}/>  
                                    </Tooltip>
                                    <Typography variant='body1' align='left'>Allow Other Payment</Typography>                        
                                    <Typography variant='caption' style={{marginLeft: 15, fontStyle: 'italic', color: 'gray'}} align='left'>You must process this payment outside the portal. Indicate payment methods in your terms and conditions.</Typography>                                               
                                </div>  

                                

                            </div>
                        </Grid>

                        <Grid item xs={12} lg={5}>
                        
                            <Typography variant="body2" style={this.styles.sectionLabel}>Other Options</Typography>
                            <div style={this.styles.roundedContainer}>
                                
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                    <Tooltip title="When checked, if patron cancels their signup, a refund is automatically issued. Otherwise, it must be manually issued.">
                                        <Checkbox defaultChecked={signup.autoRefund} color='primary'
                                                onChange={(event) => signup.autoRefund = event.target.checked}/>  
                                    </Tooltip>
                                    <Typography variant='body1' align='left'>Auto Refund Fee on Cancellation</Typography>                        
                                </div>  
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                    <Tooltip title="When checked, the Event owner is emailed when a Patron signs up or cancels">
                                        <Checkbox defaultChecked={signup.emailOnPatronSignup} color='primary'
                                                onChange={(event) => signup.emailOnPatronSignup = event.target.checked}/>  
                                    </Tooltip>
                                    <Typography variant='body1' align='left'>Email Event Owner on Signup Activity</Typography>                        
                                </div>  

                            </div>
                        </Grid>

                            <Grid item xs={12}>
                        
                            <Typography variant="body2" style={this.styles.sectionLabel}>Event Details, Terms & Conditions</Typography>
                            <div style={{...this.styles.roundedContainer, paddingBottom: 10}}>

                                <ManageTextField style={{maxWidth: 400, marginTop: 20, marginBottom: 20}}
                                                    tooltip="Events in the same category are grouped together so Patrons can easily find similar Events"
                                                    label="Category"
                                                    json="category"
                                                    initialValue={signup.category} 
                                                    onFieldChange={this._signupFieldChanged} changedBackgroundColor='white'/>  

                                <RichTextEditor draftContent={signup.termsAndConditions}     
                                        editing={true}  
                                        onChange={ (draftJson) => {
                                            this._signupFieldChanged("termsAndConditions", draftJson);
                                        }}/>
                            </div>
                        </Grid>  

                    </Grid>
                </Paper>

                { /* -------------------------------------- Patrons Tab -------------------------------------- */ }

                <div style={{display: this.state.selectedTab === 1 ? 'block' : 'none'}}>
                    <Paper style={this.styles.paper}>   
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginRight: 20, gap: 20}}>
                            <Typography style={this.styles.paperLabel} variant="h6">{"Patron Signups for \"" + this.state.event.title + "\""}</Typography> 
                        </div>
                        <PatronSignupTable patronList={this.state.event.patronSignups} editPatron={this._editPatron} 
                                           refundPatron={this._refundPatron} cancelPatronSignup={this._cancelPatronSignup}/>

                    </Paper>
                    <Paper style={this.styles.paper}>   
        
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginRight: 20, gap: 20, marginBottom: 20}}>
                            <Typography style={this.styles.paperLabel} variant="h6">{"Manual Signup Entries for \"" + this.state.event.title + "\""}</Typography> 
                            <Tooltip title="Add Patron Manually">
                                <Button disabled={!canEdit}  size='small' style={buttonStyle('green', !canEdit)} 
                                        onClick={this._addPatron} variant="outlined" component="label">
                                    Add Patron
                                </Button>
                            </Tooltip>       
                        </div>

                        <PatronSignupTable patronList={this.state.event.manualSignups} editPatron={this._editPatron} deletePatron={this._deletePatron}/>

                    </Paper>
                    <Paper style={this.styles.paper}>   
        
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginRight: 20, gap: 20}}>
                            <Typography style={this.styles.paperLabel} variant="h6">{"Cancelled Signups for \"" + this.state.event.title + "\""}</Typography> 
                        </div>
                        <PatronSignupTable refundPatron={this._refundPatron} patronList={this.state.event.cancelledSignups}/>

                    </Paper>
                </div>


                { /* -------------------------------------- Journal Tab -------------------------------------- */ }

                <Paper style={{display: this.state.selectedTab === 2 ? 'block' : 'none', ...this.styles.paper}}>   

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginRight: 20, gap: 20}}>
                        <Typography style={this.styles.paperLabel} variant="h6">{"Journal for \"" + this.state.event.title + "\""}</Typography> 
                    </div>

                    <div style={{marginLeft: 30, marginRight: 30}}>
                        {this.state.event.renderJournal(true)}
                    </div>

                </Paper>

            </Fragment>
        );
    }

}


export default withCookies(withRouter(EventSignupPage));
