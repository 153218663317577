import React, { useState, Fragment } from 'react';

import { IconButton, Collapse, Typography } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SettingsIcon from '@material-ui/icons/Settings';

import { PopupMenu } from 'react-frontend-utils'

import { ThemeColors } from '../Theme';

/**
 * Shows a list of Patrons for two types of tables: those where the Patrons signed up and those where a user manually added a Patron
 * The following props are expected:
 * 
 * patronList (array): An array of Patrons
 * editPatron (function): A callback function that is called when the user wants to edit a Patron
 * deletePatron (function): A callback function that is called when the user wants to delete a Patron (manual Patrons only)
 * cancelPatronSignup (function): A callback function that is called when the user wants to cancel a Patron signup (event Patrons only)
 * eventCancelled (boolean): A flag that indicates if the event has been cancelled
 */
export function PatronSignupTable(props) {

    const styles = {
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            marginRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 230,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
        details: {
            marginLeft: 30,
            marginBottom: 5,
            padding: 5,
            textAlign: 'left',
            borderRadius: 3,
            backgroundColor: ThemeColors.veryLightGray,
        },
    };

    const isWide = window.innerWidth > 1200;

    const columns = isWide ? 7 : 5;

    const [revealSet, setRevealSet] = useState(new Set());


    //If the Patron is in the reveal list, remove it, if it is not, add it (toggle the view)
    const revealDetail = (patron) => {
        
        if (revealSet.has(patron.id))
            revealSet.delete(patron.id);
        else
            revealSet.add(patron.id);
        
        setRevealSet(new Set(revealSet));
    }

    const getActionItems = (patron) => {
        
        const actionItems = [];

        if (!patron.cancelled)
            actionItems.push({label: "Edit", selectCallback: () => props.editPatron(patron), icon: null});

        if (patron.refundable())
            actionItems.push({label: "Refund", selectCallback: () => props.refundPatron(patron), icon: null});

        if (patron.isManual())
            actionItems.push({label: "Delete", selectCallback: () => props.deletePatron(patron), icon: null});

        else if (!patron.cancelled && !props.eventCancelled)
            actionItems.push({label: "Cancel Signup", selectCallback: () => props.cancelPatronSignup(patron), icon: null});
        

        return actionItems;
    }
    


    const renderRow = (patron, key) => {
            
        const isRevealed = revealSet.has(patron.id);
        const leftIcon = isRevealed ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
        
        const status = patron.status();

        return (
            <Fragment key={key}>
                <tr style={styles.tableStyle}>

                    <td style={styles.tableData}>
                        <IconButton edge="start" onClick={() => revealDetail(patron)}>
                            {leftIcon}
                        </IconButton>
                    </td>

                    <td style={styles.tableData}>{patron.getPrimaryPatron()}</td>
                    {isWide ?
                        <Fragment>
                            <td style={styles.tableData}>{patron.patronEmail}</td>
                            <td style={styles.tableData}>{patron.patronPhone + (patron.enableSms ? " (SMS)" : "")}</td>
                        </Fragment>
                        : null
                    }
                    <td style={styles.tableData}>{patron.paymentBadge()}</td>

                    <td style={styles.tableData}>
                        <div style={{...styles.status, backgroundColor: status.color}}>
                            {status.displayName}
                        </div>
                    </td>
                   
                    <td style={{...styles.tableData, paddingRight: 0}}>
                        <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '20', color: ThemeColors.settingsOlive}}/>)}  
                                    menuItems={getActionItems(patron)} 
                                    menuTooltipText={"Actions"}/>
                    </td>
                </tr>
                <tr style={styles.tableStyle}>
                    <td colSpan={columns} align='center' style={styles.tableDataWithBorder}>
                        <Collapse in={isRevealed}>
                            <Typography variant='body2' style={{marginLeft: 35, fontWeight: 'bold'}}>Details</Typography>
                            <div style={styles.details} dangerouslySetInnerHTML={{ __html: patron.details() }} />
                        </Collapse>
                    </td>
                </tr>
            </Fragment>
        );
    }

    return (
        <table style={styles.table}>
            <thead>
                <tr style={styles.tableStyle}>
                    <th style={{...styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                    <th style={{...styles.tableHeader, width: 180}}>Primary Patron</th>
                    {isWide ?  
                        <Fragment>
                            <th style={{...styles.tableHeader, width: 180}}>Email</th>
                            <th style={{...styles.tableHeader, width: 160}}>Phone</th>
                        </Fragment>
                        : null
                    }
                    <th style={{...styles.tableHeader, textAlign: 'center', width: 160}}>Payments</th>
                    <th style={{...styles.tableHeader, textAlign: 'center'}}>Status</th>
                    <th style={{...styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                </tr>
            </thead>
            <tbody>
                {props.patronList.map((patron, index) => renderRow(patron, index))}
            </tbody>
        </table>
    );


}