import { Slot } from './Slot';
import { ThemeColors } from '../Theme';

import { Service } from './Service';
import { Global } from './Global'
import { Patron } from './Patron'
import { Permissions } from 'react-frontend-utils'

export class Booking extends Slot {

    patron;     // Patron object, will always exist
        
    constructor(json, timezone) {
        super(json, timezone);
        this.patron = new Patron(json ? json.patron : null);
    }

    static createNew(start, end, resource) {
        
        const booking = new Booking();
        booking.type = "Booking";
        booking.title = "Untitled Booking";
        
        // call superclass method        
        booking.newSlot("bk", start, end, [resource.id]);

        booking.color = resource.color ? resource.color : ThemeColors.appBarBackground;
        return booking;
    }

    copy() {
        const copy = new Booking();
        super.copy(copy);
        copy.patron = this.patron.copy();
        return copy;
    }

    removeFieldsForNewSlot() {
        super.removeFieldsForNewSlot();
        this.patron = new Patron();     //empty patron
    }

    canEdit(resources) {
        if (Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES))      // resource managers can always edit Bookings
            return true;
        
        const service = this.getService(resources);
        if (!service) {
            console.error("Booking " + this.id + " does not have a Service, which is not allowed");
            return false;
        }

        if (service.owner === Global.user.id) {        // owners of services can always edit their bookings (and there can only be one)
            return true;
        }
        
        return false;
    }

    // Return the Service that this booking is on, or null if it is not on a service. We assume that a booking can only be on one service.
    getService = (resourcesList) => {

        // step through all our resourceIDs, and find the resource in the list that matches it. If it is a service, it's ours
        for (let resourceId of this.resourceIds) {
            const resource = resourcesList.find(r => r.id === resourceId)
            if (resource instanceof Service)
                return resource;
        }
        return null;
    }

    refundable() {
        if (!this.patron)
            return false;
        return this.patron.refundable();
    }


    // Removes all assigned resources from the booking except the service
    removeAllAssignedResources = (resourcesList) => {
        const service = this.getService(resourcesList);
        if (service)
            this.resourceIds = [service.id];    // just keep the service
        else
            this.resourceIds = [];
    }


    inBookingByPatron() {
        if (this.state === "CREATED")
            return true;
        else if (this.state === "ACTIVE" && this.patron && this.patron.status() === Patron.Status.PAYMENT_PENDING)
            return true;
        return false;
    }


    status() {
        if (this.state === "CREATED" || !this.patron)       // no patron yet
            return "Awaiting Confirmation";

        else if (this.state === "ACTIVE") {                 // active booking, use patron status
            const patronStatus = this.patron.status();
            return patronStatus.displayName;
        }
        else 
            return super.status();      // some cancelled state, use superclass status
    }

    details() {

        if (!this.patron)
            return "<div/>";  

        return this.patron.details();
    }

    symbol() {
        if (this.state === "ACTIVE" && this.patron && this.patron.status() === Patron.Status.PAYMENT_PENDING)
            return "⁉️";        // awaiting payment
        
        return super.symbol();
    }


    colorForStatus() {
        if (this.state === "ACTIVE" && this.patron && this.patron.status() === Patron.Status.PAYMENT_PENDING)
            return 'orange';
        return super.colorForStatus();
    }


    // If service is not provided, use the endBufferMinutes from the booking (slot), otherwise use the service's postBookingBufferMinutes
    patronDurationString(service) {
        const postBookingBuffer = service ? service.serviceParams.postBookingBufferMinutes : this.endBufferMinutes;
        const bookingDuration = this.duration() / 60 - postBookingBuffer;
        let durationString;
        if (bookingDuration <= 90)
            durationString = "Duration: " +  bookingDuration + " mins";
        else {
            const hrs = Math.floor(bookingDuration / 60);
            const mins = bookingDuration % 60;
            durationString = "Duration: " + hrs + " hr" + (hrs > 1 ? "s" : "");
            if (mins > 0)
                durationString += ", " + mins + (mins > 1 ? " mins" : " min");
        }
        return durationString;
    }


}