import { DateConversions } from '../utils/DateConversions';
import { Slot } from './Slot';

export class Recurrence {

    static RecurrenceMode = {
        DAILY:        {enumName: "DAILY", displayName: "Daily", description: "Every day or every n days"},
        WEEKLY:       {enumName: "WEEKLY", displayName: "Weekly", description: "Every week or every n weeks"},
        MONTHLY_DATE: {enumName: "MONTHLY_DATE", displayName: "Monthly by Date", description: "A specific date every month or every n months"},
        MONTHLY_WEEK: {enumName: "MONTHLY_WEEK", displayName: "Monthly by Week", description: "A specific day of the week every month or every n months"}
    };

    id;
    type;                  // Subclass type, for Jackson    
    mode;       
    interval;              // interval between recurrences, every n days, weeks, months
    daysOfWeek;            // valid for mode WEEKLY: bit mask of days of the week, 1=Sunday, 2=Monday, 4=Tuesday, 8=Wednesday, 16=Thursday, 32=Friday, 64=Saturday
    dateOfMonth;           // valid for mode MONTHLY_DATE: date of the month, 1-31, or 0 for last day of the month
    weekOfMonth;           // valid for mode MONTHLY_WEEK: week of the month, 1=first, 2=second, 3=third, 4=fourth, 5=fifth, 0=last
    weekOfMonthDay;        // valid for mode MONTHLY_WEEK: day of the week, 1=Sunday, 2=Monday, 3=Tuesday, 4=Wednesday, 5=Thursday, 6=Friday, 7=Saturday
    endDate;               // end date of the series, inclusive


    constructor(json, timezone) {
        if (json) {
            this.id = json.id;
            this.type = json.type;
            this.mode = json.mode;
            this.interval = json.interval;
            this.daysOfWeek = json.daysOfWeek;
            this.dateOfMonth = json.dateOfMonth;
            this.weekOfMonth = json.weekOfMonth;
            this.weekOfMonthDay = json.weekOfMonthDay;
            this.endDate = json.endDate ? DateConversions.utcJsonDateStringToLuxonDateTime(json.endDate).setZone(timezone) : null;
        }
    }

    static newRecurrence(forDate) {
        const r = new Recurrence();
        r.id = "rc_" + Slot.randomId();
        r.type = "Recurrence";
        r.mode = Recurrence.RecurrenceMode.WEEKLY.enumName;
        r.interval = 1;
        r.daysOfWeek = 1 << (DateConversions.weekdayIndex(forDate) - 1);     // set the mask, shift 1 to the left by the weekday index (0-6)
        r.dateOfMonth = forDate.day;
        r.weekOfMonth = DateConversions.whichWeekdayOfMonth(forDate);
        r.weekOfMonthDay = DateConversions.weekdayIndex(forDate);
        r.endDate = forDate.plus({weeks: 8});      // make it 8 weeks from now
        return r;
    }

    changeId() {
        this.id = "rc_" + Slot.randomId();
    }


    copy() {
        const copy = new Recurrence();
        copy.id = this.id;
        copy.type = this.type;
        copy.mode = this.mode;
        copy.interval = this.interval;
        copy.daysOfWeek = this.daysOfWeek;
        copy.dateOfMonth = this.dateOfMonth;
        copy.weekOfMonth = this.weekOfMonth;
        copy.weekOfMonthDay = this.weekOfMonthDay;
        copy.endDate = this.endDate;
        return copy;
    }

    // Convert this object to a JSON string for posting to the server, with times converted to JSON strings. This object is not modified
    toJsonForPost() {
        const copy = this.copy();
        copy.endDate = copy.endDate ? DateConversions.luxonDateTimeToJsonDateString(copy.endDate) : null;

        return JSON.stringify(copy);
    }

    recurrenceChanged(fromOriginal) {

        if (!fromOriginal)
            return true;

        if (this.mode !== fromOriginal.mode)
            return true;
        if (this.interval !== fromOriginal.interval)
            return true;
        if (this.daysOfWeek !== fromOriginal.daysOfWeek)
            return true;
        if (this.dateOfMonth !== fromOriginal.dateOfMonth)
            return true;
        if (this.weekOfMonth !== fromOriginal.weekOfMonth)
            return true;
        if (this.weekOfMonthDay !== fromOriginal.weekOfMonthDay)
            return true;

        
        // compare the recurrence end dates
        if (!this.endDate && fromOriginal.endDate)
            return true;
        if (this.endDate && !fromOriginal.endDate)
            return true;
        if (this.endDate && fromOriginal.endDate && !this.endDate.equals(fromOriginal.endDate))
            return true;

        return false;
    }

    

    _gotoMonthlyWeek(date) {
        if (this.weekOfMonth === 0)   // last week of the month
            return DateConversions.lastWeekdayOfMonth(date, this.weekOfMonthDay);
        else
            return DateConversions.nthWeekdayOfMonth(date, this.weekOfMonth, this.weekOfMonthDay);
    }


    // Generate array of slots using this recurrence, starting from the given fromDate (luxonDateTime) and based on the fromSlot information
    generateSlots(sourceSlot) {

        let date = sourceSlot.start;
        const duration = sourceSlot.duration();
        const recurringSlots = [];

        // function to add a slot on the given date, same duration as the source slot
        const addSlot = (onDate) => {
            const newSlot = Slot.newFrom(sourceSlot);   // make a copy of the source slot, with a new id, no recurrence set
            newSlot.recurrenceId = this.id;
            newSlot.start = onDate;
            newSlot.end = newSlot.start.plus({seconds: duration});
            recurringSlots.push(newSlot);
        };

        // Initialize for mode
        switch (this.mode) {
            case Recurrence.RecurrenceMode.DAILY.enumName:
                break;

            // For weekly, we want to start on the Sunday of the week of the source slot
            case Recurrence.RecurrenceMode.WEEKLY.enumName:
                addSlot(date);
                while (DateConversions.weekdayIndex(date) !== 1) {
                    date = date.minus({days: 1});
                }
                break;

            // For monthly date, we want to first add the slot for the source date, then move to the beginning of the month
            case Recurrence.RecurrenceMode.MONTHLY_DATE.enumName:
                addSlot(date);
                date = date.set({day: 1});    // goto the beginning of the this month
                break;

            // For monthly week, we want to first add the slot for the source date, then move to the beginning of the month
            case Recurrence.RecurrenceMode.MONTHLY_WEEK.enumName:
                addSlot(date);
                date = date.set({day: 1});      // goto the beginning of the this month
                break;

            default:
                console.error("Invalid recurrence mode: " + this.mode);
                return;
        }

    
        // Generate the slots, skipping the intervals, until the end date is reached
        while (date <= this.endDate) {

            switch (this.mode) {

                // Daily - we just add the slot for this date, and skip to the next day
                case Recurrence.RecurrenceMode.DAILY.enumName:
                    addSlot(date);
                    date = date.plus({days: this.interval});
                    break;

                // Weekly - we are always starting on a Sunday, add the slot for each day of the week in the mask
                case Recurrence.RecurrenceMode.WEEKLY.enumName:
                    for (let i = 0; i < 7; i++) {
                        if (date > sourceSlot.start && date <= this.endDate) {    // if the date after the source slot start and before the end date, ok to add
                            if (this.daysOfWeek & (1 << i))
                                addSlot(date);
                        }
                        date = date.plus({days: 1});
                    }
                    // we should now be on Sunday, of the next week, go to the next interval
                    date = date.plus({weeks: this.interval - 1});
                    break;

                // Monthly by date, we are starting on the beginning of the current month
                case Recurrence.RecurrenceMode.MONTHLY_DATE.enumName:
                    const slotDate = date.set({day: this.dateOfMonth});   // go to the date on this month
                    if (slotDate > sourceSlot.start && slotDate <= this.endDate) {     // if the date after the source slot start and before the end date, ok to add
                        addSlot(slotDate);
                    }
                    date = date.plus({months: this.interval});            // goto the beginning of the next month
                    break;

                
                case Recurrence.RecurrenceMode.MONTHLY_WEEK.enumName:
                    const dateThisMonth = this._gotoMonthlyWeek(date);
                    if (dateThisMonth && dateThisMonth > sourceSlot.start && dateThisMonth <= this.endDate) {    // if there is a date this month that's valid, and the date after the source slot start and before the end date, ok to add
                        addSlot(dateThisMonth);
                        date = dateThisMonth;
                    }
                    date = date.plus({months: this.interval});
                    break;

                default:
                    console.error("Invalid recurrence mode: " + this.mode);
                    return;
            }
        }
        return recurringSlots;
    }

}