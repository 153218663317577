import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { IconButton, Tooltip, Grid, Typography, Paper, Button, Collapse, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';

import { Booking } from '../models/Booking';
import { CalEvent } from '../models/CalEvent';
import { ThemeColors } from '../Theme'
import { Global } from '../models/Global'
import { PagedSlotList } from '../models/PagedSlotList'

import EventSignupPage from './EventSignupPage'
import { DateTime } from 'luxon';

import { RefundPopover } from '../components/RefundPopover'
import { EditPatronPopover } from '../components/EditPatronPopover'
import { deleteSlotConfirmation } from '../components/DeleteSlotConfirmation';

import { RestComponent, DateUtils, ManageBitwiseCheckboxes, DateRangeSelector, PopupMenu, TextEntryPopover } from 'react-frontend-utils'

import { DateConversions } from '../utils/DateConversions'



/**
 * 
 * Required props:
 * type - "Booking" or "Event"
 * viewSlotInCalendarCallback - function to call to view a slot in the calendar
 * eventid - an Event id to manage signups for
 */

export class SlotsListPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        id: {
            marginLeft: 'auto',
            maxWidth: 300,
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            marginRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 230,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
        details: {
            marginLeft: 30,
            marginBottom: 5,
            padding: 5,
            textAlign: 'left',
            borderRadius: 3,
            backgroundColor: ThemeColors.veryLightGray,
        },
        journal: {
            marginLeft: 30,
            marginBottom: 5,
        }
       
    };
    
    
    _currentDatabase = Global.getLastDatabase();

    // Now based on the browser time and we'll work with UTC in the calendar component, converting to the timezone on fetch
    _now = DateTime.now(); 

    _startOfThisMonth = this._now.startOf('month');
    _endOfThisMonth = this._startOfThisMonth.plus({month: 1}).minus({days: 1});

    _startOfLastMonth = this._startOfThisMonth.minus({month: 1});
    _startOfYear = this._now.startOf('year');
    _endOfYear = this._startOfYear.plus({year: 1}).minus({days: 1});
    _endOfNextYear = this._now.plus({year: 1}).endOf('year');

     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "This month", startTime: this._startOfThisMonth.toUTC().valueOf(), endTime: this._endOfThisMonth.toUTC().valueOf()},
        {label: "Last month", startTime: this._startOfThisMonth.minus({month: 1}).toUTC().valueOf(), endTime: this._startOfThisMonth.minus({days: 1}).toUTC().valueOf()},
        {label: "Last 90 days", startTime: this._now.minus({days: 90}).toUTC().valueOf()},
        {label: "This year", startTime: this._startOfYear.toUTC().valueOf(), endTime: this._endOfYear.toUTC().valueOf()},
        {label: "Upcoming", startTime: this._now.startOf('day').toUTC().valueOf(), endTime: this._endOfNextYear.toUTC().valueOf()},
    ];
    

    constructor(props) {
        super(props);
    
        this.state.slotsPage = null;         //last fetched page - a PagedSlotList
               
        // Defaults, in case the user selects nothing
        this.state.searchFromDate = DateConversions.luxonDateTimeToJsonDateString(this._startOfYear).split("T")[0];     // keep date only
        this.state.searchToDate = DateConversions.luxonDateTimeToJsonDateString(this._endOfYear).split("T")[0];         // keep date only
        
        this.state.stateCheckboxes = 0;           //Bitwise selected types to search for, from checkboxes only! (bit 0 = pending, bit 1 = active, bit 2 = cancelled)
        this.state.revealSet = new Set();         //Set of slots to reveal detail
        this.state.patronDataToSearch = "";       //Data in a patron name or email to search for
        this.state.textDataToSearch = "";       //Data in a slot title or description to search for

        this.state.refundPopupOpen = false;
        this.state.maxRefund = 0;
        this.state.slotCurrency = null;
        this.state.refundSlot = null
        this.state.commentPopupOpen = false;
        this.state.commentSlot = null;
        this.state.manageBookingPatronSlot = null;      // For Bookings, the Booking (slot) to manage patrons for
        this.state.editBookingPatronPopoverOpen = false;

        this.state.eventid = null;   // event to manage signups for
        if (props.eventid)
            setTimeout(() => this._manageEventSignup({id: props.eventid}));

    }
    
    componentDidUpdate() {  // when the Booking SlotListPage is requested, close the EventSignupPage
        if (this.props.type === "Booking" && this.state.eventid)
            this.setState({eventid: null});
    }
    
    
    /**
     * When the page loads, immediately fetch the first page of Slots
     */
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("databaseChangeEvent", this._databaseChanged);
        
        //fetch all slots, first page
        this._fetchSlots();  
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("databaseChangeEvent", this._databaseChanged);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.type !== nextProps.type) {
            setTimeout(() => this._fetchSlots(0), 0);  //refetch when type changes
        }
    }


    // When the database changes, refetch the resources (which will refresh the calendar) and fetch the timezone
    _databaseChanged = () => {
        if (Global.getLastDatabase() !== this._currentDatabase) {
            console.log("Database changed to " + Global.getLastDatabase() + ", refetching slots");
            this._currentDatabase = Global.getLastDatabase();
            this.setState({slotsPage: []});         // clear list of slots
            this._fetchSlots();         // refetch
        }
    }
    
    
    //Fetch a specific page, using search fields if set.
    //If forDownload is set, download as csv, otherwise normal fetch to display
    _fetchSlots = (page = 0, forDownload = false) => {
        
        // Convert to luxon in our timezones
        let start = DateConversions.jsonDateStringToLuxonDateTime(this.state.searchFromDate + "T00:00:00", Global.getTimezone());
        let end = DateConversions.jsonDateStringToLuxonDateTime(this.state.searchToDate + "T23:59:59", Global.getTimezone());

        // Then back to JSON for the fetch
        start = DateConversions.luxonDateTimeToJsonDateString(start); 
        end = DateConversions.luxonDateTimeToJsonDateString(end); 
       
        this.setState({slotsPage: null});  //clear the list of slots

        let queryString = "?page=" + page;
                        
        if (this.state.stateCheckboxes)  //state checkboxes are set
            queryString += "&states=" + this.state.stateCheckboxes;
        if (this.state.searchFromDate)
            queryString += "&fromDate=" + start;
        if (this.state.searchToDate) {
            const endOfDayDate = end;     //search goes through the end of the day
            queryString += "&toDate=" + endOfDayDate;
        }
       
        if (this.state.textDataToSearch)
            queryString += "&text=" + encodeURIComponent(this.state.textDataToSearch);
        if (this.state.patronDataToSearch)
            queryString += "&patronData=" + encodeURIComponent(this.state.patronDataToSearch);
        
        if (forDownload) {
            const filename = this.props.type + " Export on " + DateUtils.downloadTimeString() + ".csv";
            this.secureFileDownload("/bk/" + this._currentDatabase + "/slots/" + this.props.type + "/download" + queryString, filename, null, this._fetchErrorCallback); 
        }
        else {
            this.incrementBusy();
            this.secureJSONFetch("/bk/" + this._currentDatabase + "/slots/" + this.props.type, {}, this._fetchSlotsCallback, this._fetchErrorCallback, queryString); 
        }
                
    }
    
    //Callback for fetching Slots - response is json of PagedSlotList
    _fetchSlotsCallback = (response) => {
        if (response) {            
            const sl = new PagedSlotList(response, this.props.type);
            this.setState({slotsPage: sl});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    _slotModifiedCallback = (response) => {
        this.decrementBusy();
        this._refresh();
    }

  
    //If the slot is in the reveal list, remove it, if it is not, add it (toggle the transaction view)
    _revealSlotDetail = (s) => {
        const toReveal = this.state.revealSet;
        
        if (toReveal.has(s))
            toReveal.delete(s);
        else
            toReveal.add(s);
        
        this.setState({revealSet: toReveal});
    }
    

    //fetch the previous page
    _prevPage = () => {  
        
        if (!this.state.slotsPage || this.state.slotsPage.isFirst())
            return;
        
        this._fetchSlots(this.state.slotsPage.pageNo-1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    //fetch the next page
    _nextPage = () => {  
        
        if (!this.state.slotsPage || this.state.slotsPage.isLast())
            return;
        
        this._fetchSlots(this.state.slotsPage.pageNo+1); 
        window.scrollTo(0, 0);  //jump to the top
    }
    
    
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    

    _checkboxStateChanged = (json, newValue) => {
        this.setState({stateCheckboxes: newValue});
    }
    
    _download = () => {
        this._fetchSlots(0, true);  //fetch for download        
    }

    _refresh = () => {
        this._fetchSlots(this.state.slotsPage ? this.state.slotsPage.pageNo : 0);   //just fetch the same page again
    }
    
    _search = () => {
        this._fetchSlots(0, false);
    }


    _addComment = (slot) => {
        this.setState({commentPopupOpen: true, commentSlot: slot});
    }
        
    _commentOkCallback = (text) => {
        this.setState({commentPopupOpen: false});
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/" + this.state.commentSlot.id + "/comment", {method: 'POST', body: text}, this._slotModifiedCallback, this._fetchErrorCallback);
    }

    
    _managePatrons = (slot) => {
        this.setState({manageBookingPatronSlot: slot, editBookingPatronPopoverOpen: true});
    }

    _finishEditBookingPatrons = (email, phone, enableSms, patrons) => {
        const booking = this.state.manageBookingPatronSlot;
        booking.patron.patronEmail = email;
        booking.patron.patronPhone = phone;
        booking.patron.enableSms = enableSms;
        booking.patron.patronNames = patrons;
        booking.patron.patronCount = patrons.length;
        this.setState({editBookingPatronPopoverOpen: false});
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots", {method: 'POST', body: booking.toJsonForPost()}, this._slotModifiedCallback, this._fetchErrorCallback);
    }

    // user selects "Delete", show a confirm alert
    _deleteSlot = (slot) => {
        deleteSlotConfirmation(slot, true, this.showConfirmAlert, this._confirmDeleteSlot);
    }

    // callback from the confirm alert when the user confirms the delete, try and delete it from the server
    _confirmDeleteSlot = (slot) => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/" + slot.id, {method: 'DELETE'}, this._slotModifiedCallback, this._fetchErrorCallback);
    }

    _refundBooking = (booking) => {   
        this.setState({refundPopupOpen: true, maxRefund: booking.patron.calculatedCost - booking.patron.refundedAmount, slotCurrency: booking.patron.isoCurrency, refundSlot: booking});
    }

    _startRefund = (amount, comment) => {
        this.setState({refundPopupOpen: false});
        this.incrementBusy();

        const slot = this.state.refundSlot;
        const body = {amount: amount, comment: comment};
        this.secureJSONFetch("/bk/slots/" + slot.id + "/refund", {method: 'POST', body: JSON.stringify(body)}, 
                             this._slotModifiedCallback, this._fetchErrorCallback);
    }

            
    _manageEventSignup = (event) => {   
        this.props.history.push("/bkportal?eventid=" + event.id);
        this.setState({eventid: event.id});
    }

    //Action items are items for the right popup menu
    _getActionItems = (slot) => {
        
        const actionItems = [];

        if (slot.resourceIds && slot.resourceIds.length > 0)    // with no Resources, it won't be visible in the Calendars
            actionItems.push({label: "View in Calendar", selectCallback: () => {this.props.viewSlotInCalendarCallback(slot.id)}, icon: null});

        actionItems.push({label: "Comment", selectCallback: () => {this._addComment(slot);}, icon: null});

        const inBookingProcess = slot instanceof Booking && slot.inBookingByPatron();

        if (slot.state === "CREATED" && !inBookingProcess)
            actionItems.push({label: "Delete", selectCallback: () => {this._deleteSlot(slot);}, icon: null});

        if (slot.state === "ACTIVE" && !inBookingProcess)
            actionItems.push({label: "Cancel", selectCallback: () => {this._deleteSlot(slot);}, icon: null});

        if (slot instanceof Booking && slot.refundable())
            actionItems.push({label: "Refund", selectCallback: () => {this._refundBooking(slot);}, icon: null});

        if (slot instanceof Booking && (slot.state === "ACTIVE" && !slot.inBookingByPatron()))
            actionItems.push({label: "Edit Patrons", selectCallback: () => {this._managePatrons(slot);}, icon: null});

        if (slot instanceof CalEvent)
            actionItems.push({label: "Manage Signups", selectCallback: () => {this._manageEventSignup(slot);}, icon: null});

        return actionItems;
    }

    //Render a row of the table with the specified Slot. In the Slot is null, render the header row
    _renderRow = (slot, key) => {
        
        const columns = 7;

        //Render the header
        if (!slot) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                    <th style={{...this.styles.tableHeader, width: 250}}>Start Date</th>
                    <th style={{...this.styles.tableHeader, width: 130}}>Duration (min)</th>
                    <th style={{...this.styles.tableHeader, width: 160, textAlign: 'center'}}>Payments</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'center'}}>Status</th>
                    <th style={{...this.styles.tableHeader, width: 250}}>{this.props.type + " Name"}</th>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                </tr>
            );
        }
            
        const isRevealed = this.state.revealSet.has(slot);
        const leftIcon = isRevealed ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
        const startDate = DateConversions.dateTimeStr(slot.start);
        const duration = slot.duration() / 60;
    
        let payBadge = null;
        if (slot instanceof Booking) {
            payBadge = slot.patron.paymentBadge();
        }

        let title = <span>{slot.title}
                        {slot.isRecurring() ? <span style={{marginLeft: 15}}>↻</span> : null}
                    </span>;
                                
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableStyle}>

                    <td style={this.styles.tableData}>
                        <IconButton edge="start" onClick={() => this._revealSlotDetail(slot)}>
                            {leftIcon}
                        </IconButton>
                    </td>

                    <td style={this.styles.tableData}>{startDate}</td>
                    <td style={this.styles.tableData}>{duration}</td>
                    <td style={this.styles.tableData}>{payBadge}</td>

                    <td style={this.styles.tableData}>
                        <div style={{...this.styles.status, backgroundColor: slot.colorForStatus()}}>
                            {slot.status()}
                        </div>
                    </td>
                   
                    <td style={this.styles.tableData}>{title}</td>

                    <td style={{...this.styles.tableData, paddingRight: 0}}>
                        <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '20', color: ThemeColors.settingsOlive}}/>)}  
                                    menuItems={this._getActionItems(slot)} 
                                    menuTooltipText={"Actions"}/>
                    </td>
                </tr>
                <tr style={this.styles.tableStyle}>
                    <td colSpan={columns} align='center' style={this.styles.tableDataWithBorder}>
                        <Collapse in={isRevealed}>
                            <Typography variant='body2' style={{marginLeft: 35, fontWeight: 'bold'}}>Details</Typography>
                            <div style={this.styles.details} dangerouslySetInnerHTML={{ __html: slot.details() }} />
                            <div style={this.styles.journal}>
                                {slot.renderJournal()}
                            </div>
                        </Collapse>
                    </td>
                </tr>
            </Fragment>
        );
    }
    
    //Callback when the EventPage is finished
    _manageSignupFinished = () => {
        this.setState({eventid: null});
        this._refresh();
    }
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        //If we have an Event to manage signups for, show the EventPage
       if (this.state.eventid) 
            return <EventSignupPage id={this.state.eventid} exitCallback={this._manageSignupFinished} viewSlotInCalendarCallback={() => this.props.viewSlotInCalendarCallback(this.state.eventid)}/>;
                                
        const page = this.state.slotsPage;

        let checkboxLabels = [];
        if (this.props.type === "Booking") {
            checkboxLabels.push({name: "Reserved", tooltip: "Patron has reserved the Booking"});
            checkboxLabels.push({name: "Booked", tooltip: "The Booking is confirmed (payment may be pending)"});
            checkboxLabels.push({name: "Pending Cancel", tooltip: "A cancel request is pending for the Booking"});
            checkboxLabels.push({name: "Cancelled", tooltip: "The Booking has been cancelled"});
        }
       else {
            checkboxLabels.push({name: "Created", tooltip: "The Event has been created"});
            checkboxLabels.push({name: "Active", tooltip: "At least one Patron has signed up for the Event"});
            checkboxLabels.push({name: "Pending Cancel", tooltip: "A cancel request is pending for the Event"});
            checkboxLabels.push({name: "Cancelled", tooltip: "The Event has been cancelled"});
        }
       
        const showing = (page && page.slots.length > 0) ? "Displaying " + (page.index + 1) + "-" + (page.index + page.slots.length) : null;            
                
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}   

                {this.state.manageBookingPatronSlot && this.state.editBookingPatronPopoverOpen ?
                    <EditPatronPopover isOpen={this.state.editBookingPatronPopoverOpen} patron={this.state.manageBookingPatronSlot.patron}
                                              okCallback={this._finishEditBookingPatrons} cancelCallback={() => this.setState({editBookingPatronPopoverOpen: false})}/>
                    : null
                }

                <TextEntryPopover isOpen={this.state.commentPopupOpen} showSkip={false} multiline={true} title={"Add Comment for " + this.props.type} 
                                  okCallback={this._commentOkCallback} cancelCallback={() => this.setState({commentPopupOpen: false})}/>

                <RefundPopover isOpen={this.state.refundPopupOpen} maxVal={this.state.maxRefund} currency={this.state.slotCurrency}
                               okCallback={this._startRefund} cancelCallback={() => this.setState({refundPopupOpen: false})}/>                 
           

                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'right', alignItems: 'center'}}>
            
                    {this.state.isBusy ? this.getBusyComponent('left', {marginLeft: 20, padding: 15}, 30) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}
           
                </div>
                
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>{"Search " + this.props.type + "s"}</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item md={4} sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                allowFuture={true}
                                                minYear={2024}
                                                maxYear={DateUtils.currentYear() + 50}
                                                ref={this._dateRangeRef}
                                                initialTimeRange="This year"
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <ManageBitwiseCheckboxes style={{marginTop: -17}} json="stateCheckboxes" label="States" labels={checkboxLabels} onChange={this._checkboxStateChanged} initialValue={this.state.stateCheckboxes} />
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <Tooltip title={"Search text fields: " + this.props.type + " title/description (may be slow)"}>
                                <TextField label="Title/Description Search" inputProps={{style: {fontSize: 14}}} style={{marginBottom: 20}}
                                           onChange={(event) => this.setState({textDataToSearch: event.target.value.trim()})} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
                            </Tooltip>
                            <Tooltip title="Search patron names (may be slow)">
                                <TextField label="Patron Email/Name Search" inputProps={{style: {fontSize: 14}}}
                                           onChange={(event) => this.setState({patronDataToSearch: event.target.value.trim()})} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        <Tooltip title={"Search and Display " + this.props.type + "s below"}>
                            <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Tooltip>

                        <Tooltip title={"Search and Download " + this.props.type + "s"}>
                            <Button fullWidth onClick={this._download} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<GetAppIcon/>}>
                                Download
                            </Button>
                        </Tooltip>

                    </div>

                </Paper>
                
                <div style={{marginTop: 15}}/>
                
                {page ?
                     <div>   
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {page.slots.map((slot, index) => this._renderRow(slot, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {!page.isFirst() ? <Button onClick={this._prevPage} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {!page.isLast() ? <Button onClick={this._nextPage} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>                        

                    </div>
                    
                    
                : null}
                
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(SlotsListPage));

