import React, { Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';


import { Global } from '../models/Global'
import { DateConversions } from '../utils/DateConversions'
import { Currency } from 'react-frontend-utils' 
import { Patron } from '../models/Patron'

import { CommonPatronFunctions } from '../components/CommonPatronFunctions'

/**
 *  Let the Patron modify the Booking
 * 
 * The following props are required:
 *  booking: the Booking object that the user is modifying 
 *  database: the database to use for the Booking
 *  service: the Service object that the Booking is on
 * 
 *  rescheduleBookingCallback: the callback to call when the user wants to reschedule the Booking
 *  cancelBookingCallback: the callback to call when the user wants to cancel the Booking
 *  
 */
export function ManageBookingPage(props) {
    
    const timezone = Global.getTimezone();
    
    const service = props.service;
    const booking = props.booking;

    const timeUntilStart = DateConversions.duration(DateConversions.now(timezone), booking.start);
    const tooLate = service ? (timeUntilStart < service.serviceParams.minHoursBeforeChange * 3600) : false

    const canManage = service && (service.serviceParams.allowCancellation || service.serviceParams.allowRescheduling) && !tooLate;

    let title = canManage ? "Manage your Booking" : "Your Booking";
    if (service)
        title += " on " + service.name;

    return (
        <Fragment>
            <Typography align='center' variant="h6" style={{marginBottom: 10}}>{"Welcome Back, " + booking.patron.patronNames[0]}</Typography>
            <Typography align='center' variant="h5" style={{fontWeight: 'bold', marginTop: 20, marginBottom: 10}}>{title}</Typography>
            <Typography align='center' variant="body2" style={{marginBottom: 10}}>{service ? service.description : ""}</Typography>
            {CommonPatronFunctions.finalBookingSignupDateDisplay(booking)}

            {booking.patron.status() === Patron.Status.CONFIRMED_PAID ?
                <Typography align='center' variant="body1" style={{color: 'green'}}>{"Paid: " + Currency.round(booking.patron.calculatedCost) + " " + booking.patron.isoCurrency}</Typography>
                : null
            }

            <hr style={{margin: 20}}/>

            {!canManage ?
                <Typography align='center' variant="h6">You cannot change this Booking. Please contact your Service provider for assistance. You can find their contact in your booking confirmation email.</Typography>
                :
                <div style={{display: 'flex', flexDirection: 'column', gap: 20, justifyContent: 'center', alignItems: 'center'}}>
                    {service && service.serviceParams.allowRescheduling ?
                        <Button style={{minWidth: 400}} variant="outlined" color="primary" onClick={props.rescheduleBookingCallback}>Reschedule</Button> 
                        : null
                    }
                    {service && service.serviceParams.allowCancellation ?
                        <Button style={{minWidth: 400, color: 'red'}} variant="outlined" onClick={props.cancelBookingCallback}>Cancel</Button> 
                        : null
                    }
                </div>
            }


        </Fragment>
    );
}   
